import { useState, useEffect } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import Select from 'react-select';
import '../../assets/scripts/jquery-validation.init';
import ImageCropper from "../global/imageCropper";
import { getUserAll, postUser } from "../api/usersAPI";
import { uploadPublicFile } from "../api/fileAPI";

const Users = (props) => {

    const [isInit,              setIsInit]              = useState(true);
    const [rightSideDetails,    setRightSideDetails]    = useState(false);
    const [user,                setUser]                = useState({Active: 'Y'});
    const [genderOption,        setGenderOption]        = useState([{ value:  'Male', label: 'Male'}, { value:  'Female', label: 'Female'},]);
    const [usersList,           setUsersList]           = useState([]);
    const [action,              setAction]              = useState('a');
    const [userDetails,         setUserDetails]         = useState({});

   //<--- Cropper -->
   const [photoBlob,        setPhotoBlob]       = useState(null);
   const [cropperData,      setCropperData]     = useState(null);

    useEffect(() => {
        props.title("Users", "user")
        fetchUsers();
    }, []);

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();                  
        }
    }, [usersList]);

    useEffect(() => {  
        if(rightSideDetails) {
            Config.Core.OpenDrawer() 
            $('#txtDOB').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date(),
            });
            $('#txtDOB').on('change', function (event) {
                updateUserDetails('DOB',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });
        } else {
            setPhotoBlob(null)
            setUser({Active: 'Y'});
        }
    }, [rightSideDetails]);

    const updateUserDetails = (field, value) => {   
        setUser((d) => {
            return { ...d, [field]: value };
        });
    };

    const openCropper = () =>{
        setCropperData({
            imageRatio  : { width : 200, height : 200},
            dataHandler : (val) =>  setPhotoBlob(val),
            header : 'Upload Photo'
        })
    }

    const fetchUsers = async () => {
        try {
            const users = await getUserAll();
            isInit ? setIsInit(false) : $('#tblUser').KTDatatable().destroy();
            setUsersList(users);
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fillDataTable = () => {

        const dataTable = $('#tblUser').KTDatatable({
            data: {
                saveState: false,
                source: usersList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'FirstName',
                    title: 'User',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.Photo && row.Photo != '') ? `<img src="${row.Photo}" alt="event-thumbail" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.UserName.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.UserName} </span> 
                                    <span class="kt-user-card-v2__desc text-clip"> ${row.Email} </span>
                                </div>
                            </div>`
                    }
                },
                {
                    field: 'Mobile',
                    title: 'Mobile',
                },
                {
                    field: 'IsAppUser',
                    title: 'App User',
                    template: function (row) {
                        return row.IsAppUser == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                    },
                  
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        return row.Active == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                    },
                    width: 50,
                },
            ]
        })

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setAction('e')
                setUser(dataTable.dataSet[index]);  
                setRightSideDetails(true);           
            } 
        });
    }

    const addUserHandler = async () => {
        const btn = $('#btnSave');
        var form = $('#add-user-form');
        form.validate({
            rules: {
                txtFirstName: {
                    required: true
                },
                txtLastName: {
                    required: true
                },
                txtMobile: {
                    required: true,
                    number: true,
                    minlength: 10,
                    maxlength: 10,
                },
                txtEmail: {
                    required: true,
                    email: true
                },
                ddlGender: {
                    required: true
                }      
            },
            messages: {
                txtFirstName: {
                    required: "Please enter firstname"
                },
                txtLastName: {
                    required: "Please enter lastname"
                },
                txtMobile: {
                    required: "Please enter mobile",
                },
                txtEmail: {
                    required: "Please enter email",
                    email: "Please enter a valid email"
                },
                ddlGender: {
                    required: "Please select gender"
                },
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            let userPhoto = '';
            if (photoBlob) {
                const res = await uploadPublicFile(photoBlob, 'User');
                userPhoto = res.fileName
            }
            await postUser({...user, Photo: userPhoto});
            helper.StopProcessing(btn);
            fetchUsers();
            closeDrawer();
            if (user.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'User updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            } else {
                swal.fire({
                    icon: 'success',
                    titleText: 'User added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }
    
    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <div className="row align-items-center margin-l10">
                                <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <input type="text" className="form-control clientSearch" placeholder="Search User..." id="generalSearch" />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    <button onClick={() => setRightSideDetails(true)}  type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New User</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div id="tblUser" className="kt-datatable table-striped clickable"></div>
                        {rightSideDetails && <RightSideDetails title="User Details" onCloseDrawer={closeDrawer}>
                            <div className="kt-portlet">
                                <div className="kt-portlet__body padding-t30 padding-b0">
                                    <form className="kt-form" id="add-user-form">
                                        <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">First Name<span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input
                                                        id="txtFirstName" 
                                                        name="txtFirstName" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={user.FirstName || ''}
                                                        onChange={(event) => updateUserDetails('FirstName', event.target.value)}               
                                                    />						
                                                </div>
                                            </div>   
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Middle Name</label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input
                                                        id="txtMiddleName" 
                                                        name="txtMiddleName" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={user.MiddleName || ''}
                                                        onChange={(event) => updateUserDetails('MiddleName', event.target.value)}               
                                                    />						
                                                </div>
                                            </div>   
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Last Name<span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input
                                                        id="txtLastName" 
                                                        name="txtLastName" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={user.LastName || ''}
                                                        onChange={(event) => updateUserDetails('LastName', event.target.value)}               
                                                    />						
                                                </div>
                                            </div>   
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Mobile<span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input
                                                        id="txtMobile" 
                                                        name="txtMobile" 
                                                        type="number" 
                                                        className="form-control"
                                                        value={user.Mobile || ''}
                                                        onChange={(event) => updateUserDetails('Mobile', event.target.value)}               
                                                    />						
                                                </div>
                                            </div>   
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Email<span className="red">*</span></label>
                                                <div className="col-xl-4 col-lg-5">
                                                    <input 
                                                        id="txtEmail" 
                                                        name="txtEmail" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={user.Email || ''}
                                                        onChange={(event) => updateUserDetails('Email', event.target.value)} 
                                                    />
                                                </div>
                                            </div>                                        
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Gender<span className="red">*</span></label>
                                                <div className="col-xl-4 col-lg-5">
                                                    <Select 
                                                        id="ddlGender" 
                                                        name="ddlGender"
                                                        className="multi-custom"
                                                        placeholder="Select Gender"
                                                        value={user && genderOption.find((m) => m.value == user.Gender) || ''}
                                                        onChange={(event) => updateUserDetails('Gender', event.value)}
                                                        options={genderOption}
                                                    />
                                                </div>
                                            </div>                                        
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">DOB</label>
                                                <div className="col-xl-4 col-lg-5">
                                                    <div className="input-group date">
                                                        <input 
                                                            id="txtDOB" 
                                                            name="txtDOB" 
                                                            type="text" 
                                                            className="form-control date-input" 
                                                            readOnly={true} 
                                                            placeholder="Select DOB" 
                                                            value={user.DOB ? moment(user.DOB).format('DD-MM-YYYY') : ''} 
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">
                                                                <i className="la la-calendar-check-o"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">App User</label>
                                                <div className="col-xl-4 col-lg-5">
                                                    <input 
                                                        id="txtAppUser" 
                                                        name="txtAppUser" 
                                                        disabled
                                                        type="text" 
                                                        className="form-control"
                                                        value='No' 
                                                    />
                                                </div>
                                            </div>                          
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Email Verified</label>
                                                <div className="col-xl-4 col-lg-5">
                                                    <input 
                                                        id="txtAppUser" 
                                                        name="txtAppUser" 
                                                        disabled
                                                        type="text" 
                                                        className="form-control"
                                                        value='No' 
                                                    />
                                                </div>
                                            </div>                          
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Mobile Verified</label>
                                                <div className="col-xl-4 col-lg-5">
                                                    <input 
                                                        id="txtAppUser" 
                                                        name="txtAppUser" 
                                                        disabled
                                                        type="text" 
                                                        className="form-control"
                                                        value='No' 
                                                    />
                                                </div>
                                            </div>                          
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Active</label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <span className="kt-switch kt-switch--icon">
                                                        <label>
                                                            <input 
                                                                id="chkActive" 
                                                                name="chkActive" 
                                                                type="checkbox" 
                                                                defaultChecked={user.Active == 'N' ? '' : 'checked'}
                                                                onChange={(event) => updateUserDetails('Active', event.target.checked ? 'Y' : 'N')} 
                                                                />
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>      
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3"> Photo </label>
                                                <div className="col-sm-9">                                                        
                                                    {(!!photoBlob || user.Photo) &&(<>
                                                        <div className="kt-avatar kt-avatar--outline kt-avatar--changed">
                                                            <div className="kt-avatar__holder border-none shadow-none" 
                                                                style={{ backgroundImage : `url( ${photoBlob ? URL.createObjectURL(photoBlob) : user.Photo && (user.Photo) })` } }>    
                                                            </div>
                                                        </div>
                                                    </>) }

                                                    <p className="">
                                                        <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={openCropper}>
                                                            {(!!photoBlob || user.Photo) ? 'Change Photo' : 'Upload Photo'}
                                                        </button>
                                                    </p>
                                                    <p className="">(200 W x 200 H pixels)</p>                                                       
                                                </div>
                                            </div>                                     
                                        </div>
                                    </form>
                                </div>
                                <div className="kt-portlet__foot">
                                    <div className="kt-form__actions">
                                        <div className="row">
                                            <div className="col-lg-9 ml-lg-auto">
                                                <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addUserHandler}>Save</button>
                                                <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </RightSideDetails>}
                    </div>
                </div>
            </div>
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
        </>
    );
}

export default Users;