import React, { useEffect, useState } from 'react'
import { assignCoach, getCoachAll } from '../api/academyAPI';
import Select from 'react-select';
import * as helper from '../global/helper';
import swal from 'sweetalert2';

function CoachAssignModal(props) {

    const [coachList,           setCoachList]           = useState([]);
    const [selectedCoach,       setSelectedCoach]       = useState(null);

    useEffect(() => {
        $('#assign-modal').modal({
            backdrop: 'static',
        });
        $('#assign-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(null);
        });
        fetchCoach();
    }, []);

    useEffect(() => {
        if (props.bookSheet && props.bookSheet.CoachID) {
            setSelectedCoach(props.bookSheet.CoachID)
        }
    }, [props.bookSheet])

    const fetchCoach = async() => {
        try {
            const response = await getCoachAll()
            setCoachList(response);
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }  

    const addCoachAssignHandler = async (isAssign) => {
        const btn = $('#add-btn');
        var form = $('#assign-form');
        form.validate({
            rules: {
                ddlSelectCoach: {
                    required: true
                },          
            },
            messages: {
                ddlSelectCoach: {
                    required: "Please select coach"
                },
            }
        });

        if (!form.valid()) return;

        helper.StartProcessing(btn);    
        try {
            await assignCoach({...props.bookSheet, SelectedCoach: isAssign ? selectedCoach : null, isAssign, Type: props.type});
            props.refetchBookSheet()
            helper.StopProcessing(btn);
            $('#assign-modal').modal('hide');
            swal.fire({
                icon              : 'success',
                titleText         : `Coach ${isAssign ? 'Assigned' : 'Unassigned'}`,
                showConfirmButton : false,
                timer             : 1500,
            });
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const closeDrawer = () => {

    }

    return (
        <div className="modal fade" id="assign-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-s" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Assign Coach</h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={closeDrawer}
                        >
                        </button>
                    </div>
                    <div className="modal-body">
                        <form className="kt-form" id="assign-form">
                            <div className="row">
                                <div className="col-12 margin-b0">
                                    <div className="form-group">
                                        <label htmlFor="fromDate">Select Coach</label>
                                    
                                        <Select
                                            value={(coachList && coachList.length > 0 && selectedCoach) && {label:  `${coachList.find(m => m.ID == selectedCoach).Name} (${coachList.find(m => m.ID == selectedCoach).Mobile})`, value: selectedCoach} || ''}
                                            onChange={(event) => setSelectedCoach(event.value)}
                                            name="ddlSelectCoach"
                                            placeholder="Select Coach"
                                            id="ddlSelectCoach"
                                            className="ddlSelectCoach"
                                            options={coachList.map(c => ({
                                                label: `${c.Name} (${c.Mobile})`,
                                                value: c.ID
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary margin-0 margin-r5"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                        {props.bookSheet.CoachID && (
                            <button
                                type="button"
                                
                                className="btn btn-danger  margin-0 margin-r10"
                                data-dismiss="modal"
                                onClick={() => addCoachAssignHandler(false)}
                            >
                                Unassign
                            </button>
                        )}
                        <button
                            id="add-btn"
                            onClick={() => addCoachAssignHandler(true)}
                            type="button"
                            className={`btn btn-brand margin-0 `}
                            // disabled={btnLoading} 
                        >                  
                            Assign
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CoachAssignModal