import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getShotgunCalendarData, postShotgunCalendar, getShotgunMappingByCalendar, deleteShotgunCalendar } from "../api/teeTimeAPI";

const ShotgunCalender = (props) => {
    
    const [isInit,                 setIsInit]               = useState(true);
    const [rightSideDetails,       setRightSideDetails]     = useState(false);
    const [pageData,               setPageData]             = useState();
    const [calendar,               setCalendar]             = useState({});
    const [mapping,                setMapping]              = useState([]);

    useEffect(() => {
        props.title("Shotgun Calendar", "shotgun-calendar")
        fetchPageData();
    }, []);

    useEffect(() => {                  
        if (rightSideDetails) {
            Config.Core.OpenDrawer()
            $('#txtDate').datepicker({format: 'dd-mm-yyyy', minDate: new Date()});
            $('#txtDate').on('change', function (event) {
                inputHandler('Date',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });  
        }else{
            setCalendar({});
            setMapping([]);
        }
    }, [rightSideDetails]);

    useEffect(() => {                  
        if (!isInit) {
            fillDataTable();
        }
    }, [pageData]);

    const postCalendar = async() => {
        const btn  = $('#btnSave');
        const form = $('#addCalendarForm');

        form.validate({
            rules: { txtDate: { required: true } },
            messages: { txtDate: { required: "Please select date" } }
        });

        if (!form.valid()) return;
        //Validation Goes Here -->
                
        helper.StartProcessing(btn);
        try {                     
            await postShotgunCalendar( calendar, mapping);
            
            helper.StopProcessing(btn);
            
            closeDrawer();
            fetchPageData();

            if (calendar.ID) {            
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Calendar updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,                                       
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Calendar added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const deleteCalendar = async() => {
        swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
           
        try {                                 
            await deleteShotgunCalendar(calendar.ID);
            
            closeDrawer();
            fetchPageData();
            
            swal.fire({
                icon: 'success', showConfirmButton: false, timer: 1500,
                titleText: 'Calendar deleted successfully!', 
                onOpen: () => swal.hideLoading()     
            });                        
        } catch (err) {           
            swal.fire({
                icon: 'error', titleText: 'Error!', text: err.message,
                buttonsStyling: false, confirmButtonClass : 'btn btn-brand',
            });
            return;
        }
    }
    const fetchPageData = async() => {
        try {
            const res = await getShotgunCalendarData()   
            
            isInit ? setIsInit(false) : $('#tblShotgunCalendar').KTDatatable().destroy(); 
            
            setPageData(res); 
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchShotgunMapping = async(id) => {
        try {
            const res = await getShotgunMappingByCalendar(id)   
            
            setMapping(res);
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    function fillDataTable() {

        const dataTable = $('#tblShotgunCalendar').KTDatatable({
            data: {
                saveState: false,
                source: pageData.Calendar || [],
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Date',
                    title: 'Date', 
                    width: 150,
                    template: function (row) {
                        return moment(row.Date).format('DD-MMM-YYYY');
                    }                 
                },
                {
                    field: '',
                    title: 'Day',
                    width: 150,
                    template: function (row) {
                        return moment(row.Date).format('dddd');
                    }

                },
                {
                    field: 'Remark',
                    title: 'Remark',
                    template: function (row) {
                        var displayText = row.Remark ? row.Remark.substring(0, 30) + '...' : '';
                        return '<span title="' + row.Remark + '">' + displayText + '</span>';
                    },                  
                },
            ]
        });

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setCalendar(dataTable.dataSet[index]); 
                fetchShotgunMapping(dataTable.dataSet[index].ID);
                setRightSideDetails(true);
            } 
        });
    }
 
    const inputHandler = (field, value) => {
        if(field == "BlockID"){
            const exist = mapping.find( b => b.ShotgunBlockID === value);

            if(!!exist)
                setMapping(m => {
                    const newData = m.filter(d => d.ShotgunBlockID != value);
                    return newData;
                })              
            else{
                setMapping( m => ([...m, {ShotgunCalendarID:0, ShotgunBlockID: value}]))
            } 
        }else{
            setCalendar((d) => ({...d, [field]: value }));
        }        
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Calendar..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Calendar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblShotgunCalendar" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Calender Details" onCloseDrawer={() => setCalendar({})}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form className="kt-form" id="addCalendarForm">
                                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">                                                                                     
                                                
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Date <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <div className="input-group date">
                                                            <input 
                                                                id="txtDate" 
                                                                name="txtDate" 
                                                                type="text" 
                                                                className="form-control date-input" 
                                                                readOnly={true} 
                                                                placeholder="Select date" 
                                                                value={calendar.Date ? moment(calendar.Date, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''} 
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="la la-calendar-check-o"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>   
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3"> Remark </label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <textarea 
                                                            id="txtRemark" name="txtRemark" 
                                                            className="form-control"
                                                            rows={3}
                                                            value={calendar.Remark || ''}
                                                            onChange={(event) => inputHandler('Remark', event.target.value)}  
                                                        />                                                    
                                                    </div>
                                                </div>
                                                
                                                {(!!pageData && !!pageData.Blocks) && (
                                                    <div className="row">
                                                        <div className="col">
                                                            <table id="table-block" className="table table-bordered table-hover margin-b30">
                                                                <thead className="light-grey-background">
                                                                    <tr>
                                                                        <th>Block</th>
                                                                        <th>Active</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {pageData.Blocks.map( (b,i) => {
                                                                        return(
                                                                            <tr key={i}>
                                                                                <td> {b.Block} </td>
                                                                                <td>
                                                                                    <span className="kt-switch kt-switch--sm kt-switch--icon margin-lr10">
                                                                                        <label className="margin-b0 padding-t5">
                                                                                            <input className="block-active" name="block-active" type="checkbox"
                                                                                                checked = {!!mapping.find(m => m.ShotgunBlockID === b.ID)}
                                                                                                onChange={() => inputHandler("BlockID", b.ID)}
                                                                                            />
                                                                                            <span></span>
                                                                                        </label>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                )}

                                                <div className="kt-portlet__foot">
                                                    <div className="kt-form__actions">
                                                        <div className="row">
                                                        <div className="col-lg-9 ml-lg-auto">
                                                            <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={postCalendar}>Save</button>
                                                            {(calendar?.ID && !calendar?.IsPrev) && (
                                                                <button id="btnDelete" type="button" className="btn btn-danger d-inline-block margin-l10" onClick={deleteCalendar}> Delete </button>
                                                            )}
                                                            <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </RightSideDetails>
                            }
                        </div>
                    </div>
                </div>
            </div>  
        </>
    )
}

export default ShotgunCalender;