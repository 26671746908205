import React, { useEffect, useState } from 'react'
import RightSideDetails from "../global/rightSideDetails";
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import Select from 'react-select';
import { getGreenFee, getMatBook, getMatBookSheet, getMatMasterAll, postMatBook } from '../api/matAPI';
import moment from 'moment';
import { getUserAll } from '../api/usersAPI';
import AddGSTCompanyModal from '../teeTime/AddGSTCompanyModal';
import { getCompany } from '../api/teeTimeAPI';

const MatBookingSheet = (props) => {

    const [isInit,                  setIsInit]                      = useState(true);
    const [rightSideDetails,        setRightSideDetails]            = useState(false);
    const [selectedDate,            setSelectedDate]                = useState(moment().toDate());
    const [selectedMat,             setSelectedMat]                 = useState(null);
    const [allMaster,               setAllMaster]                   = useState([]);
    const [searchString,            setSearchString]                = useState('');
    const [bookSheetList,           setBookSheetList]               = useState([]);
    const [bookSheet,               setBookSheet]                   = useState({});
    const [usersList,               setUsersList]                   = useState([]);
    const [showGSTModal,            setShowGSTModal]                = useState(false);
    const [companies,               setCompanies]                   = useState([]);
    const [editCompany,             setEditCompany]                 = useState(null) 
    const [gstNumber,               setGstNumber]                   = useState(null)    
    const [refresh,                 setRefresh]                     = useState(false)     
    const [greenFee,                setGreenFee]                    = useState({});
    const [amount,                  setAmount]                      = useState(null);
    const [paymentMode,             setPaymentMode]                 = useState([{label: 'Cash', value: 'Cash'},
    {label: 'Cheque', value: 'Cheque'},{label: 'Credit Card', value: 'Credit Card'},{label: 'Debit Card', value: 'Debit Card'},{label: 'NetBanking', value: 'NetBanking'},
    {label: 'UPI', value: 'UPI'}])

    useEffect(() => {
        props.title("Booking Sheet", "mat-sheet");
        $('#txtDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtDate').on('change', function (event) {
            setSelectedDate(event.target.value)
        });      
        fetchMatMaster();
    }, []);

    useEffect(() => {
        fetchCompany();
        setRefresh(false)
    }, [refresh])

    useEffect(() => {
        if (bookSheet.UserID) {
            const Bucket = greenFee.Bucket * bookSheet.Bucket
            const GreenFee = greenFee.GreenFee;
            const TotalFee = GreenFee + Bucket 
            const DiscountedRate = bookSheet.Discount >=  100 ? TotalFee : bookSheet.Discount <= 0 ? 0 : Math.ceil((TotalFee * (bookSheet.Discount ? bookSheet.Discount : 0)) / 100)
            const SGST = Math.ceil(((TotalFee - DiscountedRate) * 9) / 100);
            const CGST = SGST;
            const Total = (TotalFee - DiscountedRate) +  SGST + CGST;
            setAmount({
                GreenFee,
                DiscountedRate,
                Bucket,
                SGST,
                CGST,
                Total
            })
        } else {
            setAmount({})
            setGstNumber(null)
        }
    }, [bookSheet, greenFee])

    useEffect(() => {
        if (selectedDate && selectedMat) {
            fetchBookSheet(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedDate, 'DD-MM-YYYY').format('dddd'), selectedMat.ID)
        }
    }, [selectedDate, selectedMat]);

    
    useEffect(() => {  
        if(rightSideDetails){
            Config.Core.OpenDrawer()
            fetchUsers();
            fetchCompany();
            fetchGreenFee();
        }else{
            setAmount({})
        }
    }, [rightSideDetails]);

    const editCompanyHandler = () => {
        if (!bookSheet.ID)
            setEditCompany(gstNumber)
    }

    const resetUser = () => {
        setBookSheet(a => ({...a, UserID: ''}))
    }

    const openDrawerHandler = (bookSheetDetails) => {
        setRightSideDetails(true)
        if (bookSheetDetails.CompanyID) {
            const company = companies.find(c => c.ID == bookSheetDetails.CompanyID);
            setGstNumber({...company, GstNumber: company.GSTNumber})
        }
        setBookSheet({
            ...bookSheetDetails, 
            Bucket      : bookSheetDetails.Bucket ? bookSheetDetails.Bucket : 1,
            Discount    : bookSheetDetails.Discount === 0 ? '' : bookSheetDetails.Discount, 
            PaymentMode : bookSheetDetails.PaymentMode ? bookSheetDetails.PaymentMode : paymentMode[0].value})
    }

    const updateBookingDetails = (field, value) => { 
        setBookSheet((d) => {
            return { ...d, [field]: value };
        });
    };

    
    const fetchCompany = async () => {
        try {
            const response = await getCompany();
            setCompanies(response)
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const fetchBookSheet = async (selectedDate, dayName, matID) => {
        try {
            const response = await getMatBook({selectedDate, dayName, matID, type: 'matBookSheet'});
            setBookSheetList(response.bookSheet);
            // setHoliday(response.holiday);
            // setserverTime(response.serverTimestamp);
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    }

    const fetchMatMaster = async () => {
        try {
            const calender = await getMatMasterAll();
            setSelectedMat(calender[0])
            setAllMaster(calender); 
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    }

    const fetchGreenFee = async () => {
        try {
            const response = await getGreenFee(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedDate, 'DD-MM-YYYY').format('dddd'), 'Mat', '18');
            setGreenFee(response)
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    }

    const fetchUsers = async () => {
        try {
            const users = await getUserAll();
            setUsersList(users.map(u => ({
                ...u,
                Name : `${u.FirstName} ${u.LastName}`
            })))
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const cancelBookingHandler = async () => {

    }

    const addBookingHandler = async () => {
        const btn = $('#btnSave');
        if (bookSheet.Bucket == 0 || !bookSheet.Bucket) {
            swal.fire({
                icon                  : 'warning',
                text                  : "Enter number of Bucket",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return;
        }

        if (!bookSheet.UserID) {
            swal.fire({
                icon                  : 'warning',
                text                  : "Please select player",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return;
        }
    
        helper.StartProcessing(btn);
        try {
            await postMatBook({
                ...bookSheet,
                CompanyID   : gstNumber ? gstNumber.ID : 0,
                MatID       : selectedMat.ID,
                Type        : 'Mat',
                BookDate    : moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), 
                DayName     : moment(selectedDate, 'DD-MM-YYYY').format('dddd'),
            })
            closeDrawer();
            fetchBookSheet(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedDate, 'DD-MM-YYYY').format('dddd'), selectedMat.ID)
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }

    }

    const validateDiscount = (field, value) => {
        if (value > 100)
            value = 100;

        if (value < 0)
            value = 0
        updateBookingDetails(field, value)
    }

    const validateBucket = (field, value) => {
        if (value > 5)
            value = 5;

        if (value < 0)
            value = 1

        // if (value = 0)
        //     value = 1
        updateBookingDetails(field, value)
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    return (
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content" >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="row">
                                    <label className="col-form-label col-sm-1 text-right">Date </label>
                                    <div className="form-group col-sm-3">
                                        <div className="input-group date">
                                            <input 
                                                id="txtDate" 
                                                name="txtDate" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Select date"
                                                defaultValue={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            /> 
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <label className="col-form-label col-sm-1 text-right">Mat </label>
                                    <div className="form-group col-sm-3">
                                        <Select
                                            onChange={(event) => setSelectedMat(event.value)}
                                            name="ddlSelectUser"
                                            value={(selectedMat && allMaster.length > 0) && {label : selectedMat.Number, value: selectedMat}}
                                            id="ddlSelectUser"
                                            className="ddlSelectUser"
                                            options={allMaster.map(c => ({
                                                label: `${c.Number}`,
                                                value: c
                                            }))}
                                        />
                                    </div>
                                    {/* <div className='col-sm-4'>
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input                                      
                                                type="text"
                                                className="form-control"
                                                placeholder="Search Booking..."
                                                id="generalSearch"
                                                value={searchString || ''}
                                                onChange={(e) => setSearchString(e.target.value)}
                                                >
                                            </input>
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                                <span>
                                                    <i className="la la-search"></i>
                                                </span>
                                            </span>
                                            {searchString && (
                                                <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                                    <span>
                                                        <i className="la la-close" onClick={() => setSearchString('')}></i>
                                                    </span>
                                                </span>
                                            )}
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <table id="table-book" className="table table-bordered table-hover margin-b30 ">
                                <thead className="light-grey-background">
                                    <tr>
                                        <th>Slot</th>
                                        <th>Status</th>
                                        <th>Player</th>
                                        <th>Bill Amt</th>
                                        <th>Payment ID</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                {(bookSheetList && bookSheetList.length > 0 &&
                                    bookSheetList.map((b, i) => (
                                        <tbody key={i} style={{borderTop: 'none'}}>
                                            <tr >
                                                <td>{b.SlotFrom} To {b.SlotTo}</td>
                                                <td>
                                                    <div className={`badge badge-${b.Status == null ? 'success' : 'danger'}`}>
                                                        <span>{b.Status == null ? 'Available' : b.Status}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    {b.Player}
                                                </td>
                                                <td>{b.PGAmount}</td>
                                                <td>{b.PaymentID}</td>
                                                <td>
                                                    <button 
                                                        type="button"
                                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                    
                                                        onClick={() => openDrawerHandler(b)}
                                                    >  
                                                        <i className="flaticon2-edit"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))
                                )}
                            </table>
                            {rightSideDetails && 
                                <RightSideDetails title="Booking Sheet" onCloseDrawer={closeDrawer}>
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body padding-t30 padding-b0">
                                            <form id="ModalHolder" className="kt-form kt-form--label-right padding-10">
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Date</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="lblDate" 
                                                            disabled="disabled" 
                                                            name="lblDate" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                                        />
                                                    </div>
                                                </div>
                                                <div id="pnlSlot" className="form-group row">
                                                    <label className="col-form-label col-lg-3">Slots</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="lblSlot" 
                                                            disabled="disabled" 
                                                            name="lblSlot" 
                                                            type="text"
                                                            onChange={updateBookingDetails} 
                                                            className="form-control" 
                                                            value={`${bookSheet.SlotFrom} To ${bookSheet.SlotTo}`}
                                                        />
                                                    </div>
                                                </div>
                                                <div id="UserHolder">
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">User</label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <div className="input-group" >
                                                                <div style={{width: "60%"}}  >
                                                                    <Select
                                                                        value={(usersList && usersList.length > 0 && bookSheet.UserID) && {label: `${usersList.find(m => m.ID == bookSheet.UserID).Name} (${usersList.find(m => m.ID == bookSheet.UserID).Mobile})`, value: bookSheet.UserID} || ''} 
                                                                        onChange={(event) => updateBookingDetails('UserID', event.value)}
                                                                        name="ddlUser"
                                                                        placeholder="Select User"
                                                                        id="ddlUser"
                                                                        className="ddlUser"
                                                                        isDisabled={bookSheet.ID ? true : false}
                                                                        options={usersList.map(c => ({
                                                                            label: `${c.Name} (${c.Mobile})`,
                                                                            value: c.ID
                                                                        }))}
                                                                    />
                                                                </div>
                                                                {!bookSheet.ID && (
                                                                    <div className="input-group-append" style={{zIndex: 0}}>
                                                                        <button className="btn btn-secondary kt-select-reset" type="button" onClick={resetUser}><i className="flaticon2-delete kt-icon-sm padding-0"></i></button>
                                                                    </div>
                                                                )}
                                                                {/* <button id="btnNewUser" className="btn btn-label-brand margin-l10" type="button" data-toggle="modal" data-target="#modalUser" onClick={() => setShowAddUser(true)}>New</button> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pnlType" className="form-group row">
                                                    <label className="col-form-label col-lg-3">Payment Mode <span className="red"></span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <div>
                                                            <Select 
                                                                id="ddlPaymentMode" 
                                                                name="ddlPaymentMode"
                                                                className="multi-custom"
                                                                isDisabled={bookSheet.ID ? true : false}
                                                                placeholder="Select Payment Mode"
                                                                defaultValue={ paymentMode.find((m) => m.value[0] )  || ''}
                                                                value={(paymentMode && paymentMode.length > 0 && bookSheet.PaymentMode) && {label: `${paymentMode.find(m => m.value == bookSheet.PaymentMode).value}`, value: bookSheet.PaymentMode} || ''}
                                                                onChange={(event) => updateBookingDetails('PaymentMode', event.value)}
                                                                options={paymentMode}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Bucket</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtBucket" 
                                                            name="txtBucket" 
                                                            type="number"
                                                            disabled={bookSheet.ID ? true : false}
                                                            className="form-control" 
                                                            value={bookSheet.Bucket || ''}
                                                            onChange={(event) => validateBucket('Bucket', event.target.value)} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Discount %</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtDiscount" 
                                                            name="txtDiscount" 
                                                            type="number"
                                                            disabled={bookSheet.ID ? true : false}
                                                            className="form-control" 
                                                            value={bookSheet.Discount || ''}
                                                            onChange={(event) => validateDiscount('Discount', event.target.value)} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Comment</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <textarea 
                                                            id="txtComment" 
                                                            name="txtComment" 
                                                            className="form-control" 
                                                            disabled={bookSheet.ID ? true : false}
                                                            rows="3"
                                                            value={bookSheet.Comment || ''}
                                                            onChange={(event) => updateBookingDetails('Comment', event.target.value)} 
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div className='form-group row'>
                                                    <label className='col-form-label col-6'>
                                                        {!bookSheet.ID && !gstNumber && (
                                                            <span>
                                                                <a id="lblGstNo" style={{ fontWeight: 'lighter', color: '#3699ff' }} className="lbl text-center margin-0" onClick={() => setShowGSTModal(true)}>Add GST Number</a>
                                                            </span>
                                                        )}
                                                        {gstNumber && (
                                                            <span className="clickable text-success" onClick={() => editCompanyHandler()}>
                                                                {gstNumber.CompanyName} : {gstNumber.GstNumber}
                                                            </span>
                                                        )}
                                                    </label>
                                                </div>
                                                {(amount && amount.GreenFee) && (
                                                    <div id="pnlPaymentBrekup">
                                                        <div id="pnlGreenFee" className="row margin-br5">
                                                            <div className="col-sm-10 col-xs-8 text-right">
                                                                <h6 className="margin-0">Green Fee:</h6>
                                                            </div>
                                                            <div className="col-sm-2 col-xs-4 text-right">
                                                                <h6 className="margin-0">₹ <span id="lblTotalGreenFee">{amount.GreenFee}</span></h6>
                                                            </div>
                                                        </div>
                                                        <div id="pnlBucket" className="row margin-br5">
                                                            <div className="col-sm-10 col-xs-8 text-right">
                                                                <h6 className="margin-0">{bookSheet.Bucket} Bucket:</h6>
                                                            </div>
                                                            <div className="col-sm-2 col-xs-4 text-right">
                                                                <h6 className="margin-0">₹ <span id="lblBucket">{amount.Bucket}</span></h6>
                                                            </div>
                                                        </div>
                                                        {bookSheet.Discount && (
                                                            <div id="pnlDiscount" className="row margin-br5">
                                                                <div className="col-sm-10 col-xs-8 text-right">
                                                                    <h6 className="margin-0">Discount:</h6>
                                                                </div>
                                                                <div className="col-sm-2 col-xs-4 text-right">
                                                                    <h6 className="margin-0">- ₹ <span id="lblDiscount">{amount.DiscountedRate}</span></h6>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="row margin-br5">
                                                            <div className="col-sm-10 col-xs-8 text-right">
                                                                <h6 className="margin-0">SGST 9%:</h6>
                                                            </div>
                                                            <div className="col-sm-2 col-xs-4 text-right">
                                                                <h6 className="margin-0">₹ <span id="lblSGST">{amount.SGST}</span></h6>
                                                            </div>
                                                        </div>
                                                        <div className="row margin-br5">
                                                            <div className="col-sm-10 col-xs-8 text-right">
                                                                <h6 className="margin-0">CGST 9%:</h6>
                                                            </div>
                                                            <div className="col-sm-2 col-xs-4 text-right">
                                                                <h6 className="margin-0">₹ <span id="lblCGST">{amount.CGST}</span></h6>
                                                            </div>
                                                        </div>
                                                        <div className="row margin-br5">
                                                            <div className="col-sm-10 col-xs-8 text-right">
                                                                <h6 className="margin-0"><strong>Total:</strong></h6>
                                                            </div>
                                                            <div className="col-sm-2 col-xs-4 text-right">
                                                                <h6 className="margin-0"><strong>₹ <span id="lblFinalAmount">{amount.Total}</span></strong></h6>
                                                            </div>
                                                        </div>
                                                        {bookSheet.ID && (
                                                            <>  
                                                                <div className="row margin-bl5">
                                                                    <div className="col-sm-12">
                                                                        <h6 className="margin-0"><strong id="lblOwner">Booked By Golf {bookSheet.OwnerType}</strong></h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row margin-l5 margin-b20">
                                                                    <div className="col-sm-12">
                                                                        <h6 className="margin-0"><strong id="lblPaymentID">{bookSheet.PaymentID}</strong></h6>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </form>
                                        </div>
                                        <div className="kt-portlet__foot">
                                            <div className="kt-form__actions">
                                                <div className="row">
                                                    <div className="col-lg-12 text-right">
                                                        {!bookSheet.ID && (
                                                            <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addBookingHandler}>{bookSheet.ID ? 'Update Booking' : 'Save Booking' }</button>
                                                        )}
                                                        {/* {bookSheet && 
                                                            bookSheet.ID && <button id="btnCancel" type="button" className="btn btn-warning d-inline-block margin-l10" onClick={cancelBookingHandler}>Cancel Booking</button>
                                                        } */}
                                                        <button id="btnCloseDrawer" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </RightSideDetails>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {editCompany && (
                <AddGSTCompanyModal
                    action='e'
                    setRefresh={() => setRefresh(true)}
                    companyDetails={editCompany}
                    gstNumber={setGstNumber}
                    show={editCompany}
                    companies={companies}
                    onDismissModal={() => setEditCompany(null)}
                />
            )}
            {showGSTModal && (
                <AddGSTCompanyModal
                    action='a'
                    show={showGSTModal}
                    gstNumber={setGstNumber}
                    companies={companies}
                    onDismissModal={() => setShowGSTModal(false)}
                />
            )}
        </>
    )
}

export default MatBookingSheet;