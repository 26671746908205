import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import SideMenu from '../components/global/sideMenu';
import Header from '../components/global/header';
import AdminUser from '../components/settings/adminUser';
import AdminMenu from '../components/settings/adminMenu';
import AdminRole from '../components/settings/adminRole';
import EmailTemplate from '../components/settings/emailTemplate';
import AdminUpdate from '../components/appControl/adminUpdate';
import AdminService from '../components/appControl/adminService';
import AndroidUpdate from '../components/appControl/androidUpdate';
import AndroidService from '../components/appControl/androidService';
import IOSUpdate from '../components/appControl/iOSUpdate';
import IOSService from '../components/appControl/iOSService';
import AdvertisementMaster from '../components/advertisment/advertisementMaster';
import NoticeBoard from '../components/golfClub/noticeBoard';
import EventRegistration from '../components/golfClub/eventRegistration';
import Event from '../components/golfClub/event';
import HolidayCalender from '../components/golfClub/holidayCalender';
import TournamentCalender from '../components/golfClub/tournamentCalender';
import Club from '../components/golfClub/club';
import TeeBox from '../components/golfClub/teeBox';
import Course from '../components/golfClub/course';
import CourseClosed from '../components/golfClub/courseClosed';
import StaggeredMaster from '../components/teeTime/staggeredMaster';
import StaggeredBlock from '../components/teeTime/staggeredBlock';
import ShotgunMaster from '../components/teeTime/shotgunMaster';
import ShotgunBlock from '../components/teeTime/shotgunBlock';
import ShotgunCalender from '../components/teeTime/shotgunCalender';
import Users from '../components/users/users';
import StaggeredTemplate from '../components/teeTime/staggeredTemplate';
import ShotgunTemplate from '../components/teeTime/shotgunTemplate';
import TeeTimePublish from '../components/teeTime/teeTimePublish';
import TeeTimeBlocking from '../components/teeTime/teeTimeBlocking';
import HoleInOne from '../components/golfClub/holeInOne';
import Feedback from '../components/golfClub/feedback';
import BookingSheet from '../components/teeTime/bookingSheet';
import Package from '../components/academy/package';
import Lesson from '../components/academy/lesson';
import AcademyFees from '../components/academy/AcademyFees';
import Subscriber from '../components/academy/subscriber';
import Coach from '../components/academy/Coach';
import Booking from '../components/academy/Booking';
import MatMaster from '../components/mat/MatMaster';
import MatTimeMaster from '../components/mat/MatTimeMaster';
import MatTemplate from '../components/mat/MatTemplate';
import MatBlocking from '../components/mat/MatBlocking';
import MatBookingSheet from '../components/mat/MatBookingSheet';

const Admin = (props) => {

    const [headerTitle,                   setHeaderTitle]                      = useState('');
    const [menuID,                        setMenuID]                           = useState(null);

    const toggleState = (title, menuID) => {
		setHeaderTitle(title);
        setMenuID(menuID);
	};

    return (  
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <SideMenu menuID={menuID} title={toggleState} />
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                
                <Header onLogout={props.onAuthStateChange} title={headerTitle} /> 
                {menuID && <div style={{ paddingTop: '0px' }} id="kt_content" className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0">
                    <Switch>
                        {/*Mat*/}
                        <Route path="/mat-sheet" exact>
                            <MatBookingSheet
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/mat-master" exact>
                            <MatMaster
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/mat-time-master" exact>
                            <MatTimeMaster
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/mat-template" exact>
                            <MatTemplate
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/mat-block" exact>
                            <MatBlocking
                                title={toggleState}
                            />
                        </Route>
                        {/*Mat End*/}

                        {/*Academy*/}
                        <Route path="/coach" exact>
                            <Coach
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/booking" exact>
                            <Booking
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/package" exact>
                            <Package
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/lesson" exact>
                            <Lesson
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/academy-fees" exact>
                            <AcademyFees
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/subscriber" exact>
                            <Subscriber
                                title={toggleState}
                            />
                        </Route>
                        {/*Academy End*/}

                        {/* Settings */}
                        <Route path="/admin-user" exact>
                            <AdminUser
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/admin-role" exact>
                            <AdminRole
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/admin-menu" exact>
                            <AdminMenu
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/email-template" exact>
                            <EmailTemplate
                                title={toggleState} 
                            />
                        </Route>
                        {/* Settings End*/}

                        {/* Golf Club */}
                        <Route path="/notice-board" exact>
                            <NoticeBoard
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/event" exact>
                            <Event
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/event-registration" exact>
                            <EventRegistration
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/holiday-calender" exact>
                            <HolidayCalender
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/tournament-calender" exact>
                            <TournamentCalender
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/club" exact>
                            <Club
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/teebox" exact>
                            <TeeBox
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/course-closed" exact>
                            <CourseClosed
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/course" exact>
                            <Course
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/event" exact>
                            <Event
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/event-registration" exact>
                            <EventRegistration
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/feedback" exact>
                            <Feedback
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/hole-in-one" exact>
                            <HoleInOne
                                title={toggleState} 
                            />
                        </Route>
                        {/* Golf Club End*/}

                        {/* Advertisement */}
                        <Route path="/advertisement-master" exact>
                            <AdvertisementMaster
                                title={toggleState} 
                            />
                        </Route>
                        {/* Advertisement End */}

                        {/* Tee Time */}
                        <Route path="/tee-sheet">
                            <BookingSheet
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/staggered-master">
                            <StaggeredMaster 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/staggered-block">
                            <StaggeredBlock 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/staggered-template">
                            <StaggeredTemplate
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/shotgun-master">
                            <ShotgunMaster 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/shotgun-block">
                            <ShotgunBlock 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/shotgun-calendar">
                            <ShotgunCalender 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/shotgun-template">
                            <ShotgunTemplate 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/teetime-publish">
                            <TeeTimePublish title={toggleState}/>
                        </Route>
                        <Route path="/tee-time-blocking">
                            <TeeTimeBlocking 
                                title={toggleState}
                            />
                        </Route>
                        {/* Tee Time End */}

                        {/* App Control */}
                        <Route path="/android-update" exact>
                            <AndroidUpdate
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/android-service" exact>
                            <AndroidService
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/ios-update" exact>
                            <IOSUpdate
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/ios-service" exact>
                            <IOSService
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/admin-update" exact>
                            <AdminUpdate
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/admin-service" exact>
                            <AdminService
                                title={toggleState} 
                            />
                        </Route>
                        {/* App Control End*/}

                        {/* Users */}
                        <Route path="/user" exact>
                            <Users
                                title={toggleState} 
                            />
                        </Route>
                        {/* Users End*/}


                        {/* Default */}
                        <Redirect to={'/' + menuID} />
                    </Switch>
                </div>}
            </div>
        </div>
    );    
}

export default withRouter(Admin);