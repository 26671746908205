import {React, useEffect, useState} from 'react'
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import Select from 'react-select';
import '../../assets/scripts/jquery-validation.init';
import { getActivePackage, getPackageAll, getPackageSlot, getSubscriber, postSubscriber } from '../api/academyAPI';
import { getActiveUser, getUserAll } from '../api/usersAPI';
import AddSubscriber from './AddSubscriber';
import NoData from '../global/noData';
import LessonCompletionModal from './LessonCompletionModal';

const Subscriber = (props) => {

    const [isInit,              setIsInit]              = useState(true);
    const [rightSideDetails,    setRightSideDetails]    = useState(false);
    const [subscriberDetails,   setSubscriberDetails]   = useState({});
    const [usersList,           setUsersList]           = useState([]);
    const [subscriberList,      setSubscriberList]      = useState([]);
    const [slotList,            setSlotList]            = useState([]);
    const [navState,            setNavState]            = useState('Subscriber');
    const [outerNavState,       setOuterNavState]       = useState('All Packages');
    const [packageList,         setPackageList]         = useState([]);
    const [selectedPackage,     setSelectedPackage]     = useState(null);
    const [selectedSubscriber,  setSelectedSubscriber]  = useState(null);


    // const []
    useEffect(() => {
        props.title("Package", "subscriber")
    }, []);

    useEffect(() => {
        if (outerNavState) {
            fetchPackage();
        }
    }, [outerNavState])

    useEffect(() => {
        if(rightSideDetails){
            Config.Core.OpenDrawer()  
        } else {
            setSelectedPackage(null);
            setSubscriberDetails({})
        }

        if (rightSideDetails && navState === 'Subscriber') {
            fetchUsers()
            $('#txtStartDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date(),
            });
            $('#txtStartDate').on('change', function (event) {
                updateSubscriberDetails('StartDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });
        }
    }, [rightSideDetails, navState]);

    useEffect(() => {
        if (selectedPackage)
            fetchSubscriber(selectedPackage.ID)
    }, [selectedPackage])
    
    useEffect(() => {       
        if (!isInit) {
            fillDataTable();        
        }
    }, [packageList]);

    const fetchPackage = async() => {
        try {
            const response = await getPackageAll()
            isInit ? setIsInit(false) : $('#tblPackage').KTDatatable().destroy();
            outerNavState == 'All Packages' ? setPackageList(response) : outerNavState == 'Individual' ? setPackageList([...response.filter(f => f.Type == 'Individual')]) : setPackageList([...response.filter(f => f.Type == 'Group')]);
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const updateSubscriberDetails = (field, value) => {   
        setSubscriberDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const fetchSubscriber = async(id) => {
        try {
            const response = await getSubscriber(id);
            setSubscriberList(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchPackageSlot = async (id) => {
        try {
            const response = await getPackageSlot(id);
            setSlotList(response)  
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchUsers = async () => {
        try {
            const users = await getActiveUser();
            setUsersList(users);
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const AddSubscriberHandler = async () => {
        const btn = $('#add-btn');
        var form = $('#add-subscriber-form');
        form.validate({
            rules: {
                ddlSelectUser: {
                    required: true
                },
                txtStartDate: {
                    required: true,
                }           
            },
            messages: {
                ddlSelectUser: {
                    required: "Please select name"
                },
                txtStartDate: {
                    required: "Please select date"
                },
            }
        });

        if (!form.valid()) return;

        if (selectedPackage.Type === 'Group' && subscriberList.length >= selectedPackage.GroupSize) {
            swal.fire({
                icon                  : 'warning',
                // titleText             : 'Error!',
                text                  : "Package full",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return;
        }
        helper.StartProcessing(btn);    
        try {
            await postSubscriber({...subscriberDetails, PackageID: selectedPackage.ID});
            setSubscriberDetails({});
            fetchSubscriber(selectedPackage.ID)
            helper.StopProcessing(btn);
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: 'Subscriber added successfully!',
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,                                       
            });
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const closeDrawer = () => {
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    function fillDataTable() {

        const dataTable = $('#tblPackage').KTDatatable({
            data: {
                saveState: false,
                source: packageList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Title',
                    title: 'Title',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.BannerImage && row.BannerImage != '') ? `<img src="${row.BannerImage}" height="40" width="40" alt="banner-image" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Title.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Title} </span>
                                    <span class="kt-user-card-v2__desc text-clip"> ${row.Type} </span>
                                </div>
                            </div>`
                    }
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        if (row.Active == 'Y') 
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                               
                        else
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">No</span>'
                    },
                    width: 50,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblPackage .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setSelectedPackage(dataTable.dataSet[index]);
                setRightSideDetails(true); 
            }    
        });

    }

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <nav className="file-nav navbar navbar-top">
                                <a className={outerNavState === "All Packages" ? "top-nav-active" : ""} onClick={() => setOuterNavState("All Packages")}>  
                                    All Packages 
                                </a>
                                <a className={outerNavState === "Individual" ? "top-nav-active" : "" } onClick={() => setOuterNavState("Individual")}  >  
                                    Individual
                                </a>
                                <a className={outerNavState === "Group" ? "top-nav-active" : "" } onClick={() => setOuterNavState("Group")}  >  
                                    Group
                                </a>
                            </nav>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="row align-items-center margin-l10">
                                <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <input type="text" className="form-control clientSearch" placeholder="Search Package..." id="generalSearch" />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div id="tblPackage" className="kt-datatable table-striped clickable"></div>
                        {rightSideDetails && (
                            <RightSideDetails title="Subscribers" onCloseDrawer={closeDrawer}>
                            <div className="kt-portlet">
                                    <div className="kt-portlet__body">
                                        <div className="kt-widget kt-widget--user-profile-3">
                                            <div className="kt-widget__top">
                                                {selectedPackage.BannerImage ? (

                                                    <div className="kt-widget__pic kt-widget__pic--brand">
                                                        <div className="kt-media kt-media--circle">
                                                            <img src={selectedPackage.BannerImage} alt="image"/>
                                                        </div>
                                                    </div>
                                                ) : (

                                                    <div className="kt-widget__pic kt-widget__pic--brand kt-font-brand kt-font-boldest">
                                                        {selectedPackage.Title && selectedPackage.Title.slice(0,1)}
                                                    </div>
                                                )}
                                                <div className="kt-widget__content">
                                                    <div className="row align-items-center">
                                                        <div className="col-5">
                                                            <div className="kt-widget__head">
                                                                <span className="kt-widget__username">
                                                                    {selectedPackage.Title}
                                                                </span>
                                                            </div>
                                                            <div className="kt-widget__subhead padding-t0">
                                                                <span className="padding-r10">
                                                                    <i className="flaticon2-calendar-3"></i>
                                                                    {selectedPackage.Type}
                                                                </span>
                                                                {selectedPackage.Type === 'Group' && (
                                                                    <span className="padding-r10">
                                                                        <i className="flaticon-users"></i>
                                                                        Group Strength : {selectedPackage.GroupSize}
                                                                    </span>
                                                                )}
                                                                <br/>
                                                                {selectedPackage.Type === 'Individual' && (
                                                                    <span className="padding-r10">
                                                                        <i className="flaticon-calendar-1"></i>
                                                                        Duration (in days) : {selectedPackage.Duration}
                                                                    </span>
                                                                )}
                                                                {selectedPackage.Type === 'Group' && (
                                                                    <span className="padding-r10">
                                                                        <i className="flaticon-calendar-1"></i>
                                                                        Duration : {moment(selectedPackage.FromDate).format('Do MMM YYYY')} - {moment(selectedPackage.ToDate).format('Do MMM YYYY')}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet kt-prtlet--mobile">
                                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                                        <div className="kt-portlet__head-label">
                                            <nav className="file-nav navbar navbar-top">
                                                <a className={navState === 'Subscriber' ? 'top-nav-active' : ''}
                                                    onClick={() => setNavState('Subscriber')}>
                                                    Subscriber
                                                </a>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__body padding-30">
                                        {navState === 'Subscriber' && (
                                            <>
                                                <form className="kt-form kt-form--label-right" id="add-subscriber-form" onSubmit={e => e.preventDefault()}>
                                                    <div className="form-group row">
                                                        <div className="col-5">
                                                            <label>Select User</label>
                                                            <Select
                                                                value={(usersList && usersList.length > 0 && subscriberDetails.UserID) && {label:  `${usersList.find(m => m.ID == subscriberDetails.UserID).Name} (${usersList.find(m => m.ID == subscriberDetails.UserID).Mobile})`, value: subscriberDetails.UserID} || ''}
                                                                onChange={(event) => updateSubscriberDetails('UserID', event.value)}
                                                                name="ddlSelectUser"
                                                                placeholder="Select User"
                                                                id="ddlSelectUser"
                                                                className="ddlSelectUser"
                                                                options={usersList.map(c => ({
                                                                    label: `${c.Name} (${c.Mobile})`,
                                                                    value: c.ID
                                                                }))}
                                                            />
                                                        </div>
                                                        {selectedPackage.Type === 'Individual' && (
                                                            <div className="col-5">
                                                                <label className="">Start Date</label>
                                                                <div className="input-group date">
                                                                    <input 
                                                                        id="txtStartDate" 
                                                                        name="txtStartDate" 
                                                                        type="text" 
                                                                        className="form-control date-input" 
                                                                        readOnly={true} 
                                                                        placeholder="Select Start Date" 
                                                                        value={subscriberDetails.StartDate ? moment(subscriberDetails.StartDate).format('DD-MM-YYYY') : ''} 
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text">
                                                                            <i className="la la-calendar-check-o"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className='col-2 pt-4'>
                                                            <button className="btn btn-brand btn-sm margin-t10 margin-l0" id='add-btn' onClick={AddSubscriberHandler} >
                                                                <i className="la la-plus"></i>
                                                                Add
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                                {(subscriberList && subscriberList.length > 0) ? (
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                                                <table className="kt-datatable__table">
                                                                    <thead
                                                                        className="kt-datatable__head"
                                                                        style={{ display: 'block' }}>

                                                                        <tr className="kt-datatable__row block-row">
                                                                            <th className="kt-datatable__cell text-clip" width="35%">
                                                                                <span className="padding-l10">Name</span>
                                                                            </th>
                                                                            <th className="kt-datatable__cell text-clip" width="30%">
                                                                                <span>Mobile</span>
                                                                            </th>
                                                                            {selectedPackage.Type === 'Individual' && (
                                                                                <th className="kt-datatable__cell text-clip" width="20%">
                                                                                    <span>Start Date</span>
                                                                                </th>
                                                                            )}
                                                                            {/* <th className="kt-datatable__cell text-clip" width="15%">
                                                                                <span>Action</span>
                                                                            </th> */}
                                                                        </tr>
                                                                    </thead>
                                                                </table>
                                                            </div>
                                                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                                                                <table className="kt-datatable__table table-striped">
                                                                    <tbody className="kt-datatable__body" style={{ display: 'block' }} >
                                                                        {subscriberList.map((m, i) => (
                                                                            <tr key={i} className="kt-datatable__row block-row clickable" onClick={() => setSelectedSubscriber(m)}>
                                                                                <td  width="35%" className="padding-l20 kt-datatable__cell text-clip">
                                                                                    <div className="kt-user-card-v2">
                                                                                        <div className="kt-user-card-v2__pic">
                                                                                            {m.Photo && m.Photo != '' ? 
                                                                                            <img src={m.Photo} alt="user-profile" /> : 
                                                                                            <div className={`kt-badge kt-badge--xl kt-badge--info`}> {m.Name.substring(0, 1)}</div>}
                                                                                        </div>
                                                                                        <div className="kt-user-card-v2__details text-clip">
                                                                                            <span className="kt-user-card-v2__name text-clip"> {m.Name} </span>
                                                                                            <span className="kt-user-card-v2__desc text-clip">{m.Email}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td width="30%" className="kt-datatable__cell text-clip">
                                                                                    <span>{m.Mobile || ''}</span>
                                                                                </td>
                                                                                {selectedPackage.Type === 'Individual' && (
                                                                                    <td width="20%" className="kt-datatable__cell text-clip">
                                                                                        <span>{m.StartDate}</span>
                                                                                    </td>
                                                                                )}
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <NoData
                                                        message='No Subscriber Found'
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </RightSideDetails>
                        )}
                    </div>
                </div>
            </div>
            {selectedSubscriber && (
                <LessonCompletionModal
                    show={selectedSubscriber}
                    subscriberDetails={selectedSubscriber}
                    onDismissModal={setSelectedSubscriber}
                />
            )}
        </>
    )
}

export default Subscriber