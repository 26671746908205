import React, {useState, useEffect} from 'react'
import { getCompletedLessonBySubLessonIDAndUserID, getCompletedLessonByUserID, getLessonAndSubLessonByPackageID, getLessonByPackageID, getSubLessonByLessonID, postCompletedLesson } from '../api/academyAPI';
import NoData from '../global/noData';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import Loading from '../global/loading';

function LessonCompletionModal(props) {

    const [lessonList,          setLessonList]          = useState([]);
    const [subLessonList,       setSubLessonList]       = useState([]);
    const [subLessonDetails,    setSubLessonDetails]    = useState({Active: 'Y'});
    const [editSubLesson,       setEditSubLesson]       = useState(false);
    const [editLesson,          setEditLesson]          = useState(false);
    const [loading,             setLoading]             = useState(false);
    const [isExpanded,          setIsExpanded]          = useState(null);
    const [selectedLesson,      setSelectedLesson]      = useState(null);
    const [completedLesson,     setCompletedLesson]     = useState([]);
    const [filteredLessonList,     setFilteredLessonList]     = useState([]);

    useEffect(() => {
        $('#lesson-completion-modal').modal({
            backdrop: 'static',
        });
        $('#lesson-completion-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(null);
        });
        if (props.subscriberDetails) {
            fetchLesson(props.subscriberDetails.PackageID)
        }
    }, [props.show])

    useEffect(() => {
        const lessonArrayWithCompletionStatus = lessonList.map(lesson => {
            const subLessonWithSameLessonID = subLessonList.filter(s => s.LessonID == lesson.ID).map(m => m.SubLessonID)
            const completedLessonList = completedLesson.map(e => e.SubLessonID)
            const isLessonCompleted = subLessonWithSameLessonID.every(e => completedLessonList.includes(e))
            return { ...lesson, Completed: isLessonCompleted };
        });
        setFilteredLessonList(lessonArrayWithCompletionStatus)
    }, [completedLesson, subLessonList, lessonList])

    useEffect(() => {
        if (isExpanded && isExpanded.id && isExpanded.expand == 'true') {
            // fetchSubLesson(isExpanded.id)
        }
    }, [isExpanded]);


    const cardClick = (id, index) => {
        const expandedID = document.getElementById(index);
        const expand = expandedID.getAttribute('aria-expanded');
        const cardDetail = {expand, id}
        setIsExpanded(cardDetail)
        setSelectedLesson(id)
    }

    // const fetchSubLesson = async(id) => {
    //     try {
    //         setLoading(true)
    //         const response = await getSubLessonByLessonID(id)
    //         const res = await getCompletedLessonByUserID(props.subscriberDetails.UserID)
    //         setIsExpanded(null)
    //         setSubLessonList(response);
    //         setCompletedLesson(res);
    //         setLoading(false)
    //     } catch (error) {
    //         setLoading(false)
    //         swal.fire({
    //             icon      : 'error',
    //             titleText : error.message
    //         })  
    //     }
    // }

    const fetchLesson = async(id) => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();

                }
            });
            const response = await getLessonAndSubLessonByPackageID(id);
            const res = await getCompletedLessonByUserID(props.subscriberDetails.UserID)
            setLessonList(response.lesson);   
            setSubLessonList(response.subLesson) 
            setCompletedLesson(res);
            swal.close()
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const completeLessonHandler = async(isChecked, subLesson) =>  {
        try {
            const res = await postCompletedLesson(isChecked, subLesson.SubLessonID, props.subscriberDetails.UserID)
            setCompletedLesson(res)
            swal.fire({
                toast             : true,
                icon              : 'success',
                titleText         : `${isChecked ? 'Lesson Completed' : 'Lesson Incomplete'} `,
                position          : 'bottom-end',
                showConfirmButton : false,
                timer             : 1500,
                animation         : false,
                customClass       : {
                    popup: 'margin-20',
                },
            });
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        } 
    }
    
    const closeDrawer = () => {

    }

    return (
        <div className="modal fade" id="lesson-completion-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Lesson Completion Modal</h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={closeDrawer}
                        >
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="accordion accordion-toggle-arrow" id="accordionExample4">
                            {(filteredLessonList && filteredLessonList.length > 0) ? (
                                filteredLessonList.map((m, i) => {
                                    {/* const isLessonCompleted = subLessonList && subLessonList.length > 0 &&
                                        subLessonList.every(s => completedLesson.find(a => a.SubLessonID === s.ID) !== undefined); */}

                                    return(
                                        <div className="card" key={i} >
                                            <div className="card-header" id="menuHeading" onClick={() => cardClick(m.ID, i)}>
                                                <div className="card-title collapsed" id={i}  data-toggle="collapse" data-target={`#${helper.SanitizeUrl(m.Title)}`} aria-expanded="false" aria-controls={helper.SanitizeUrl(m.Title)}>
                                                    <div className="col-9">
                                                        <span>{i + 1}.</span>
                                                        <span className="margin-l10">{m.Title}</span>
                                                    </div>
                                                    {/* <div className="col-1">
                                                        <span className={`kt-badge badge-active kt-badge--${m.Active == 'Y' ? 'success' : 'grey'} kt-badge--pill  padding-lr20 padding-tb10`}>{m.Active == 'Y' ? 'Active' : 'Inactive'}</span>
                                                    </div> */}
                                                    <div className="col-3">
                                                        <span className={`kt-badge badge-active kt-badge--${m.Completed ? 'success' : 'danger'} kt-badge--pill padding-lr40 padding-tb10`}>
                                                            {m.Completed ? 'Completed' : 'Incomplete'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id={helper.SanitizeUrl(m.Title)} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample4" style={{}}>
                                                <div className="card-body">
                                                    <div className="kt-widget4">
                                                        {loading ? <Loading/> : (
                                                            subLessonList && subLessonList.length > 0) && subLessonList.filter(f => f.LessonID == m.ID).map((s, i) => (
                                                                <div className="kt-widget4__item padding-tb5" key={i}>
                                                                    <div className="col-10">
                                                                        <div className="kt-widget4__info margin-t10 margin-b10">
                                                                            <span></span>
                                                                            <span className="kt-widget4__username">{i + 1}. {s.SubLessonTitle}</span>
                                                                        </div>
                                                                    </div>
                                                                    <label className="kt-checkbox kt-checkbox--bold kt-checkbox--success">
                                                                        <input 
                                                                            className='chkActive'
                                                                            defaultChecked={completedLesson && completedLesson.find(a => a.SubLessonID === s.SubLessonID ? 'checked' : '')}
                                                                            onClick={(e) => completeLessonHandler(e.target.checked, s)}
                                                                            type="checkbox"/>
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                )})
                            ) : (
                                <NoData
                                    message='No Lesson Found'
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LessonCompletionModal