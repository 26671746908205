import { useEffect } from "react";
import { useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import { getCourseClosedAll, postCourseClosed } from "../api/golfClubAPI";
import swal from 'sweetalert2';
import * as helper from '../global/helper';

const CourseClosed  = (props) => {

    const [courseClosed,            setCourseClosed]            = useState({});
    const [courseClosedList,        setCourseClosedList]        = useState([]);
    const [isInit,                  setIsInit]                  = useState(true);
    const [rightSideDetails,        setRightSideDetails]        = useState(false);

    useEffect(() => {
        props.title("Course Closed", "course-closed")   
        fetchCourseClosed();
    }, []);

    useEffect(() =>{
        if(rightSideDetails){
            Config.Core.OpenDrawer()      
            $('#txtDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date(),
            });
            $('#txtDate').on('change', function (event) {
                updateCourseClosedDetails('Date',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });  
        }else{
            setCourseClosed({})
        }
    }, [rightSideDetails])
    
    useEffect(() => {       
        if (!isInit) {
            fillDataTable();                      
        }
    }, [courseClosedList]);

    
    const fetchCourseClosed = async() =>{
        try {
            const course = await getCourseClosedAll()   
            isInit ? setIsInit(false) : $('#tblCourseClosed').KTDatatable().destroy(); 
            setCourseClosedList(course); 
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    function fillDataTable() {
        
        const dataTable = $('#tblCourseClosed').KTDatatable({
            data: {
                saveState: false,
                source: courseClosedList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'ID',
                    title: 'ID',
                    width: 20,
                    type: 'number',
                    textAlign: 'center',
                },
                {
                    field: 'Name',
                    title: 'Name',
                    
                },
                {
                    field: 'Date',
                    title: 'Date',
                    template: function (row) {
                        return moment(row.Date).format('DD-MMM-YYYY');
                    }
                },
            ]
        });
        
        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblCourseClosed .kt-datatable__table').addClass('overflow-x-auto');
        
        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setCourseClosed(dataTable.dataSet[index]);  
                setRightSideDetails(true);
            } 
        });
        
    }

    const updateCourseClosedDetails = (field, value) =>{
        setCourseClosed((d) => {
            return { ...d, [field]: value };
        });
    }

    const addCourseClosedHandler = async() => {

        const btn = $('#btnSave');
        var form = $('#add-courseClosed-form');
        
        form.validate({
            rules: {
                txtName: {
                    required: true
                },
                txtDate: {
                    required: true,
                }         
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                },
                txtDate: {
                    required: "Please select date"
                }
            }
        });

        if (!form.valid()) return;
        if(courseClosedList.find((d) => d.ID !== courseClosed.ID && moment(courseClosed.Date).isSame(moment(d.Date).format('YYYY-MM-DD')))){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Course Closed Already Exist!',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return  
        }

        helper.StartProcessing(btn);

        try {
            await postCourseClosed(courseClosed);
            helper.StopProcessing(btn);
            closeDrawer();
            fetchCourseClosed();
            if (courseClosed.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Course Closed updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Course Closed added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }
    
    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    return( 
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Course Closed..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblCourseClosed" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Course Closed Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form className="kt-form" id="add-courseClosed-form">
                                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">                                       
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtName" 
                                                            name="txtName" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={courseClosed.Name || ''}
                                                            onChange={(event) => updateCourseClosedDetails('Name', event.target.value)}  
                                                        />
                                                        </div>
                                                </div>    
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Date <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <div className="input-group date">
                                                            <input 
                                                                id="txtDate" 
                                                                name="txtDate" 
                                                                type="text" 
                                                                className="form-control date-input" 
                                                                readOnly={true} 
                                                                placeholder="Select date" 
                                                                value={courseClosed.Date ? moment(courseClosed.Date).format('DD-MM-YYYY') : ''} 
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="la la-calendar-check-o"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>   
                                                </div>                                                                                                                     
                                                <div className="kt-portlet__foot">
                                                    <div className="kt-form__actions">
                                                        <div className="row">
                                                        <div className="col-lg-9 ml-lg-auto">
                                                            <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addCourseClosedHandler}>Save</button>
                                                            <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>
            </div>  
        </>
    )
}

export default CourseClosed;