import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getAdminUser, postAdminUser } from "../api/adminAPI";

const AdminUser = (props) =>{

    const [user,                setUser]                = useState({Active: 'Y'});
    const [userList,            setUserList]            = useState([]);
    const [isInit,              setIsInit]              = useState(true);
    const [rightSideDetails,    setRightSideDetails]    = useState(false);

    useEffect(() => {
        props.title("Admin User", "admin-user")
        fetchAdminUser();
    }, []);

    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : setUser({Active: 'Y'});
    }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();
        }
    }, [userList]);
    
    const fetchAdminUser = async() =>{
        try {
            const users = await getAdminUser()   
            isInit ? setIsInit(false) : $('#tblAdminUser').KTDatatable().destroy(); 
            setUserList(users);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }
    const fillDataTable = () =>{
        const dataTable = $('#tblAdminUser').KTDatatable({
            data: {
                saveState: false,
                source: userList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Name',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                    ${` <div class="kt-badge kt-badge--xl kt-badge--info" > ${row.UserName.substring(0, 1)}</div >`}
                                    </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.UserName}  </span> <span class="kt-user-card-v2__desc text-clip"> ${row.Email} </span>
                                    </div>
                                </div>`
                    }
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        return row.Active == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                    },
                    width: 50,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblAdminUser .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);          
            if (dataTable.dataSet[index]) {
                setUser(dataTable.dataSet[index]);  
                setRightSideDetails(true);           
            }                
        });
    }
    
    const updateUserDetails = (field, value) => {   
        setUser((d) => {
            return { ...d, [field]: value };
        });
    };

    const addUserHandler = async() => {

        const btn = $('#btnSave');
        var form = $('#add-AdminUser-form');
        
        form.validate({
            rules: {
                txtName: {
                    required: true
                },
                txtEmail: {
                    required: true,
                }         
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                },
                txtEmail: {
                    required: "Please enter email"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            await postAdminUser(user);
            helper.StopProcessing(btn);
            closeDrawer();
            fetchAdminUser();
            if (user.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'User updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'User added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }
    return(
        <>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <div className="row align-items-center margin-l10">
                                <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <input type="text" className="form-control clientSearch" placeholder="Search User..." id="generalSearch" />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    <button onClick={() => setRightSideDetails(true)}  type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New User</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div id="tblAdminUser" className="kt-datatable table-striped clickable"></div>
                        {rightSideDetails && <RightSideDetails title="User Details" onCloseDrawer={closeDrawer}>
                            <div className="kt-portlet">
                                <div className="kt-portlet__body padding-t30 padding-b0">
                                    <form className="kt-form" id="add-AdminUser-form">
                                        <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                            <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">User Name<span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input
                                                        id="txtName" 
                                                        name="txtName" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={user.UserName || ''}
                                                        onChange={(event) => updateUserDetails('UserName', event.target.value)}               
                                                        />						
                                                </div>
                                            </div>   
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Email<span className="red">*</span></label>
                                                <div className="col-xl-4 col-lg-5">
                                                    <input 
                                                        id="txtEmail" 
                                                        name="txtEmail" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={user.Email || ''}
                                                        onChange={(event) => updateUserDetails('Email', event.target.value)} 
                                                    />
                                                </div>
                                            </div>                                        
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Active</label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <span className="kt-switch kt-switch--icon">
                                                        <label>
                                                            <input 
                                                                id="chkActive" 
                                                                name="chkActive" 
                                                                type="checkbox" 
                                                                defaultChecked={user.Active == 'N' ? '' : 'checked'}
                                                                onChange={(event) => updateUserDetails('Active', event.target.checked ? 'Y' : 'N')} 
                                                                />
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>                                           
                                        </div>
                                    </form>
                                </div>
                                <div className="kt-portlet__foot">
                                    <div className="kt-form__actions">
                                        <div className="row">
                                            <div className="col-lg-9 ml-lg-auto">
                                                <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addUserHandler}>Save</button>
                                                <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </RightSideDetails>}
                    </div>  
                </div>
            </div>
        </>
    )
}

export default AdminUser;