import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import * as helper from '../global/helper';
import { getEmailTemplate, postEmailTemplate } from "../api/golfClubAPI";
import swal from 'sweetalert2';

const EmailTemplate = (props) =>{

    const [template,                setTemplate]                = useState({});
    const [templateList,            setTemplateList]            = useState([]);
    const [isInit,                  setIsInit]                  = useState(true);
    const [rightSideDetails,        setRightSideDetails]        = useState(false);

    useEffect(() => {
        props.title("Email Template", "email-template")
        fetchEmailTemplate();
    }, []);

    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : setTemplate({});
    }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();
        }
    }, [templateList]);
    
    const fetchEmailTemplate = async() =>{
        try {
            const templates = await getEmailTemplate()   
            isInit ? setIsInit(false) : $('#tblEmailTamplate').KTDatatable().destroy(); 
            setTemplateList(templates);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    function fillDataTable() {

        const dataTable = $('#tblEmailTamplate').KTDatatable({
            data: {
                saveState: false,
                source: templateList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'ID',
                    title: 'ID',
                    width: 20,
                    type: 'number',
                    textAlign: 'center',
                },
                {
                    field: 'Type',
                    title: 'Type',

                },
                {
                    field: 'Name',
                    title: 'Name',

                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblEmailTamplate .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setTemplate(dataTable.dataSet[index]);  
                setRightSideDetails(true);   
            } 
        });

    }
    
    const updateTemplateDetails = (field, value) => {   
        setTemplate((d) => {
            return { ...d, [field]: value };
        });
    };

    const addTemplateHandler = async() => {

        const btn = $('#btnSave');
        var form = $('#add-emailTemplate-form');
        
        form.validate({
            rules: {
                txtType: {
                    required: true
                },
                txtName: {
                    required: true,
                },
                txtContent: {
                    required: true,
                }           
            },
            messages: {
                txtType: {
                    required: "Please enter type"
                },
                txtName: {
                    required: "Please enter name"
                },
                txtContent: {
                    required: "Please enter content"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            await postEmailTemplate(template);
            helper.StopProcessing(btn);
            closeDrawer();
            fetchEmailTemplate()

            if (template.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Template updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Template added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }
    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }
    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Template..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Template</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblEmailTamplate" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Template Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form className="kt-form" id="add-emailTemplate-form">
                                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Type <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtType" 
                                                            name="txtType" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={template.Type || ''}
                                                            onChange={(event) => updateTemplateDetails('Type', event.target.value)} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtName" 
                                                            name="txtName" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={template.Name || ''}
                                                            onChange={(event) => updateTemplateDetails('Name', event.target.value)} 
                                                        />
                                                    </div>
                                                </div>                                         
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Content <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <textarea 
                                                            id="txtContent" 
                                                            name="txtContent" 
                                                            className="form-control"
                                                            rows="3"
                                                            value={template.Content || ''}
                                                            onChange={(event) => updateTemplateDetails('Content', event.target.value)} 
                                                        >
                                                        </textarea>
                                                    </div>
                                                </div>
                                                <div className="kt-portlet__foot">
                                                    <div className="kt-form__actions">
                                                        <div className="row">
                                                            <div className="col-lg-9 ml-lg-auto">
                                                                <button id="btnSave" type="button" className="btn btn-brand d-inline-block"  onClick={addTemplateHandler}>Save</button>
                                                                <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailTemplate;