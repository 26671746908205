import { useEffect, useState } from "react";
import * as helper from '../global/helper';
import { postCompany } from "../api/teeTimeAPI";

const AddGSTCompanyModal = (props) => {

    const [companyDetails,                 setCompanyDetails]              = useState({});


    useEffect(() => {
        $('#modalCompany').modal({
            backdrop: 'static',
        });
        $('#modalCompany').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal();
        });
      
    }, []);

    useEffect(() => {
        if (props.action === 'e') {
           setCompanyDetails(props.companyDetails) 
        }
    }, [props])

    const updateCompanyDetails = (field, value) => { 
        setCompanyDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const closeDrawer = () => {

    }

    const AddCompanyHandler = async () => {

        const btn = $('#btnSaveCompany');        
        var form = $('#add-company-form');

        form.validate({
            rules: {
                txtCompanyName: {
                    required: true
                },
                txtCompanyGstNo: {
                    required: true,
                    minlength: 15,
                    maxlength: 15,
                }
            },
            messages: {
                txtCompanyName: {
                    required: "Please enter company name"
                },
                txtCompanyGstNo: {
                    required: "Please enter gst number"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);  

        try {
            await postCompany(companyDetails);
            if (props.action === 'e') {
                props.setRefresh()
            }
            props.gstNumber(companyDetails)
            helper.StopProcessing(btn);
            $('#modalCompany').modal('hide');
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const selectCompanyHandler = (company) => {
        props.gstNumber({...company, GstNumber: company.GSTNumber})
        $('#modalCompany').modal('hide');
    }

    const removeHandler = async () => {
        props.gstNumber(null)
        $('#modalCompany').modal('hide');
    }

    return (
        <>
            <div id="modalCompany" className="modal fade modal-responsive" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-lg" role="document">
                    <form className="kt-form" id="add-company-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add GST Number</h5>
                                <button 
                                    type="button" 
                                    className="close" 
                                    data-dismiss="modal" 
                                    aria-label="Close"
                                    onClick={closeDrawer}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div id="ModalCompanyHolder" className="kt-form kt-form--label-right" role="form">
                                    <div className="row d-block">
                                        <div className="col-xs-12">
                                            <h5 id="lblCompanyAction" className="text-center">New Company</h5>
                                            <br />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Company Name <span className="red">*</span></label>
                                        <div className="col-xl-6 col-lg-8">
                                            <input 
                                                id="txtCompanyName" 
                                                name="txtCompanyName" type="text" className="form-control" 
                                                value={companyDetails.CompanyName || ''}
                                                onChange={(event) => updateCompanyDetails('CompanyName', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">GST Number <span className="red">*</span></label>
                                        <div className="col-xl-6 col-lg-8">
                                            <input 
                                                id="txtCompanyGstNo" 
                                                name="txtCompanyGstNo" 
                                                type="text" className="form-control" 
                                                value={companyDetails.GstNumber || ''}
                                                onChange={(event) => updateCompanyDetails('GstNumber', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Address</label>
                                        <div className="col-xl-6 col-lg-8">
                                            <textarea 
                                                id="txtCompanyAddress" 
                                                value={companyDetails.Address || ''}
                                                onChange={(event) => updateCompanyDetails('Address', event.target.value)}
                                                name="txtCompanyAddress" className="form-control" rows="3">
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                {(props.companies && props.companies.length > 0) && (
                                    <div id="pnlCompany">
                                        <div className="row d-block">
                                            <div className="col-xs-12">
                                                <h5 className="text-center">Select Company</h5>
                                                <br />
                                            </div>
                                        </div>
                                        <table id="tblCompany" className="table table-bordered table-hover clickable">
                                            <thead className="light-grey-background">
                                                <tr>
                                                    <th>Company</th>
                                                    <th>GST No</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(props.companies.map((c, i) => (
                                                    <tr key={i} onClick={() => selectCompanyHandler(c)}>
                                                        <td>{c.CompanyName}</td>
                                                        <td>{c.GSTNumber}</td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary margin-0 margin-r5" data-dismiss="modal" onClick={closeDrawer}>Close</button>
                                <button type="button" className="btn btn-danger margin-0 margin-r5" data-dismiss="modal" onClick={removeHandler}>Remove</button>
                                <button type="button" id="btnSaveCompany" name="btnSaveCompany" className="btn btn-brand margin-0" onClick={AddCompanyHandler}>Add</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            
        </>
    )
}

export default AddGSTCompanyModal