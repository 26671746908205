import pdfLogo from '../../assets/images/ic_pdf.png';
import docLogo from '../../assets/images/ic_doc.png';
import excelLogo from '../../assets/images/ic_excel.png';
import imageLogo from '../../assets/images/ic_image.png';
import { downloadFile } from '../api/fileAPI';

export function ShowMsg(form, type, msg) {
    const alert = $(
      '<div class="alert alert-' +
        type +
        ' alert-dismissible" role="alert"><div class="alert-text">' +
        msg +
        '</div><div class="alert-close" ><i class="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i></div></div>'
    );
  
    form.find('.alert').remove();
    alert.prependTo(form);
    alert.find('span').html(msg);
  }
  
  export const RemoveMsg = (form) => form.find('.alert').remove();
  
  export function StartProcessing(btn) {
    btn
      .addClass(
        `kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light ${btn[0].id == 'kt_login_signin_google' && ' kt-spinner--primary' }`
      )
      .attr('disabled', true);
  }
  
  export function StopProcessing(btn) {
    btn
      .removeClass(
        `kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light ${btn[0].id == 'kt_login_signin_google' && ' kt-spinner--primary' }`
      )
      .attr('disabled', false);
  }

export function SanitizeUrl (input) {
  return input.replace(":", "").replace("&", "-and-").replace(/ /g, "-").replace("/", "").replace(",", "").toLowerCase().replace(/<br\/>/g, "_").replace(/<br>/g, "_").replace("'", "").replace(/"/g, "").toLowerCase();
}

  export const getDeviceDetails = async(deviceData) => {

    if(deviceData && (deviceData.isMobile || deviceData.isTablet)){
      return `${deviceData.os} ${deviceData.osVersion}`
    }else{
      return`${deviceData.osName} ${deviceData.osVersion}-${deviceData.browserName}`
    }
  
  }

  export const getFileExt = (fileName) => {
    const mtype     = fileName.split('.');
    const ext       = mtype[mtype.length - 1];
    return ext.toLowerCase();
  }

  export const downloadFileHandler = async (fileType, fileName, displayName) => {

    try {
      const file = await downloadFile(fileType, fileName);
      const type = getMimeType(fileName);
      const blob = new Blob([new Uint8Array(file.Body.data)], { type });
      blob.name = displayName;
      return blob;      
    } catch (err) {
      swal.close();
      swal.fire({
          icon                : 'error',
          titleText           : 'Error!',
          text                : err.message,
          buttonsStyling      : false,
          confirmButtonClass  : 'btn btn-brand',
      });
    }
  }

  export const getMimeType = (fileName) => {
    const ext       = getFileExt(fileName);
    let mimeType    = 'Unknown';
    switch (ext) {
          case 'pdf':
              mimeType = 'application/pdf'
              break
          case 'jpg':
              mimeType = 'image/jpg'
              break
          case 'jpeg':
              mimeType = 'image/jpeg'
              break
          case 'png':
              mimeType = 'image/png'
              break
          case 'xlsx':
              mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              break
          case 'sheet':
              mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              break
          case 'xls':
              mimeType = 'application/vnd.ms-excel'
              break
          case 'ms-excel':
              mimeType = 'application/vnd.ms-excel'
              break
          case 'csv':
              mimeType = 'text/csv'
              break
          case 'doc':
              mimeType = 'application/msword'
              break
          case 'docx':
              mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              break
          default:
            throw new Error('File not supported');
    }
    return mimeType;
  }

  export const downloadAndOpenFileHandler = async (fileType, fileName, displayName) => {
    try {
      swal.fire({
        titleText           : 'Loading',
        text                : 'Please Wait...',
        showConfirmButton   : false,
        onOpen: () => {
            swal.showLoading();
        }
      });
      const file =  await downloadFileHandler(fileType, fileName, displayName);
      if (file) {
        await swal.close();
        setTimeout(() => {
            openFileHandler(file);
        }, 50);
      } 
    } catch (err) {
      swal.fire({
        icon                : 'error',
        titleText           : 'Error!',
        text                : err.message,
        buttonsStyling      : false,
        confirmButtonClass  : 'btn btn-brand',
      });
    }
  }
  
  export const openFileHandler = (f) => {

    const url = URL.createObjectURL(f);
    const type = getFileType(f.name);

    if (type === 'excel') {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        link.setAttribute('href', url);
        link.setAttribute('download', f.name);
        link.style.visibility = 'hidden';
        link.click();
      }   
    }else{
      window.open(url);
    }
    
}

  export const getFileLogo = (fileName) => {
    const type = getFileType(fileName);
    let logo = imageLogo;
    switch (type) {
      case 'excel':
          logo = excelLogo
          break
      case 'pdf':
          logo = pdfLogo
          break
      case 'word':
          logo = docLogo
          break
      default:
          logo = imageLogo
  }
    return logo;
  }
  
  export const getFileType = (fileName) => {
    const ext       = getFileExt(fileName);
    let type        = 'Unknown';
    switch (ext) {
          case 'sheet':
              type = 'excel'
              break
          case 'ms-excel':
              type = 'excel'
              break
          case 'xlsx':
              type = 'excel'
              break
          case 'xls':
              type = 'excel'
              break
          case 'csv':
              type = 'excel'
              break
          case 'jpg':
              type = 'image'
              break
          case 'jpeg':
              type = 'image'
              break
          case 'png':
              type = 'image'
              break
          case 'doc':
              type = 'word'
              break
          case 'docx':
              type = 'word'
              break
          case 'pdf':
              type = 'pdf'
              break
          case 'mp4':
              type = 'video'
              break
          case 'mov':
              type = 'video'
              break
          default:
              type = ext
    }
    return type;
  }

  export function setStickyHeader() { 
    const container = $('.scrolTable')[0];
    const header = $('.kt-portlet__head')[0];
    const filter = $('.stickyFilter')[0];
  
    const currentScroll = $(container).scrollTop();
    if (currentScroll <= 0) {
      $(header).removeClass('scroll-up');
      $(filter).removeClass('scroll-up');
      $(header).removeClass('scroll-down');
      $(filter).removeClass('scroll-down');
      return;
    }
  
    if (currentScroll > lastScroll + 10 && !$(header).hasClass('scroll-down')) {
      // down
      $(header).removeClass('scroll-up');
      $(header).addClass('scroll-down');
      $(filter).removeClass('scroll-up');
      $(filter).addClass('scroll-down');
    } else if (currentScroll < lastScroll && $(header).hasClass('scroll-down')) {
      // up
      $(header).addClass('scroll-up');
      $(header).removeClass('scroll-down');
      $(filter).addClass('scroll-up');
      $(filter).removeClass('scroll-down');
    }
    lastScroll = currentScroll;
    SetScrollHeight();
  }

  
  export const camelCase = (data) =>
    !data || data === ''
      ? ''
      : data
          .split(' ')
          .map((d) => capitalizeCamel(d))
          .join(' ');

  export const capitalizeCamel = (name) =>
    !name || name === ''
      ? ''
      : `${name[0].toUpperCase()}${name.slice(1).toLowerCase()}`;
