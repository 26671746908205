import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getAdminMenuRole, getAdminUser, postAdminRole } from "../api/adminAPI";

const AdminRole = (props) => {

    const [userList,            setUserList]            = useState([]);
    const [selectedUser,        setSelectedUser]        = useState(null);
    const [isInit,              setIsInit]              = useState(true);
    const [menuList,            setMenuList]            = useState([]);
    const [subMenuList,         setSubMenuList]         = useState([]);
    const [adminRole,           setAdminRole]           = useState([]);
    const [rightSideDetails,    setRightSideDetails]    = useState(false);

    useEffect(() => {
        props.title("Admin Role", "admin-role")
        fetchAdminUser();
    }, []);

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();
        }
    }, [userList]);

    useEffect(() => {  
        if (rightSideDetails) {
            Config.Core.OpenDrawer();
        }else{
            setSelectedUser(null);
            setAdminRole([]);
            setMenuList([]);
            setSubMenuList([]);
        }
    }, [rightSideDetails]);

    useEffect(() => {
        if (selectedUser) {
            fetchAdminMenuRole(selectedUser.ID);
        }
    }, [selectedUser])

    const fetchAdminUser = async() => {
        try {
            const users = await getAdminUser();
            isInit ? setIsInit(false) : $('#tblAdminRole').KTDatatable().destroy(); 
            setUserList(users);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const accessHandler = async (isChecked, menuID, userID) => {
        try {
            await postAdminRole(isChecked, menuID, userID)
            swal.fire({
                toast             : true,
                icon              : 'success',
                titleText         : `${isChecked ? 'Menu Assigned' : 'Menu Removed'} `,
                position          : 'bottom-end',
                showConfirmButton : false,
                timer             : 1500,
                animation         : false,
                customClass       : {
                    popup: 'margin-20',
                },
            });
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        } 
    }

    const fetchAdminMenuRole = async(ID) => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();

                }
            });
            const menu = await getAdminMenuRole(ID);
            setAdminRole(menu.adminRole)
            setMenuList(menu.menuList)
            setSubMenuList(menu.subMenuList)
            swal.close()
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fillDataTable = () => {
        const dataTable = $('#tblAdminRole').KTDatatable({
            data: {
                saveState: false,
                source: userList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Name',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                    ${` <div class="kt-badge kt-badge--xl kt-badge--info" > ${row.UserName.substring(0, 1)}</div >`}
                                    </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.UserName}  </span> <span class="kt-user-card-v2__desc text-clip"> ${row.Email} </span>
                                    </div>
                                </div>`
                    }
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        return row.Active == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                    },
                    width: 50,
                },
            ]
        });
        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);          
            if (dataTable.dataSet[index]) {
                setSelectedUser(dataTable.dataSet[index]);
                setRightSideDetails(true);           
            }                
        });
    }

    const closeDrawer = () => {
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <div className="row align-items-center margin-l10">
                                <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <input type="text" className="form-control clientSearch" placeholder="Search User..." id="generalSearch" />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div id="tblAdminRole" className="kt-datatable table-striped clickable"></div>
                        {rightSideDetails && (
                            <RightSideDetails title="Admin Role" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body">
                                        <div className="accordion accordion-toggle-arrow" id="accordionExample4">
                                            {(menuList && menuList.length > 0) && (
                                                menuList.map((m, i) => (
                                                    <div className="card" key={i}>
                                                        <div className="card-header" id="menuHeading">
                                                            <div className="card-title collapsed" data-toggle="collapse" data-target={`#${helper.SanitizeUrl(m.Name)}`} aria-expanded="false" aria-controls={helper.SanitizeUrl(m.Name)}>
                                                                <span dangerouslySetInnerHTML={{__html: m.Icon}}/>
                                                                <span className="margin-l10">{m.Name}</span>
                                                            </div>
                                                        </div>

                                                        <div id={helper.SanitizeUrl(m.Name)} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample4" style={{}}>
                                                            <div className="card-body">
                                                                <div className="kt-widget4">
                                                                    {(subMenuList && subMenuList.length > 0) && subMenuList.filter(f => f.ParentMenuID == m.MenuID).map((s, i) => (
                                                                        <div className="kt-widget4__item padding-tb5" key={i}>
                                                                            <div className="kt-widget4__info">
                                                                                <span className="kt-widget4__username">{s.Name}</span>
                                                                            </div>
                                                                            <span className="kt-switch kt-switch--icon">
                                                                                <label className="margin-b0">
                                                                                    <input 
                                                                                        className="chkActive"
                                                                                        onChange={(e) => accessHandler(e.target.checked, s.MenuID, selectedUser.ID)}
                                                                                        defaultChecked={adminRole && adminRole.find(a => a.MenuID === s.MenuID ? 'checked' : '')}
                                                                                        name="chkActive"
                                                                                        id="chkActive" 
                                                                                        type="checkbox"/>
                                                                                    <span></span>
                                                                                </label>
                                                                            </span>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>
                        )}
                    </div>
                </div>
            </div>
        </>
    )

}

export default AdminRole;