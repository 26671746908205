import React, { useEffect, useState } from 'react'
import RightSideDetails from "../global/rightSideDetails";
import TextInput from '../base/TextInput';
import { getMatMasterAll, getMatTemplateByMasterID, getMatTimeMasterAll, postMatMaster, postMatTemplate, postTimeMatMaster } from '../api/matAPI';
import * as helper from '../global/helper';
import swal from 'sweetalert2';

const MatTemplate = (props) => {

    const [isInit,                      setIsInit]                      = useState(true);
    const [rightSideDetails,            setRightSideDetails]            = useState(false);
    const [allTimeMaster,               setAllTimeMaster]               = useState([]);
    const [slot,                        setSlot]                        = useState({});
    const [allMatTemplate,              setAllMatTemplate]              = useState([]);
    const [selectedMatTemplate,         setSelectedMatTemplate]         = useState(null);
    const [isEdit,                      setIsEdit]                      = useState(false);
    const [isMasterStarted,             setIsMasterStarted]             = useState(false);

    useEffect(() => {
        props.title("Template", "mat-template")       
        fetchMatTimeMaster();
    }, []);

    useEffect(() => {       
        if (rightSideDetails) {
            Config.Core.OpenDrawer();
            fetchMatTemplateByMasterID(selectedMatTemplate.ID)

            //Sorting Function -->
            AllowSortable();

            $('#txtStartTime').timepicker({ minuteStep: 30, defaultTime: '05:00 AM'});
            $('#txtStartTime').on('change', ({target}) => updateSlotDetails("From", target.value) );
            $('#txtEndTime').timepicker({ minuteStep: 30, defaultTime: '05:00 AM'});
            $('#txtEndTime').on('change', ({target}) => updateSlotDetails("To", target.value) );
        }else{
            setSlot({Active: 'Y'})
        }
    }, [rightSideDetails]);

    const currentDate = moment().format('YYYY-MM-DD')

    useEffect(() => {
        if (selectedMatTemplate) {
            const startDate = moment(selectedMatTemplate.StartDate).format('YYYY-MM-DD')
            setIsMasterStarted(moment(startDate).isSameOrBefore(currentDate) ? true : false)
            setSlot({MatTimeMasterID: selectedMatTemplate.ID})
        }
    }, [selectedMatTemplate])

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();
        }
    }, [allTimeMaster]);

    const updateSlotDetails = (field, value) => {
        setSlot(m => ({
            ...m,
            [field]: value
        }))
    }
    
    //Sorting -->
    const AllowSortable =() =>{
        const config = (event, ui) => {
            const ids = [];
            $('#table-block > tbody > .dragableRow').each((i,e) => ids.push( $(e).attr('data-id')));
            sortAndSave(ids);   
        }

        window.sortInit('#table-block tbody', config);    
    }

    const sortAndSave = async (param) => {
        if(param.length <= 0) return;     
        
        try {
            swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
                        
            let templates = [];        

            setAllMatTemplate( d => {
                const sorted = [];
                if(d.length > 0){                  
                    for (const [i,id] of Object.entries(param)) {                       
                        let f = d.find( a => a.ID == id);
                        if(!!f){
                            f.Index = parseInt(i)+1;                    
                            sorted.push(f);
                        }
                    }
                
                    templates = sorted;
                    return d;
                }
            });   
            if(templates.length > 0){                 
                const res = await postMatTemplate(templates);
                fetchMatTemplateByMasterID(selectedMatTemplate.ID)
            }                   
            swal.close()
        } catch (err) {              
            swal.close()
            swal.fire({icon: 'error',titleText: 'Error!',text: err.message,buttonsStyling: false, confirmButtonClass: 'btn btn-brand'});
            return;  
        }
    }

    const fetchMatTemplateByMasterID = async (id) => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();

                }
            });
            const response = await getMatTemplateByMasterID(id)
            setAllMatTemplate(response);    
            swal.close()
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }
        
    const fetchMatTimeMaster = async () => {
        try {
            const calender = await getMatTimeMasterAll()   
            isInit ? setIsInit(false) : $('#tblMaster').KTDatatable().destroy(); 
            setAllTimeMaster(calender); 
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    }

    const fillDataTable = () => {
        const dataTable = $('#tblMaster').KTDatatable({
            data: {
                saveState: false,
                source   : allTimeMaster,
                pageSize : 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable  : true,
            pagination: true,
            search    : { input: $('#generalSearch')},
            columns: [               
                {  field: 'Name',  title: 'Name', width:250 },
                {
                    field: 'Type',
                    title: 'Type',
                    width: 120,
                },
                {
                    field: 'StartDate',
                    title: 'Start Date',
                    width: 120,
                    template: (row) => moment(row.StartDate).format('DD-MMM-YYYY'),  
                },
                {
                    field: 'EndDate',
                    title: 'End Date', width:120,
                    template: (row) => moment(row.EndDate).format('DD-MMM-YYYY'),                                    
                },
            ]
        });
    
        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setSelectedMatTemplate(dataTable.dataSet[index]);  
                setRightSideDetails(true);
            } 
        });    
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    const postTemplate = async () => {
        const btn = $('#btnAdd')
        const form = $('#addTemplateForm');

        form.validate({
            rules   : { txtEndTime: { required: true }, txtStartTime: { required:true }},
            messages: { txtEndTime : { required: "Please select end time" }, txtStartTime:{ required: "Please select start time" }}
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            await postMatTemplate([{...slot, Index: slot.ID ? slot.Index : allMatTemplate.length + 1, MatTimeMasterID: selectedMatTemplate.ID}])

            helper.StopProcessing(btn);
            fetchMatTemplateByMasterID(selectedMatTemplate.ID)
            setSlot({Active: 'Y'})
            if (slot.ID) {    
                setIsEdit(false)        
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Template updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,                                       
                });
            }else{
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Template added successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }

    }

    const editHandler = (value) => {
        setIsEdit(true);
        setSlot({...value, From: value.SlotFrom, To: value.SlotTo});
    }

    return (
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Master..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblMaster" className="kt-datatable table-striped clickable">
                                {rightSideDetails && (
                                    <RightSideDetails title="Time Slots" onCloseDrawer={() => closeDrawer()}>
                                        <div className="kt-portlet">
                                            <div className="kt-portlet__body padding-t30 padding-b0">
                                                {!isMasterStarted && (<form className="kt-form" id="addTemplateForm">
                                                    <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">                                       
                                                        <div className='row'>
                                                            <label className="col-form-label col-sm-2 text-right">From</label>
                                                            <div className="form-group col-xl-2 col-sm-3">
                                                                <div className="input-group timepicker width-200">
                                                                    <input id='txtStartTime' name="txtStartTime" type="text" className="form-control" 
                                                                        readOnly={true} placeholder="Select time" 
                                                                        value={slot.From || ''}                                               
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text"> <i className="la la-clock-o"></i> </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <label className="col-form-label col-sm-1 text-right">To</label>
                                                            <div className="form-group col-xl-2 col-sm-3">
                                                                <div className="input-group timepicker width-200">
                                                                    <input id='txtEndTime' name="txtEndTime" type="text" className="form-control" 
                                                                        readOnly={true} placeholder="Select time" 
                                                                        value={slot.To || ''}                                               
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text"> <i className="la la-clock-o"></i> </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                {isEdit ? (
                                                                    <div className="btn-group btn-block" role="group">
                                                                        <button
                                                                            id="btnAdd"
                                                                            className="btn btn-success btn-block margin-0"
                                                                            style={{ maxWidth: '70%' }}
                                                                            onClick={postTemplate}
                                                                            type="button"
                                                                        >
                                                                            Update
                                                                        </button>
                                                                        <button
                                                                            style={{ maxWidth: '30%' }}
                                                                            className="btn btn-secondary btn-block margin-0 padding-0"
                                                                            onClick={() => {
                                                                                setSlot({Active: "Y"});
                                                                                setIsEdit(false)
                                                                            }}
                                                                        >
                                                                            <i
                                                                                className="la la-times"
                                                                                style={{ fontSize: '1rem', marginLeft: '3px' }}
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <button id="btnAdd" type="button" onClick={postTemplate} className="btn btn-label-brand d-inline-block"><i className="la la-plus"></i>Add</button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>)}
                                                <table id="table-block" className="table table-bordered table-hover margin-b30">
                                                    <thead className="light-grey-background">
                                                        <tr>
                                                            <th>Sr. No</th>
                                                            <th>Slot</th>
                                                            <th>Active</th>
                                                            <th>  </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {allMatTemplate.length > 0 ? (
                                                            allMatTemplate.map((t) => (
                                                                <tr className="dragableRow" key={t.ID} data-id={t.ID}>
                                                                    <td>{t.Index}</td>
                                                                    <td>{t.SlotFrom} To {t.SlotTo}</td>
                                                                    <td>          
                                                                        <span className="kt-switch kt-switch--sm kt-switch--icon">                  
                                                                            <label className="margin-b0">                      
                                                                                <input 
                                                                                    checked = { t.Active === "Y" ? "checked" : ""}
                                                                                    onChange={() => {
                                                                                        t.Active === "Y"
                                                                                        ? updateSlotDetails("Active", "N")
                                                                                        : updateSlotDetails("Active", "Y")
                                                                                    }}
                                                                                    type="checkbox"/>                      
                                                                                <span>{}</span>                  
                                                                            </label>              
                                                                        </span>    
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {!isMasterStarted && (
                                                                            <button type="button" 
                                                                                // disabled={isUpdate} 
                                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-block-edit mr-2"
                                                                                onClick={() => editHandler(t)}
                                                                                >
                                                                                <i className="flaticon2-edit"></i>
                                                                            </button>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan={4}> 
                                                                    <div className="padding-10"> 
                                                                        <h5 className="text-center text-muted"> No Slots Available..! </h5> 
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </RightSideDetails>
                                )}                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                            
        </>
    )
}

export default MatTemplate;