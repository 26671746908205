import { useState } from "react";
import { useEffect } from "react";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getAppUpdateByType, postAppUpdate } from "../api/golfClubAPI";


const AdminUpdate = (props) => {

    const [admin,                  setAdmin]                  = useState({});

    useEffect(() => {
        props.title("Admin Update", "admin-update")
        fetchAppUpdate();
    }, []);
    
    const fetchAppUpdate = async() =>{
        try {
            const app = await getAppUpdateByType('Admin')  
            setAdmin(app);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }
        
    const updateAdminDetails = (field, value) => {   
        setAdmin((d) => {
            return { ...d, [field]: value };
        });
    };

    const updateAdminHandler = async() => {

        const btn = $('#btnUpdate');
        var form = $('#add-admin-form');
        
        form.validate({
            rules: {
                txtCurrentVersion: {
                    required: true,
                    number: true
                }       
            },
            messages: {
                txtCurrentVersion: {
                    required: "Please enter Version"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            await postAppUpdate(admin);
            helper.StopProcessing(btn);
            fetchAppUpdate();
            swal.fire({
                icon: 'success',
                titleText: 'Admin Version updated!',
                showConfirmButton: false,
                timer: 1500,
            });

        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonclassName    : 'btn btn-brand',
            });
            return;
        }
    }

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <form className="kt-form" id="add-admin-form">
                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Current Version <span className="red">*</span></label>
                                        <div className="col-xl-3 col-lg-4">
                                            <input 
                                                id="txtCurrentVersion" 
                                                name="txtCurrentVersion" 
                                                type="number" 
                                                className="form-control"
                                                value={admin.CurrentVersion || ''}
                                                onChange={(event) => updateAdminDetails('CurrentVersion', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Mandatory</label>
                                        <div className="col-xl-6 col-lg-8">
                                            <span className="kt-switch kt-switch--icon">
                                                <label>
                                                    <input 
                                                        id="chkIsMandatory" 
                                                        name="chkIsMandatory" 
                                                        type="checkbox" 
                                                        checked={admin.IsMandatory == 'Y' ? 'checked' : ''}
                                                        onChange={(event) => updateAdminDetails('IsMandatory', event.target.checked ? 'Y' : 'N')} 
                                                    />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="kt-portlet__foot">
                            <div className="kt-form__actions">
                                <div className="row">
                                    <div className="col-lg-9 ml-lg-auto">
                                        <button id="btnUpdate" type="button" className="btn btn-brand d-inline-block" onClick={updateAdminHandler}>Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminUpdate;