import { useState } from "react";
import { useEffect } from "react";
import swal from 'sweetalert2';
import Select from 'react-select';
import RightSideDetails from "../global/rightSideDetails";
import { deleteBooking, getBookingDetails, getCompany, getShotgunBookSheet, getTeeTimeBookSheet, postTeeTimeBook, verifyDate } from "../api/teeTimeAPI";
import ic_check_on from '../../assets/images/ic_check_on.png'
import golf_cart from '../../assets/images/golf_cart.png'
import golf_caddy from '../../assets/images/golf_caddy.png'
import * as helper from '../global/helper';
import AddUser from "./addUser";
import AddGuest from "./addGuest";
import AddGSTCompanyModal from "./AddGSTCompanyModal";
import { getGreenFee } from "../api/matAPI";
// import AddPayment from "./addPayment";

const BookingSheet = (props) => {

    const [selectedDate,                    setSelectedDate]                    = useState(moment().toDate());
    const [teeTimeBookSheet,                setTeeTimeBookSheet]                = useState([]);
    const [shotgunBookSheet,                setShotgunBookSheet]                = useState([]);
    const [isInit,                          setIsInit]                          = useState(true);
    const [rightSideDetails,                setRightSideDetails]                = useState(false);
    const [bookSheet,                       setBookSheet]                       = useState({});
    const [showAddUser,                     setShowAddUser]                     = useState(false);
    const [showAddGuest,                    setShowAddGuest]                    = useState(false);
    const [showGSTModal,                    setShowGSTModal]                    = useState(false);
    const [usersList,                       setUsersList]                       = useState([]);
    const [guestList,                       setGuestList]                       = useState([]);
    const [addedUserId,                     setAddedUserId]                     = useState(null);
    const [addedGuestId,                    setAddedGuestId]                    = useState(null);
    const [bookingUserList,                 setBookingUserList]                 = useState([]);
    const [companies,                       setCompanies]                       = useState([]);
    const [gstNumber,                       setGstNumber]                       = useState(null)     
    const [refresh,                         setRefresh]                         = useState(false)     
    const [editCompany,                     setEditCompany]                     = useState(null) 
    const [bookingTable,                    setBookingTable]                    = useState(null);    
    const [greenFee,                        setGreenFee]                        = useState({});
    const [amount,                          setAmount]                          = useState(null);
    const [paymentMode,                     setPaymentMode]                     = useState([{label: 'Cash', value: 'Cash'},
    {label: 'Cheque', value: 'Cheque'},{label: 'Credit Card', value: 'Credit Card'},{label: 'Debit Card', value: 'Debit Card'},{label: 'NetBanking', value: 'NetBanking'},
    {label: 'UPI', value: 'UPI'},{label: 'Company', value: 'Company'},{label: 'Golflan', value: 'Golflan'},{label: 'Apexylnx', value: 'Apexylnx'},{label: 'Vidsur Golf', value: 'Vidsur Golf'}, {label: 'Golftripz', value: 'Golftripz'}
])
    useEffect(() => {
        props.title("Booking Sheet", "tee-sheet")    
        $('#txtDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtDate').on('change', function (event) {
            setSelectedDate(event.target.value)
        }); 
        fetchTeeTimeBookSheetData(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'))
    }, []);

    useEffect(() => {
        if (selectedDate)
            fetchTeeTimeBookSheetData(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'))
    }, [selectedDate])

    useEffect(() => {
        if (bookSheet) {
            fetchBookingDetails(bookSheet.ID || 0)
        }
        if (bookSheet.CompanyID) {
            const company = companies.find(c => c.ID == bookSheet.CompanyID);
            setGstNumber({...company, GstNumber: company.GSTNumber})
        }
        if (bookSheet.Hole) {
            fetchGreenFee()
        }
    }, [bookSheet])

    useEffect(() => {  
        if(rightSideDetails){
            Config.Core.OpenDrawer()      
            if(bookSheet && bookSheet.Hole == '9'){
                $('.radio9').attr('checked', true)
            }else {
                $('.radio18').attr('checked', true)
            }
            fetchCompany();
            // if (bookSheet.ID) { 

                setBookSheet(b => ({
                    ...b,
                    Hole : '18',
                    Discount: b.Discount === 0 ? '' : b.Discount, 
                    PaymentMode: b.PaymentMode ? b.PaymentMode : paymentMode[0].value
                }))
            // }
        }else{
            setBookSheet({})
            setBookingUserList([])
        }
    }, [rightSideDetails]);

    useEffect(() => {
        fetchCompany();
        setRefresh(false)
    }, [refresh])

    useEffect(() => {
        if (addedUserId)
            fetchBookingDetails(0)
    }, [addedUserId]);

    useEffect(() => {
        if (addedGuestId)
            fetchBookingDetails(0)
    }, [addedGuestId]);

    useEffect(() => {
        if (bookingUserList.length === 0) {
            setGstNumber(null)
        }
        if (bookingUserList && bookingUserList.length > 0) {
            const caddyLength = bookingUserList.filter(b => b.IsCaddyRequired === 'Y')
            const GreenFee = greenFee.GreenFee * bookingUserList.length;
            const Caddy = greenFee.Caddy * caddyLength.length
            const TotalFee = GreenFee + Caddy
            const DiscountedRate = bookSheet.Discount >=  100 ? TotalFee : bookSheet.Discount <= 0 ? 0 : Math.ceil((TotalFee * (bookSheet.Discount ? bookSheet.Discount : 0)) / 100)
            const SGST = Math.ceil(((TotalFee - DiscountedRate) * 9) / 100);
            const CGST = SGST;
            const Total = (TotalFee - DiscountedRate) + SGST + CGST;
            setAmount({
                GreenFee,
                Caddy,
                DiscountedRate,
                SGST,
                CGST,
                Total
            })
        } else {
            setAmount({})
        }
    }, [bookingUserList, greenFee])

    useEffect(() => {
        if (usersList.length > 0 && addedUserId) {
            updateBookingDetails('UserID', addedUserId) 
        }
        if (usersList.length > 0 && addedGuestId) {
            updateBookingDetails('GuestID', addedGuestId) 
        }
    }, [usersList])

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();
        }
    }, [teeTimeBookSheet, shotgunBookSheet]);

    const fetchTeeTimeBookSheetData = async(date) => {
        try {
            const response = await getTeeTimeBookSheet(date);
            isInit ? setIsInit(false) : $('#tblBookSheet').KTDatatable().destroy(); 
            setTeeTimeBookSheet(response)
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    const fetchBookingDetails = async(id) => {
        try {
            const booking = await getBookingDetails(id)
            setUsersList(booking.Users)
            setGuestList(booking.Guests)
            if(booking.BookingUsers && bookingUserList.length == 0) setBookingUserList(booking.BookingUsers)
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    function fillDataTable() {
        var check = '<img src="' + ic_check_on + '" style="height: 20px; width: 20px; float:right;"/>';
        var cart = '<img src="' + golf_cart + '" style="height: 22px; width: 22px; float:right; margin-left:5px"/>';
        var caddy = '<img src="' + golf_caddy + '" style="height: 20px; width: 20px; float:right; margin-left:5px"/>';

        // const bookingList = teeTimeBookSheet.BookSheet.filter(i => i.TeeBox == teeBox);

        const bookingList = teeTimeBookSheet.BookSheet
        if (teeTimeBookSheet.holiday != null) {
            $('#lblHoliday').text("Holiday : " + teeTimeBookSheet.holiday.Name);
        } else {
            $('#lblHoliday').text("");
        }

        $.each(bookingList, function (index, element) {

            if (element.Player1Caddy == 'Y') {
                element["Player1"] += caddy;
            }
            if (element.Player2Caddy == 'Y') {
                element["Player2"] += caddy;
            }
            if (element.Player3Caddy == 'Y') {
                element["Player3"] += caddy;
            }
            if (element.Player4Caddy == 'Y') {
                element["Player4"] += caddy;
            }

            if (element.Player1Cart == 'Y') {
                element["Player1"] += cart;
            }
            if (element.Player2Cart == 'Y') {
                element["Player2"] += cart;
            }
            if (element.Player3Cart == 'Y') {
                element["Player3"] += cart;
            }
            if (element.Player4Cart == 'Y') {
                element["Player4"] += cart;
            }

            if (element.Player1IsPresent == 'Y') {
                element["Player1"] += check;
            }
            if (element.Player2IsPresent == 'Y') {
                element["Player2"] += check;
            }
            if (element.Player3IsPresent == 'Y') {
                element["Player3"] += check;
            }
            if (element.Player4IsPresent == 'Y') {
                element["Player4"] += check;
            }
            
        });

        const dataTable = $('#tblBookSheet').KTDatatable({
            data: {
                saveState: false,
                source: bookingList,
            },
            layout: {
                scroll: false,
                footer: false,
            },
            sortable: false,
            pagination: false,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Slot',
                    title: 'Slot',
                    width: 70
                },
                {
                    field: 'Status',
                    title: 'Status',
                    width: 85,
                    template: function (row) {
                        if (row.Status == null) {
                            return "<div class='badge badge-success'><span>Available</span></div>";
                        } else {
                            return '<div class="badge badge-danger"><span>' + row.Status + '</span></div>';
                        }
                    }
                },
                {
                    field: 'Player1',
                    title: 'Player 1'
                },
                {
                    field: 'Player2',
                    title: 'Player 2'
                },
                {
                    field: 'Player3',
                    title: 'Player 3'
                },
                {
                    field: 'Player4',
                    title: 'Player 4'
                },
                {
                    field: 'CreatedDate',
                    title: 'Time',
                    width: 145,
                    template: function (row) {
                        if (row.CreatedDate == null) {
                            return "";
                        } else {
                            return moment(row.CreatedDate).format('DD-MMM hh:mm:ss.SS A');
                        }
                    }
                },
                {
                    field: '',
                    title: '',
                    width: 60,
                    template: function (row) {
    
                        if (row.Status == "Booked" || row.Status == null) {
                            return showEditButton(row)
                        } else {
                            return '';
                        }
                    }
                },
    
            ],
            rows: {
                autoHide: false,
            }
        })

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblBookSheet .kt-datatable__table').addClass('overflow-x-auto');

        Config.Core.InitPopover();
        Config.Core.GrabAndScroll('.kt-datatable__table');

        $('.tableexport-caption').addClass('hidden');

        function showEditButton(row) {

            const formattedDate = moment($('#txtDate').val(), 'DD/MM/YYYY').format('DD-MMM-YYYY');

            var optString = '';

            const isEdit = moment(moment(teeTimeBookSheet.ServerDate).format('DD-MMM-YYYY')).isSameOrBefore(formattedDate);

            optString += '<center>'

            if (row.Comment) optString += `<button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs mr-1" data-toggle="kt-popover"  data-trigger="hover click" data-placement="left" data-original-title="Comments" data-content="${row.Comment}"><i class="fa fa-info"></i></button>`

            if (isEdit) optString += `<button type="button"  class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-edit"><i class="flaticon2-edit"></i></button>`

            optString += '</center>'

            return optString;
        }

        dataTable.on('click', '.btn-edit', function (ss) {
            const index = $(ss.currentTarget).parents('tr').index();
               
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                setBookSheet({...dataTable.dataSet[index]});  
                setRightSideDetails(true); 
            } 
        });
    
    }

    const resetUser = () => {
        setBookSheet(a => ({...a, UserID: ''}))
    }

    const resetGuest = () => {
        setBookSheet(a => ({...a, GuestID: ''}))
    }

    const updateBookingDetails = (field, value) => { 
        setBookSheet((d) => {
            return { ...d, [field]: value };
        });
    };

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    const cancelBookingHandler = async () => {}

    const fetchCompany = async () => {
        try {
            const response = await getCompany();
            setCompanies(response)
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const addBookingHandler = async () => {
        const btn = $('#btnSave');
        if (bookingUserList.length == 0) {
            swal.fire({
                icon                  : 'warning',
                text                  : "Please add atleast 1 player",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return;
        }
    
        helper.StartProcessing(btn);
        try {

            await postTeeTimeBook({
                ...bookSheet,
                CompanyID   : gstNumber ? gstNumber.ID : 0,
                BookDate    : moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), 
                DayName     : moment(selectedDate, 'DD-MM-YYYY').format('dddd'), 
                Type        :'TeeTime',
                bookingUserList
            })
            closeDrawer();
            fetchTeeTimeBookSheetData(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }

    }

    const addBookingUserHandler = () =>{
        if(bookSheet.UserID){
            setBookingUserList((a) => {
                const newA = [...a];
                const newO = usersList.find((f) => f.ID == bookSheet.UserID)
                newA.push({...newO,  UserID: newO.ID, UserType: "User"})
                return newA;
            })
            setBookSheet(a => ({...a, UserID: '', GuestID: ''}))
        }
    }

    const deleteUserHandler = (index, value) => {
        setBookingUserList(p => {
            const nA = p.filter((f, i) => index !== i )
            return nA
        })
    }

    const fetchGreenFee = async () => {
        try {
            const response = await getGreenFee(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedDate, 'DD-MM-YYYY').format('dddd'), 'TeeTime', bookSheet.Hole);
            setGreenFee(response)
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    }

    const editCompanyHandler = () => {
        if (!bookSheet.ID)
            setEditCompany(gstNumber)
    }

    const caddyHandler = (m) => {
        setBookingUserList(prev => {
            const index = prev.findIndex(user => user.ID === m.ID)

            if (index !== -1) {
                const updatedList = [...prev];
                updatedList[index] = {
                    ...updatedList[index],
                    IsCaddyRequired: !m.IsCaddyRequired || m.IsCaddyRequired == 'N' ? 'Y' : 'N',
                }
                return updatedList
            }

            return prev
        })
    }

    const addBookingGuestHandler = () =>{
        if(bookSheet.GuestID){
            setBookingUserList((a) => {
                const newA = [...a];
                const newO = guestList.find((f) => f.ID == bookSheet.GuestID)
                newA.push({...newO,  UserID: newO.ID, UserType: "Guest"})
                return newA
            })
            setBookSheet(a => ({...a, UserID: '', GuestID: ''}))
        }
    }

    const validateDiscount = (field, value) => {
        if (value > 100)
            value = 100;

        if (value < 0)
            value = 0

        updateBookingDetails(field, value)
        
    }

    return (
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="row">
                                    <label className="col-form-label col-sm-1 text-right">Date </label>
                                    <div className="form-group col-xl-3 col-sm-3">
                                        <div className="input-group date">
                                            <input 
                                                id="txtDate" 
                                                name="txtDate" 
                                                type="text" 
                                                className="form-control " 
                                                placeholder="Select date" 
                                                defaultValue={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-xl-3 col-sm-3">
                                        <div className="input-group">
                                            <div className="kt-input-icon kt-input-icon--left">
                                                <input 
                                                    type="text" 
                                                    className="form-control clientSearch" 
                                                    placeholder="Search Tee Time..." 
                                                    id="generalSearch" 
                                                />
                                                <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-sm-2">
                                        <div className="dropdown">
                                            <button className="btn btn-label-brand dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Export
                                            </button>
                                            <div className="dropdown-menu " aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style={{position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(0px, 38px, 0px)'}}>
                                                <button 
                                                    id="btn-export-excel" 
                                                    type="button" 
                                                    className="dropdown-item" 
                                                    // onClick={() => exportSheet('xlsx')}
                                                >Excel
                                                </button>
                                                <button 
                                                    id="btn-export-csv" 
                                                    type="button" 
                                                    className="dropdown-item" 
                                                    // onClick={ () => exportSheet('csv')}
                                                >Csv 
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h5 id="lblHoliday" className="lblHoliday"></h5>
                            <div id="tblBookSheet" className="table table-bordered table-hover custom-datatable"></div>
                            {rightSideDetails && (
                                <RightSideDetails title="Book Tee Time" onCloseDrawer={closeDrawer}>
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body padding-t30 padding-b0">
                                            <form id="ModalHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Date</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="lblDate" 
                                                            name="lblDate" 
                                                            disabled="disabled" 
                                                            type="text" 
                                                            className="form-control" 
                                                            value={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                                        />
                                                    </div>
                                                </div>
                                                <div id="pnlEdit" className="form-group row">
                                                    <label className="col-form-label col-lg-3">Slot <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <div>
                                                            <Select 
                                                                id="ddlTeeBox" 
                                                                name="ddlTeeBox"
                                                                className="multi-custom"
                                                                isDisabled={true}
                                                                value={(teeTimeBookSheet && teeTimeBookSheet.BookSheet.length > 0 && bookSheet.TemplateID) && {label: teeTimeBookSheet.BookSheet.find(c => c.TemplateID == bookSheet.TemplateID).Slot, value: bookSheet.TemplateID}}
                                                                // value={(teeTimeBookSheet && teeTimeBookSheet.BookSheet.length > 0 && bookSheet.TemplateID) && {label: teeTimeBookSheet.BookSheet.find(c => c.TemplateID == bookSheet.TemplateID).Slot, value: bookSheet.TemplateID}}
                                                                // onChange={(event) => updateBookingDetails('TemplateID', event.value)}
                                                                // options={teeTimeBookSheet.BookSheet.map(a => ({
                                                                //     label: a.Slot,
                                                                //     value: a.TemplateID,
                                                                // }))}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3"></label>
                                                    <div className="kt-radio-inline col-xl-6 col-lg-8 margin-l10">
                                                        <label id="pnlRadio18" className="kt-radio kt-radio--brand">
                                                            <input 
                                                                type="radio" 
                                                                id="radio18" 
                                                                disabled={bookSheet.ID ? true : false}
                                                                className="radio18" 
                                                                name="hole" 
                                                                value="18" 
                                                                onChange={({ target }) => updateBookingDetails('Hole', target.value)}
                                                            />
                                                                18 Holes
                                                            <span></span>
                                                        </label>
                                                        <label className="kt-radio kt-radio--brand">
                                                            <input 
                                                                type="radio" 
                                                                id="radio9" 
                                                                disabled={bookSheet.ID ? true : false}
                                                                className="radio9" 
                                                                name="hole" 
                                                                value="9"
                                                                onChange={({ target }) => updateBookingDetails('Hole', target.value)}
                                                            />
                                                                9 Holes
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div id="UserHolder">
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">User</label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <div className="input-group" >
                                                                <div style={{width: "60%"}}  >
                                                                    <Select
                                                                        value={(usersList && usersList.length > 0 && bookSheet.UserID) && {label: `${usersList.find(m => m.ID == bookSheet.UserID).Name} (${usersList.find(m => m.ID == bookSheet.UserID).Mobile})`, value: bookSheet.UserID} || ''} 
                                                                        onChange={(event) => updateBookingDetails('UserID', event.value)}
                                                                        name="ddlUser"
                                                                        placeholder="Select User"
                                                                        id="ddlUser"
                                                                        className="ddlUser"
                                                                        isDisabled={bookSheet.ID ? true : false}
                                                                        options={usersList.map(c => ({
                                                                            label: `${c.Name} (${c.Mobile})`,
                                                                            value: c.ID
                                                                        }))}
                                                                    />
                                                                </div>
                                                                <div className="input-group-append" style={{zIndex: 0}}>
                                                                    <button className="btn btn-secondary kt-select-reset" type="button" onClick={resetUser}><i className="flaticon2-delete kt-icon-sm padding-0"></i></button>
                                                                </div>
                                                                {!bookSheet.ID && (
                                                                    <>
                                                                        <button id="btnAddUser" className="btn btn-label-brand margin-l10 margin-r0" type="button"  onClick={addBookingUserHandler}>Add</button>
                                                                        <button id="btnNewUser" className="btn btn-label-brand margin-l10" type="button" data-toggle="modal" data-target="#modalUser" onClick={() => setShowAddUser(true)}>New</button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="GuestHolder" >
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Guest</label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <div className="input-group">
                                                                <div style={{width: "60%"}}  >
                                                                    <Select
                                                                        value={(guestList && guestList.length > 0 && bookSheet.GuestID) && {label: `${guestList.find(m => m.ID == bookSheet.GuestID).Name} ${guestList.find(m => m.ID == bookSheet.GuestID).Mobile && `(${guestList.find(m => m.ID == bookSheet.GuestID).Mobile})`}`, value: bookSheet.GuestID}}
                                                                        onChange={(event) => updateBookingDetails('GuestID', event.value)}
                                                                        name="ddlGuest"
                                                                        placeholder="Select Guest"
                                                                        id="ddlGuest"
                                                                        isDisabled={bookSheet.ID ? true : false}
                                                                        data-live-search="true"
                                                                        className="ddlGuest"
                                                                        options={guestList.map(c => ({
                                                                            label: `${c.Name} ${c.Mobile && `(${c.Mobile})`}`,
                                                                            value: c.ID
                                                                        }))}
                                                                    />
                                                                </div>
                                                                <div className="input-group-append"  style={{zIndex: 0}}>
                                                                    <button className="btn btn-secondary kt-select-reset" type="button" onClick={resetGuest}><i className="flaticon2-delete kt-icon-sm padding-0"></i></button>
                                                                </div>
                                                                {!bookSheet.ID && (
                                                                    <>
                                                                        <button id="btnAddGuest" className="btn btn-label-brand margin-l10 margin-r0" type="button" onClick={addBookingGuestHandler}>Add</button>
                                                                        <button id="btnNewGuest" className="btn btn-label-brand margin-l10" type="button" onClick={() => setShowAddGuest(true)} >New</button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pnlType" className="form-group row">
                                                    <label className="col-form-label col-lg-3">Payment Mode <span className="red"></span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <div>
                                                            <Select 
                                                                id="ddlPaymentMode" 
                                                                name="ddlPaymentMode"
                                                                className="multi-custom"
                                                                placeholder="Select Payment Mode"
                                                                isDisabled={bookSheet.ID ? true : false}
                                                                defaultValue={ paymentMode.find((m) => m.value[0] )  || ''}
                                                                onChange={(event) => updateBookingDetails('PaymentMode', event.value)}
                                                                options={paymentMode}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Discount %</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtDiscount" 
                                                            name="txtDiscount" 
                                                            type="number" 
                                                            disabled={bookSheet.ID ? true : false}
                                                            max='100'
                                                            min='0'
                                                            className="form-control" 
                                                            value={bookSheet.Discount || ''}
                                                            onChange={(event) => validateDiscount('Discount', event.target.value)} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Comment</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <textarea 
                                                            id="txtComment" 
                                                            name="txtComment" 
                                                            disabled={bookSheet.ID ? true : false}
                                                            className="form-control" 
                                                            rows="3"
                                                            value={bookSheet.Comment || ''}
                                                            onChange={(event) => updateBookingDetails('Comment', event.target.value)} 
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                {bookingUserList && bookingUserList.length > 0 && (
                                                    <table id="table-user" className="table table-bordered table-hover margin-b30">
                                                        <thead className="light-grey-background">
                                                            <tr>
                                                                <th>Players</th>
                                                                <th className="text-center">Caddy</th>
                                                                <th className="text-center">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {bookingUserList.map((m, i) => (
                                                                <tr key={i}>
                                                                    <td>
                                                                        {m.UserName || m.Name}
                                                                        <small className="green-fee text-fee">Green Fee: ₹{greenFee.GreenFee}</small>
                                                                        {m.IsCaddyRequired == 'Y' && (
                                                                            <small className="green-fee text-fee">Caddy: ₹{greenFee.Caddy}</small>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <center>              
                                                                            <span className="kt-switch kt-switch--sm kt-switch--icon">                  
                                                                                <label className="margin-b0">                      
                                                                                    <input className="user-caddy" name="user-caddy"
                                                                                        disabled={bookSheet.ID ? true : false}
                                                                                        checked = { m.IsCaddyRequired === "Y" ? "checked" : ""}
                                                                                        onChange={() => caddyHandler(m)}
                                                                                        type="checkbox"/>                      
                                                                                    <span></span>                  
                                                                                </label>              
                                                                            </span>          
                                                                        </center>
                                                                    </td>
                                                                    <td>
                                                                        {!bookSheet.ID && (
                                                                            <center>
                                                                                <button className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10 btn-user-delete" type="button" onClick={() => deleteUserHandler(i, m)} >                  
                                                                                    <i className="flaticon2-rubbish-bin-delete-button"></i>             
                                                                                </button>
                                                                            </center>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                        <tfoot>  
                                                            <tr className="text-center">     
                                                                <td colSpan="3">
                                                                    {!bookSheet.ID && !gstNumber && (
                                                                        <span>
                                                                            <a id="lblGstNo" style={{ fontWeight: 'lighter', color: '#3699ff' }} className="lbl text-center margin-0" onClick={() => setShowGSTModal(true)}>Add GST Number</a>
                                                                        </span>
                                                                    )}
                                                                    {gstNumber && (
                                                                        <span className="clickable text-success" onClick={() => editCompanyHandler()}>
                                                                            {gstNumber.CompanyName} : {gstNumber.GstNumber}
                                                                        </span>
                                                                    )}
                                                                </td>  
                                                            </tr>
                                                        </tfoot>
                                                                    
                                                    </table>
                                                )}
                                                {(amount && amount.GreenFee) && (
                                                    <div id="pnlPaymentBrekup">
                                                        <div id="pnlGreenFee" className="row margin-br5">
                                                            <div className="col-sm-10 col-xs-8 text-right">
                                                                <h6 className="margin-0">Green Fee:</h6>
                                                            </div>
                                                            <div className="col-sm-2 col-xs-4 text-right">
                                                                <h6 className="margin-0">₹ <span id="lblTotalGreenFee">{amount.GreenFee}</span></h6>
                                                            </div>
                                                        </div>
                                                        {(bookingUserList && bookingUserList.length > 0) && (bookingUserList.filter(b => b.IsCaddyRequired === 'Y').length > 0) && (
                                                            <div id="pnlCaddy" className="row margin-br5">
                                                                <div className="col-sm-10 col-xs-8 text-right">
                                                                    <h6 className="margin-0">{bookingUserList.filter(b => b.IsCaddyRequired === 'Y').length} Caddy:</h6>
                                                                </div>
                                                                <div className="col-sm-2 col-xs-4 text-right">
                                                                    <h6 className="margin-0">₹ <span id="lblCaddy">{amount.Caddy}</span></h6>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {bookSheet.Discount && (
                                                            <div id="pnlDiscount" className="row margin-br5">
                                                                <div className="col-sm-10 col-xs-8 text-right">
                                                                    <h6 className="margin-0">Discount:</h6>
                                                                </div>
                                                                <div className="col-sm-2 col-xs-4 text-right">
                                                                    <h6 className="margin-0">- ₹ <span id="lblDiscount">{amount.DiscountedRate}</span></h6>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="row margin-br5">
                                                            <div className="col-sm-10 col-xs-8 text-right">
                                                                <h6 className="margin-0">SGST 9%:</h6>
                                                            </div>
                                                            <div className="col-sm-2 col-xs-4 text-right">
                                                                <h6 className="margin-0">₹ <span id="lblSGST">{amount.SGST}</span></h6>
                                                            </div>
                                                        </div>
                                                        <div className="row margin-br5">
                                                            <div className="col-sm-10 col-xs-8 text-right">
                                                                <h6 className="margin-0">CGST 9%:</h6>
                                                            </div>
                                                            <div className="col-sm-2 col-xs-4 text-right">
                                                                <h6 className="margin-0">₹ <span id="lblCGST">{amount.CGST}</span></h6>
                                                            </div>
                                                        </div>
                                                        <div className="row margin-br5">
                                                            <div className="col-sm-10 col-xs-8 text-right">
                                                                <h6 className="margin-0"><strong>Total:</strong></h6>
                                                            </div>
                                                            <div className="col-sm-2 col-xs-4 text-right">
                                                                <h6 className="margin-0"><strong>₹ <span id="lblFinalAmount">{amount.Total}</span></strong></h6>
                                                            </div>
                                                        </div>
                                                        {bookSheet.ID && (
                                                            <>  
                                                                <div className="row margin-bl5">
                                                                    <div className="col-sm-12">
                                                                        <h6 className="margin-0"><strong id="lblOwner">Booked By Golf {bookSheet.OwnerType}</strong></h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row margin-l5 margin-b20">
                                                                    <div className="col-sm-12">
                                                                        <h6 className="margin-0"><strong id="lblPaymentID">{bookSheet.PaymentID}</strong></h6>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </form>
                                        </div>
                                        <div className="kt-portlet__foot">
                                            <div className="kt-form__actions">
                                                <div className="row">
                                                    <div className="col-lg-12 text-right">
                                                        {!bookSheet.ID && (
                                                            <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addBookingHandler}>{bookSheet.ID ? 'Update Booking' : 'Save Booking' }</button>
                                                        )}
                                                        {/* {bookSheet && 
                                                            bookSheet.ID && <button id="btnCancel" type="button" className="btn btn-warning d-inline-block margin-l10" onClick={cancelBookingHandler}>Cancel Booking</button>
                                                        } */}
                                                        <button id="btnCloseDrawer" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </RightSideDetails>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showAddUser && (
                <AddUser
                    show={showAddUser}
                    addUserId={setAddedUserId}
                    onDismissModal={setShowAddUser}                   
                />
            )}
            {showAddGuest && (
                <AddGuest
                    show={showAddGuest}
                    onDismissModal={setShowAddGuest}
                    addGuestId={setAddedGuestId}
                />
            )}
            {editCompany && (
                <AddGSTCompanyModal
                    action='e'
                    setRefresh={() => setRefresh(true)}
                    companyDetails={editCompany}
                    gstNumber={setGstNumber}
                    show={editCompany}
                    companies={companies}
                    onDismissModal={() => setEditCompany(null)}
                />
            )}
            {showGSTModal && (
                <AddGSTCompanyModal
                    action='a'
                    show={showAddGuest}
                    gstNumber={setGstNumber}
                    companies={companies}
                    onDismissModal={() => setShowGSTModal(false)}
                />
            )}
        </>
    )
}

export default BookingSheet;