import { useEffect, useState } from "react";
import { getClubAll, postClub } from "../api/golfClubAPI";
import swal from 'sweetalert2';
import * as helper from '../global/helper';


const Club = (props) =>{

    const [club,                  setClub]                  = useState({});

    useEffect(() => {
        props.title("Club", "club")
        fetchClub();
    }, []);

    const fetchClub = async() =>{
        try {
            const clubs = await getClubAll()   
            setClub(clubs);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }
    const updateClubDetails = (field, value) => {   
        setClub((d) => {
            return { ...d, [field]: value };
        });
    };

    const addClubHandler = async() => {

        const btn = $('#btnSave');
        var form = $('#add-Club-form');
        
        form.validate({
            rules: {
                txtName: {
                    required: true
                },
                txtCartCount: {
                    required: true,
                    number: true
                },
                txtCaddyCount: {
                    required: true,
                    number: true
                },
                txtBookBuffer: {
                    required: true,
                    number: true
                }         
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                },
                txtCartCount: {
                    required: "This field is required"
                },
                txtCaddyCount: {
                    required: "This field is required"
                },
                txtBookBuffer: {
                    required: "This field is required"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            await postClub(club);
            helper.StopProcessing(btn);
            fetchClub();
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: 'Club updated successfully!',
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,
                animation: false,
                customClass: {
                    popup: 'margin-20',
                },
            });

        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }



    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <form className="kt-form" id="add-Club-form">
                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Name<span className="red">*</span></label>
                                        <div className="col-xl-4 col-lg-5">
                                            <input 
                                                id="txtName" 
                                                name="txtName" 
                                                type="text" 
                                                className="form-control" 
                                                value={club.Name || ''}
                                                onChange={(event) => updateClubDetails('Name', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Address</label>
                                        <div className="col-xl-4 col-lg-5">
                                            <textarea 
                                                id="txtAddress" 
                                                name="txtAddress" 
                                                className="form-control" 
                                                rows="3"
                                                value={club.Address || ''}
                                                onChange={(event) => updateClubDetails('Address', event.target.value)}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">City</label>
                                        <div className="col-xl-4 col-lg-5">
                                            <input 
                                                id="txtCity" 
                                                name="txtCity" 
                                                type="text" 
                                                className="form-control" 
                                                value={club.City || ''}
                                                onChange={(event) => updateClubDetails('City', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">State</label>
                                        <div className="col-xl-4 col-lg-5">
                                            <input 
                                                id="txtState" 
                                                name="txtState" 
                                                type="text" 
                                                className="form-control"
                                                value={club.State || ''}
                                                onChange={(event) => updateClubDetails('State', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Country</label>
                                        <div className="col-xl-4 col-lg-5">
                                            <input 
                                                id="txtCountry" 
                                                name="txtCountry" 
                                                type="text" 
                                                className="form-control"
                                                value={club.Country || ''}
                                                onChange={(event) => updateClubDetails('Country', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Latitude</label>
                                        <div className="col-xl-2 col-lg-3">
                                            <input 
                                                id="txtLatitude" 
                                                name="txtLatitude" 
                                                type="number" 
                                                className="form-control" 
                                                value={club.Latitude || ''}
                                                onChange={(event) => updateClubDetails('Latitude', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Longitude</label>
                                        <div className="col-xl-2 col-lg-3">
                                            <input 
                                                id="txtLongitude" 
                                                name="txtLongitude" 
                                                type="number" 
                                                className="form-control"
                                                value={club.Longitude || ''}
                                                onChange={(event) => updateClubDetails('Longitude', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Mobile</label>
                                        <div className="col-xl-4 col-lg-5">
                                            <input 
                                                id="txtMobile" 
                                                name="txtMobile" 
                                                type="number" 
                                                className="form-control"
                                                value={club.Mobile || ''}
                                                onChange={(event) => updateClubDetails('Mobile', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Phone</label>
                                        <div className="col-xl-4 col-lg-5">
                                            <input 
                                                id="txtPhone" 
                                                name="txtPhone" 
                                                type="number" 
                                                className="form-control"
                                                value={club.Phone || ''}
                                                onChange={(event) => updateClubDetails('Phone', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Email</label>
                                        <div className="col-xl-4 col-lg-5">
                                            <input 
                                                id="txtEmail" 
                                                name="txtEmail" 
                                                type="text" 
                                                className="form-control" 
                                                value={club.Email || ''}
                                                onChange={(event) => updateClubDetails('Email', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Website</label>
                                        <div className="col-xl-4 col-lg-5">
                                            <input 
                                                id="txtWebsite" 
                                                name="txtWebsite" 
                                                type="text" 
                                                className="form-control"
                                                value={club.Website || ''}
                                                onChange={(event) => updateClubDetails('Website', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Total Cart <span className="red">*</span></label>
                                        <div className="col-xl-4 col-lg-5">
                                            <input 
                                                id="txtCartCount" 
                                                name="txtCartCount" 
                                                type="number" 
                                                className="form-control"
                                                value={club.CartCount || ''}
                                                onChange={(event) => updateClubDetails('CartCount', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Total Caddy <span className="red">*</span></label>
                                        <div className="col-xl-4 col-lg-5">
                                            <input 
                                                id="txtCaddyCount" 
                                                name="txtCaddyCount" 
                                                type="number" 
                                                className="form-control"
                                                value={club.CaddyCount || ''}
                                                onChange={(event) => updateClubDetails('CaddyCount', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Booking Gap (In days) <span className="red">*</span></label>
                                        <div className="col-xl-4 col-lg-5">
                                            <input 
                                                id="txtBookBuffer" 
                                                name="txtBookBuffer" 
                                                type="number" 
                                                className="form-control" 
                                                value={club.BookBuffer || ''}
                                                onChange={(event) => updateClubDetails('BookBuffer', event.target.value)} 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="kt-portlet__foot">
                            <div className="kt-form__actions">
                                <div className="row">
                                    <div className="col-lg-9 ml-lg-auto">
                                        <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addClubHandler}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
 export default Club;