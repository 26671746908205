import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete } from "./axiosFunctions";

//#region Api URL's --> 

const getMatMasterAllUrl                = '/api/admin/mat/getMatMasterAll';
const postMatMasterUrl                  = '/api/admin/mat/postMatMaster';

const getMatTimeMasterAllUrl            = '/api/admin/mat/getMatTimeMasterAll';
const postMatTimeMasterUrl              = '/api/admin/mat/postMatTimeMaster';

const postMatTemplateUrl                = '/api/admin/mat/postMatTemplate';
const getMatTemplateByMasterIDUrl       = (id) => `/api/admin/mat/getMatTemplateByMasterID/${id}`;

const getMatBookUrl                     = '/api/admin/mat/getMatBook';
const postMatTimeBlockUrl               = '/api/admin/mat/postMatTimeBlock';  
const postMatBookUrl                    = '/api/admin/mat/postMatBook';  
const deleteMatTimeBlockUrl             = (id) => `/api/admin/mat/deleteMatTimeBlock/${id}`;  
const getGreenFeeUrl                    = (date, dayName, type, holeType) => `/api/admin/mat/getGreenFee/${date}/${dayName}/${type}/${holeType}`;  

export const getMatMasterAll = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getMatMasterAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getMatBook = async (matBookDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( getMatBookUrl, {matBookDetails}, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postMatMaster= async(matMaster)=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postMatMasterUrl, { matMaster }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getMatTimeMasterAll = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getMatTimeMasterAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postTimeMatMaster= async(matMaster)=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postMatTimeMasterUrl, { matMaster }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postMatTemplate= async(matTemplate)=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postMatTemplateUrl, { matTemplate }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getMatTemplateByMasterID = async(id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getMatTemplateByMasterIDUrl(id), { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postMatTimeBlock = async(books, date) =>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postMatTimeBlockUrl, { books, date }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const deleteMatTimeBlock = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosDelete(
        deleteMatTimeBlockUrl(id),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getGreenFee = async (date, dayName, type, holeType) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getGreenFeeUrl(date, dayName, type, holeType),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postMatBook = async(matBook)=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postMatBookUrl, { matBook }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}