import React, { useEffect, useState } from "react";
import '../../assets/styles/header-light.css';
import './header.css';
import { logout } from '../api/loginAPI';
import { getAuthState, setAuthState } from "../../assets/scripts/login-util";

const Header = (props) =>{

    const [username,            		setUsername]       	 			= useState('Admin');

    useEffect(() =>{
        const cData = getAuthState()
        const { user } = cData;
        
        setUsername(user.userName)

    },[])

	const logoutHandler = async () => {
		try {
			await logout()
			setAuthState();
            window.open('/', '_self');
		} catch (err) {
			window.open('/', '_self');
			swal.fire({
				icon              : "error",
				titleText         : "Error!",
				text              :  err.message,
				buttonsStyling    : false,
				confirmButtonClass: "btn btn-brand",
			});
		}
	};

    
    return(
        <div id="kt_header" className="kt-header kt-grid__item  kt-header--fixed header-curtailed">
            <div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
                <div id="kt_header_menu" className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default ">
                    <h3 className="header-title">{props.title}</h3>
                </div>
            </div>
            <div className="kt-header__topbar"  >
                <div className="kt-header__topbar-item kt-header__topbar-item--user" >
                    <div className="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="0px,0px">
                        <div className="kt-header__topbar-user">
                            <span className="kt-header__topbar-welcome kt-hidden-mobile">Hi,</span>
                            <span className="kt-header__topbar-username kt-hidden-mobile">{username}</span>
                            <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">{username[0].toUpperCase()}</span>
                        </div>
                    </div>
                    <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
                        <div className="kt-notification">
                            <a id="btnLogout" className="kt-notification__item padding-tb10">
                                <div className="kt-notification__item-icon">
                                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <rect x="0" y="0" width="24" height="24"/>
                                            <path d="M7.62302337,5.30262097 C8.08508802,5.000107 8.70490146,5.12944838 9.00741543,5.59151303 C9.3099294,6.05357769 9.18058801,6.67339112 8.71852336,6.97590509 C7.03468892,8.07831239 6,9.95030239 6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,9.99549229 17.0108275,8.15969002 15.3875704,7.04698597 C14.9320347,6.73472706 14.8158858,6.11230651 15.1281448,5.65677076 C15.4404037,5.20123501 16.0628242,5.08508618 16.51836,5.39734508 C18.6800181,6.87911023 20,9.32886071 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 C4,9.26852332 5.38056879,6.77075716 7.62302337,5.30262097 Z" fill="#000000" fillRule="nonzero"/>
                                            <rect fill="#000000" opacity="0.3" x="11" y="3" width="2" height="10" rx="1"/>
                                        </g>
                                    </svg>
                                </div>
                                <div className="kt-notification__item-details"><div className="kt-notification__item-title" onClick={logoutHandler}>Logout</div></div>
                            </a>
                        </div>            
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Header;
