import { useEffect, useState } from "react"
import RightSideDetails from "../global/rightSideDetails";
import { getFeedbackAll } from "../api/golfClubAPI";
import swal from 'sweetalert2';

const Feedback = (props) => {

    const [rightSideDetails,           setRightSideDetails]           = useState(false);
    const [isInit,                     setIsInit]                     = useState(true);
    const [feedBackList,               setFeedBackList]               = useState([]);
    const [feedBack,                   setFeedBack]                   = useState({});

    useEffect(() => {
        props.title('Feedback', 'feedback')
        fetchFeedback();
    },[])

    useEffect(() => {
        if(rightSideDetails){
            Config.Core.OpenDrawer()
        }
    },[rightSideDetails])

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();        
        }
    }, [feedBackList]);

    const fetchFeedback = async() =>{
        try {
            const feedBack = await getFeedbackAll()
            isInit ? setIsInit(false) : $('#tblFeedback').KTDatatable().destroy(); 
            setFeedBackList(feedBack);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    function fillDataTable() {

        const dataTable = $('#tblFeedback').KTDatatable({
            data: {
                saveState: false,
                source: feedBackList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'User',
                    width: 210,
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                    ${(row.Photo && row.Photo != '') ? `<img src="${row.Photo}" alt="user-profile" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                                    </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.Name} </span> 
                                        <span class="kt-user-card-v2__desc text-clip"> ${row.Email} </span>
                                    </div>
                               </div>`
                    },
                },
                {
                    field: 'Mobile',
                    title: 'Mobile',
                    width: 150,
                },

                {
                    field: 'Message',
                    title: 'Message',
                    width: 350,
                    template: function (row) {
                        var displayText = row.Message.length > 50 ? row.Message.substring(0, 50) + '...' : row.Message;
                        return '<span title="' + row.Message + '">' + displayText + '</span>';
                    },

                },

                {
                    field: 'CreatedDate',
                    title: 'Received Date',
                    width: 180,
                    template: function (row) {
                        return moment(row.CreatedDate).format('DD-MMM-YYYY hh:mm A');
                    }

                }
            ]
        });

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setFeedBack(dataTable.dataSet[index]);  
                setRightSideDetails(true); 
            }    
        });
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

     return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content" >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Feedback..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblFeedback" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Feedback Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body">
                                        <div className="kt-widget kt-widget--user-profile-3">
                                            <div className="kt-widget__top">
                                                {feedBack.Photo && feedBack.Photo != '' ? 
                                                    <img className="kt-widget__pic kt-widget__pic" src={feedBack.Photo} alt="user-profile"/> :
                                                    <div
                                                        className={`kt-widget__pic kt-widget__pic--brand kt-font-brand kt-font-boldest `}>
                                                        {feedBack.Name.slice(0, 1)}
                                                    </div>
                                                }   
                                                <div className="kt-widget__content">
                                                    <div className="kt-widget__head">
                                                        <span className="kt-widget__username">
                                                            {feedBack.Name}{' '}
                                                            &nbsp;&nbsp;&nbsp;
                                                        </span>
                                                        {/* <div className="kt-widget__action">
                                                            <span className="dropdown">
                                                                <a
                                                                    className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                                                    data-toggle="dropdown"
                                                                    aria-expanded="true">
                                                                    <i className="flaticon-more-1"></i>
                                                                </a>
                                                                <OverflowMenu
                                                                    onEditClick={() =>
                                                                        {setEditUser(true)}
                                                                    }
                                                                />
                                                            </span>
                                                        </div> */}
                                                    </div>
                                                    <div className="kt-widget__subhead padding-t0">
                                                        <br></br>
                                                        {feedBack.Mobile && (
                                                            <span className="padding-r10">
                                                            <i className="flaticon2-phone"></i>
                                                            {feedBack.Mobile}
                                                            </span>
                                                        )}
                                                        {feedBack.Email && (
                                                            <span className="padding-r10">
                                                                <i className="flaticon2-new-email"></i>
                                                                {feedBack.Email}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-t0">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body">
                                            <p 
                                                id="txtMessage" 
                                                className="text-desc"
                                            >{feedBack.Message}</p>
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>
            </div>
        </>
     )
}

export default Feedback;
