import React, {useEffect, useState} from 'react'
import Dropzone from '../global/dropzone';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import { getActiveSubscriber, getSubscriber, postResources } from '../api/academyAPI';
import { uploadPrivateFile, uploadPublicFile } from '../api/fileAPI';
import Select from 'react-select';
// import VideoZipper from 'video-zipper';

function AddResources(props) {

    const [resourcesDetails,    setResourcesDetails]    = useState({Type: 'Public', FileType: 'Image'})
    const [file,                setFile]                = useState(null); 
    const [subscriberList,      setSubscriberList]      = useState([]);

    useEffect(() => {
        $('#add-resources-modal').modal({
            backdrop: 'static',
        });
        $('#add-resources-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);
        });
    }, []);

    useEffect(() => {
        if (props.PackageID) {
            fetchActiveSubscriber(props.PackageID)
        }
    }, [props.PackageID])

    const fetchActiveSubscriber = async(id) => {
        try {
            const response = props.type === 'Group' ? await getSubscriber(id) : await getActiveSubscriber(id, 'resources');
            setSubscriberList(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const updateResourcesDetails = (field, value) => {
        setResourcesDetails((f) => {
            return { ...f, [field]: value };
        });
    };

    const addResourcesHandler = async () => {
        const btn = $('#btnSave');
        var form = $('#add-resources-form');
        form.validate({
            rules: {
                title: {
                    required: true
                },
                ddlSelectUser: {
                    required: true
                },   
            },
            messages: {
                title: {
                    required: "Please enter title"
                },
                ddlSelectUser: {
                    required: "Please select name"
                },
            }
        });

        if (!form.valid()) return;

        if(!file) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : "Please upload a file",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            helper.StopProcessing(btn);
            return;
        } else {
            const fileType = helper.getFileType(file.name)
            if (fileType != resourcesDetails.FileType.toLowerCase()) {
                swal.fire({
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : "Invalid File Type",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
                helper.StopProcessing(btn);
                return;
            }
        }

        helper.StopProcessing(btn);

        try {

            // const compressor = new VideoZipper({
            //     quality: 'medium',
            // });
        
            // compressor.load().then(()=>{
            //     console.log('loaded');
            // });
        
            // compressor.progress((percent) => {
            //     console.log('percent: ' + percent);
            // });        

            // compressor.compress(file)
            //     .then(() => {
            //         console.log('Video compressed successfully!');
                    
            //         // Download file
            //         compressor.download();
                    
            //         // Get local URL (URL blob)
            //         const url = compressor.getUrl();
                    
            //         // Get blob
            //         const blob = compressor.getBlob();
                    
            //         // for Upload file
            //         const fd = new FormData();
            //         fd.append('file', blob);
            //         // Use fetch or any other library to upload the file using the FormData object
            //     })
            //     .catch(err => console.error(err));

            let docName = '';
            if (file) {
                const res = await uploadPrivateFile(file, 'Resources');
                docName = res.fileName
            } else {
                if (resourcesDetails.FileName) docName = resourcesDetails.FileName.split('/Resources/')[1];
            }
            await postResources({...resourcesDetails, PackageID: props.PackageID, FileName: docName});
            helper.StopProcessing(btn);
            props.refetchResources()
            $('#add-resources-modal').modal('hide');
            swal.fire({
                icon              : 'success',
                titleText         : 'Resources Added Successfully',
                showConfirmButton : false,
                timer             : 1500,
            });
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
              icon                  : 'error',
              titleText             : 'Error!',
              text                  : err.message,
              buttonsStyling        : false,
              confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }

    }
    const closeDrawer = () => {

    }

    const removeFileHandler = (e) => {
        e.preventDefault();
        setFile(null);
    }

    const fileHandler = async (f) => {
        setFile(f)
    }

    return (
        <div className="modal fade"id="add-resources-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add Resources</h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={closeDrawer}
                        >
                        </button>
                    </div>
                    <div className="modal-body">
                        <form className="kt-form" id="add-resources-form">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className='form-group row'>
                                    <label className="col-form-label col-lg-3">Resources Type</label>
                                    <div className="col-xl-6 col-lg-8">
                                        <select
                                            className="form-control kt-selectpicker"
                                            id="ddlType"
                                            value={resourcesDetails.Type || ''}
                                            onChange={(event) => updateResourcesDetails('Type', event.target.value)}>                                                 
                                            <option value="Public">Public</option>
                                            <option value="Private">Private</option>
                                        </select>
                                    </div>
                                </div>
                                {resourcesDetails.Type === 'Private' && (
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Subscriber</label>
                                        <div className="col-xl-6 col-lg-8">
                                            <div className="input-group" >
                                                <div style={{width: "100%"}}  >
                                                    <Select
                                                        value={(subscriberList && subscriberList.length > 0 && resourcesDetails.UserID) && {label: `${subscriberList.find(m => m.UserID == resourcesDetails.UserID).Name} (${subscriberList.find(m => m.UserID == resourcesDetails.UserID).Mobile})`, value: resourcesDetails.UserID} || ''}
                                                        onChange={(event) => updateResourcesDetails('UserID', event.value)}
                                                        name="ddlSelectUser"
                                                        placeholder="Select Subscriber"
                                                        id="ddlSelectUser"
                                                        className="ddlSelectUser"
                                                        options={subscriberList.map(c => ({
                                                            label: `${c.Name} (${c.Mobile})`,
                                                            value: c.UserID
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-3">Title<span className="red">*</span></label>
                                    <div className="col-xl-6 col-lg-8">
                                        <input
                                            className="form-control"
                                            value={resourcesDetails.Title || ''}
                                            onChange={(event) => updateResourcesDetails('Title', event.target.value)}
                                            id="title"
                                            name="title"
                                            type="text"
                                        />						
                                    </div>
                                </div>  
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-3">Comment</label>
                                    <div className="col-xl-6 col-lg-8">
                                        <textarea
                                            className="form-control"
                                            rows='3'
                                            value={resourcesDetails.Comment || ''}
                                            onChange={(event) => updateResourcesDetails('Comment', event.target.value)}
                                            id="comment"
                                            name="comment"
                                            type="text"
                                        />						
                                    </div>
                                </div>  
                                <div className='form-group row'>
                                    <label className="col-form-label col-lg-3">File Type</label>
                                    <div className="col-xl-6 col-lg-8">
                                        <select
                                            className="form-control kt-selectpicker"
                                            id="ddlFileType"
                                            value={resourcesDetails.FileType || ''}
                                            onChange={(event) => updateResourcesDetails('FileType', event.target.value)}>                                                 
                                            <option value="Image">Image</option>
                                            {/* <option value="Audio">Audio</option> */}
                                            <option value="PDF">PDF</option>
                                            {/* <option value="Video">Video</option> */}
                                        </select>
                                    </div>
                                </div>
                                {!file && (<Dropzone fileHandler={fileHandler} from={resourcesDetails.FileType === 'Video' && 'resources'} accept="image/* pdf"/>)}
                                {file && (
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="kt-notification margin-b30" style={{backgroundColor: '#f7f8fa'}}>
                                                    <a className="kt-notification__item">
                                                        <div className="kt-notification__item-icon margin-r10" onClick={() => helper.openFileHandler(file)}>
                                                            <img height="30px" width="30px" src={helper.getFileLogo(file.name)} />
                                                        </div>
                                                        <div className="kt-notification__item-details" onClick={() => helper.openFileHandler(file)}>
                                                            <div className="kt-notification__item-title kt-font-bold">
                                                                {file.name}
                                                            </div>
                                                        </div>
                                                        <button 
                                                            type="button"                    
                                                            onClick={removeFileHandler}
                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div> 
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary margin-0 margin-r5"
                            data-dismiss="modal"
                            onClick={closeDrawer}
                        >
                            Close
                        </button>
                        <button
                            id="btnSave"
                            onClick={addResourcesHandler}
                            type="button"
                            className={`btn btn-brand margin-0 `}
                        >                  
                            {/* {props.editDocument.FileName ? 'Update' : 'Attach'} */}
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddResources;