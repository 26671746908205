import { useState, useEffect } from "react";
import swal from 'sweetalert2';
import Select from "react-select";
import { deleteShotgunTemplate, getShotgunTemplateData, postShotgunTemplate } from "../api/teeTimeAPI"



const ShotgunTemplate = (props) => {
    
    const [pageData,            setPageData]        = useState({});

    const [isEdit,              setIsEdit]          = useState(false);
    const [isUpdate,            setIsUpdate]        = useState(false);
    
    const [allTemplates,        setAllTemplates]    = useState([]);
    const [newTemplate,         setNewTemplate]     = useState({});

    //only for react-selects -->
    const [selectedMaster,      setSelectedMaster]  = useState();
    const [selectedBlock,       setSelectedBlock]   = useState();
   
    useEffect(() => {
        props.title("Shotgun Template", "shotgun-template");       
        fetchData();
        
        //Sorting Function -->
        AllowSortable();
    }, []);


    const fetchData = async() => {
        try {
            const {shotgunTemplates, shotgunBlocks, shotgunMasters} = await getShotgunTemplateData();         

            const masters = shotgunMasters.map(m =>({label: m.Name , value : m.ID, ...m}));         
            const blocks  = shotgunBlocks.map(b => ({label: b.Block , value : b.ID, ...b}));

            setPageData({ masters, blocks, shotgunTemplates});

            if(masters.length > 0){
                setIsEdit(masters[0].IsEdit);
                filterTemplates( { ddl: "both", master:masters[0] ,block: blocks[0]}, shotgunTemplates);                                                   
            }
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    }

    const postTemplate = () => {       
        const form = $('#addTemplateForm');
        
        form.validate({
            rules   : { ddlMaster: { required: true }, ddlBlock: { required: true }, txtHole: { required:true } },
            messages: { ddlMaster : { required: "Please select master" }, ddlBlock : { required: "Please select block" }, txtHole:{ required: "Please enter hole" } }
        });

        if (!form.valid()) return;
        
        //Validation Goes Here -->  
        
        try {    

            if(!isUpdate) {
                newTemplate.ShotgunMasterID = selectedMaster.ID
                newTemplate.ShotgunBlockID  = selectedBlock.ID
                newTemplate.Index           = allTemplates.length + 1;            
            }else{
                //Allow to update block, teebox, slot -->   
                newTemplate.ShotgunBlockID  = selectedBlock.ID                          
            }

            //Calls API -->
            saveTemplates([newTemplate]);

            if (newTemplate.ID) {            
                swal.fire({ toast: true, icon: 'success', position: 'bottom-end', showConfirmButton: false, timer: 2500,  
                    titleText: 'Template updated successfully!', 
                    onOpen: () => swal.hideLoading()                                     
                });
            }else{
                swal.fire({ icon: 'success', showConfirmButton: false, timer: 2500,
                    titleText: 'Template added successfully!',
                    onOpen: () => swal.hideLoading()    
                });
            }
                
            clearActions();
        } catch (err) {
            swal.fire({ icon: 'error', titleText: 'Error!', text: err.message, buttonsStyling: false, confirmButtonClass: 'btn btn-brand'});
            return;
        }        
    }    

    const deleteHandler = async(templateId) => {
        if(!templateId) return;

        swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
        try {
            const res = await deleteShotgunTemplate(templateId);
                        
            //Update and Refresh -->
            filterTemplates( { ddl: "both", master: selectedMaster , block: selectedBlock }, res);             
            
            setPageData( d => {
                d.shotgunTemplates = res;
                return {...d};
            });            
                           
            swal.fire({ toast: true, icon: 'success', position: 'bottom-end', showConfirmButton: false, timer: 2500,  
                titleText: 'Template deleted successfully!', 
                onOpen: () => swal.hideLoading()                                     
            });
            // swal.close()
        } catch (err) {
            swal.fire({ icon: 'error', titleText: 'Error!', text: err.message, buttonsStyling: false, confirmButtonClass: 'btn btn-brand'});
            return;
        }
    }

    const saveTemplates = async(templates) => {
        if(templates.length <= 0) return;

        try {
            swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
                        
            const res = await postShotgunTemplate(templates);
            
            //Update and Refresh -->
            filterTemplates( { ddl: "both", master:selectedMaster , block:selectedBlock }, res);             
            
            setPageData( d => {
                d.shotgunTemplates = res;
                return {...d};
            });            
                                    
            swal.close()
        } catch (err) {
            swal.close()
            swal.fire({ icon: 'error', titleText: 'Error!', text: err.message, buttonsStyling: false, confirmButtonClass: 'btn btn-brand'});
            return;  
        }
    }

    const filterTemplates = (d,t) => {
        const { shotgunTemplates } = pageData;
        
        const templates = (t) ? t : shotgunTemplates;
        const master    =  (!!d.master) ? d.master : selectedMaster;
        const block     =  (!!d.block) ? d.block : selectedBlock;
        
        const filteredTemplates = templates.filter( t => t.ShotgunMasterID === master.ID && t.ShotgunBlockID === block.ID);
        
        if(d.ddl === 'block' || d.ddl === "both")
            setSelectedBlock(d.block);
        
        if(d.ddl === 'master' || d.ddl === "both")
            setSelectedMaster(d.master);
        
        setAllTemplates(filteredTemplates);
    }

    //#region Helpers --->
        const dataHandler = (field,value) => setNewTemplate(d => ({...d, [field]: value}));

        const onMasterChange = (m) => {
            setIsEdit(m.IsEdit);
            filterTemplates({ddl:'master', master: m});
        }
        const onBlockChange = (b) => filterTemplates({ddl:'block', block: b});

        const editHandler = (id) => {                
            setIsUpdate(true);
            
            const d = allTemplates.find( a => a.ID == id);      
            setNewTemplate(d);
            $(`tr[data-id =${id}]`).addClass("bg-lightgrey");
        }

        const AllowSortable =() =>{
            const config = (event, ui)=> {
                const ids = [];
                $('#table-block > tbody > .dragableRow').each((i,e) => ids.push( $(e).attr('data-id')));
                sortAndSave(ids);   
            }

            window.sortInit('#table-block tbody', config);    
        }

        const sortAndSave = async(param)=> {
            if(param.length <= 0) return;      
            swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
                        
            try {
                let templates = [];        

                setAllTemplates( d => {
                    const sorted = [];
                    if(d.length > 0){                  
                        for (const [i,id] of Object.entries(param)) {                       
                            let f = d.find( a => a.ID == id);
                            if(!!f){
                                f.Index = parseInt(i)+1;                    
                                sorted.push(f);
                            }
                        }
                    
                        templates = sorted;
                        return d;
                    }
                });
                            
                if(templates.length > 0){                 
                    const res = await postShotgunTemplate(templates);
                    setPageData( d => {
                        d.shotgunTemplates = res;
                        return {...d};
                    });    
                }                   
                swal.close()
            } catch (err) {             
                swal.close()
                swal.fire({icon: 'error',titleText: 'Error!',text: err.message,buttonsStyling: false, confirmButtonClass: 'btn btn-brand'});             
                return;  
            }
        }

            
        const clearActions = () => {              
        
            setIsUpdate(false);
            setNewTemplate({});          
            $("#table-block tbody tr").removeClass("bg-lightgrey");                   
        }

    //#endregion
    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <form className="kt-form" id="addTemplateForm" >                
                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10 bv-form" role="form">
                                    <div className="row justify-content-center">
                                        <div className="form-group col-sm-3 d-flex">
                                            <label className="col-form-label text-right mr-2">Master</label>
                                            <div className="input-group ddlCoursePnl">
                                                <Select id="ddlMaster" name="ddlMaster" className="width-95p" 
                                                    options={pageData.masters}
                                                    value={selectedMaster || ''} 
                                                    onChange={(m) => onMasterChange(m)}
                                                />
                                            </div>
                                        </div> 

                                        <label className="col-form-label col-sm-1 text-right pnlAdd">Block <span className="red">*</span></label>
                                        <div className="form-group col-sm-2 pnlAdd">
                                            <div className="input-group">                                            
                                                <Select id="ddlBlock" name="ddlBlock" className="width-95p" 
                                                    options={pageData.blocks}
                                                    value={selectedBlock || ''} 
                                                    onChange={(b) => onBlockChange(b)} 
                                                />
                                            </div>
                                        </div>                                   

                                        {(isEdit) && (<>                            
                                            <div className="form-group col-sm-5 pnlAdd d-flex">
                                                <label className="col-form-label  text-right pnlAdd width-55 margin-lr20"> Hole </label>
                                                <div className="input-group timepicker width-170  margin-r20">
                                                    <input id="txtHole" name="txtHole" type="text" className="form-control" placeholder="Enter Hole" 
                                                        value={ newTemplate.Hole || '' }                                               
                                                        onChange={({target}) => dataHandler("Hole", target.value)}
                                                    />                                              
                                                </div>

                                                {(!isUpdate) ? (
                                                    <button id="btnAdd" type="button" className={`btn btn-label-brand d-inline-block margin-0  height-38 width-100`} onClick={postTemplate} ><i className="la la-plus"></i>Add</button>
                                                ):(
                                                    <div className="btn-group height-38">
                                                        <button id="btnUpdate" type="button" className={`btn btn-label-success d-inline-block margin-0`} onClick={postTemplate} > Update </button>
                                                        <button id="btnCancel" type="button" className="btn btn-secondary" onClick={clearActions}> <i className="la la-times ml-1 font-16" > </i> </button>
                                                    </div>
                                                )}
                                            </div>
                                        </>) }                                  
                                    
                                    </div>                                
                                </div>
                            </form>

                            <table id="table-block" className="table table-bordered table-hover margin-b30">
                                <thead className="light-grey-background">
                                    <tr>                                   
                                        <th>Hole</th>
                                        <th>  </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(allTemplates.length > 0 )?
                                        allTemplates.map((t,i) => {
                                            return (<tr key={i} data-id={t.ID}>                                          
                                                <td>{t.Hole}</td>
                                                <td className="text-center">
                                                    {(isEdit) && (<>
                                                        <button type="button" disabled={isUpdate} className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-block-edit mr-2"
                                                            onClick={() => editHandler(t.ID)}
                                                        >
                                                            <i className="flaticon2-edit"></i>
                                                        </button>
                                                        <button type="button" disabled={isUpdate} className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-block-edit"
                                                            onClick={() => deleteHandler(t.ID)}
                                                        >
                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                        </button>
                                                    </>
                                                    )}
                                                </td>
                                            </tr>)
                                        }):
                                        (<tr><td colSpan={4}> 
                                            <div className="padding-10"> 
                                                <h5 className="text-center text-muted"> No Slots Available..! </h5> 
                                            </div>
                                        </td></tr>)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ShotgunTemplate;