import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const postPackageUrl                                       = '/api/admin/academy/postPackage';
const postSubscriberUrl                                    = '/api/admin/academy/postSubscriber';
const postCoachUrl                                         = '/api/admin/academy/postCoach';
const postLessonUrl                                        = '/api/admin/academy/postLesson';
const postLessonBookUrl                                    = '/api/admin/academy/postLessonBook';
const postSubLessonUrl                                     = '/api/admin/academy/postSubLesson';
const postAcademyDiscountUrl                               = '/api/admin/academy/postAcademyDiscount';
const postAcademyFeeUrl                                    = '/api/admin/academy/postAcademyFee';
const postResourcesUrl                                     = '/api/admin/academy/postResources';
const postPackageTimeUrl                                   = '/api/admin/academy/postPackageTime';
const getCoachAllUrl                                       = '/api/admin/academy/getCoachAll';
const getPackageAllUrl                                     = '/api/admin/academy/getPackageAll';
const getActivePackageUrl                                  = `/api/admin/academy/getActivePackage`;
const getDaysUrl                                           = '/api/admin/academy/getDays';
const getPackageTimeByPackageIDUrl                         = (packageID, dayID) => `/api/admin/academy/getPackageTime/${packageID}/${dayID}`;
const getLessonBookUrl                                     = (packageID, type, dayID, date) => `/api/admin/academy/getLessonBook/${packageID}/${type}/${dayID}/${date}`;
const getResourcesUrl                                      = (id) => `/api/admin/academy/getResources/${id}`;
const getAcademyFeeByPackageIDUrl                          = (id) => `/api/admin/academy/getAcademyFeeByPackageID/${id}`;
const getAcademyDiscountByPackageIDUrl                     = (id) => `/api/admin/academy/getAcademyDiscountByPackageID/${id}`;
const getLessonAndSubLessonByPackageIDUrl                  = (id) => `/api/admin/academy/getLessonAndSubLessonByPackageID/${id}`;
const getLessonByPackageIDUrl                              = (id) => `/api/admin/academy/getLessonByPackageID/${id}`;
const getSubLessonByLessonIDUrl                            = (id) => `/api/admin/academy/getSubLessonByLessonID/${id}`;
const getCompletedLessonByUserIDUrl                        = (userID) => `/api/admin/academy/getCompletedLessonByUserID/${userID}`;
const getSubscriberUrl                                     = (packageId) => `/api/admin/academy/getSubscriber/${packageId}`;
const getActiveSubscriberUrl                               = (packageId, type) => `/api/admin/academy/getActiveSubscriber/${packageId}/${type}`;
const getPackageSlotUrl                                    = (id) => `/api/admin/academy/getPackageSlot/${id}`;
const deletePackageTimeUrl                                 = (id) => `/api/admin/academy/deletePackageTime/${id}`;
const deleteResourcesUrl                                   = (id) => `/api/admin/academy/deleteResources/${id}`;
const assignCoachUrl                                       = `/api/admin/academy/assignCoach`;
const postCompletedLessonUrl                               = `/api/admin/academy/postCompletedLesson`;

export const getDays = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getDaysUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getPackageTimeByPackageID = async (packageID, dayID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getPackageTimeByPackageIDUrl(packageID, dayID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getLessonBook = async (packageID, type, dayID, date) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getLessonBookUrl(packageID, type, dayID, date),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getPackageAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getPackageAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getCoachAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getCoachAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getActivePackage = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getActivePackageUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getPackageSlot = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getPackageSlotUrl(id),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getAcademyDiscountByPackageID = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getAcademyDiscountByPackageIDUrl(id),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getResources = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getResourcesUrl(id),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getAcademyFeeByPackageID = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getAcademyFeeByPackageIDUrl(id),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getLessonByPackageID = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getLessonByPackageIDUrl(id),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getLessonAndSubLessonByPackageID = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getLessonAndSubLessonByPackageIDUrl(id),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getSubLessonByLessonID = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getSubLessonByLessonIDUrl(id),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getCompletedLessonByUserID = async (userID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getCompletedLessonByUserIDUrl(userID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getSubscriber = async (packageId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getSubscriberUrl(packageId),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getActiveSubscriber = async (packageId, type) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getActiveSubscriberUrl(packageId, type),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postPackage = async (packageDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postPackageUrl,
        { packageDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postSubscriber = async (subscriberDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postSubscriberUrl,
        { subscriberDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postCoach = async (coachDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postCoachUrl,
        { coachDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postResources = async (resourcesDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postResourcesUrl,
        { resourcesDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postPackageTime = async (packageDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postPackageTimeUrl,
        { packageDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deletePackageTime = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosDelete(
        deletePackageTimeUrl(id),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deleteResources = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosDelete(
        deleteResourcesUrl(id),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postLesson = async (lessonDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postLessonUrl,
        { lessonDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postLessonBook = async (bookSheet) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postLessonBookUrl,
        { bookSheet },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postSubLesson = async (subLessonDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postSubLessonUrl,
        { subLessonDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postAcademyDiscount = async (feeDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postAcademyDiscountUrl,
        { feeDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postAcademyFee = async (feeDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postAcademyFeeUrl,
        { feeDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const assignCoach = async (bookSheet) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        assignCoachUrl,
        { bookSheet },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postCompletedLesson = async (isChecked, subLessonID, userID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postCompletedLessonUrl,
        {isChecked, subLessonID, userID},
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};
