import React, {useState, useEffect} from 'react'
import { getActivePackage, getActiveSubscriber, getLessonBook, getPackageTimeByPackageID, getSubscriber, postLessonBook } from '../api/academyAPI';
import Select from 'react-select';
import swal from 'sweetalert2';
import RightSideDetails from "../global/rightSideDetails";
import NoData from '../global/noData';
import * as helper from '../global/helper';
import CoachAssignModal from './CoachAssignModal';

function Booking(props) {

    const [selectedPackage,             setSelectedPackage]         = useState(null);
    const [type,                        setType]                    = useState('Individual')
    const [activePackage,               setActivePackage]           = useState([]);
    const [subscriberList,              setSubscriberList]          = useState([]);
    const [filteredActivePackage,       setFilteredActivePackage]   = useState([]);
    const [selectedDate,                setSelectedDate]            = useState(moment().toDate());
    const [bookingSheetList,            setBookingSheetList]        = useState([]);
    const [bookSheet,                   setBookSheet]               = useState({});
    const [rightSideDetails,            setRightSideDetails]        = useState(false);
    const [bookingUserList,             setBookingUserList]         = useState([]);
    const [assignCoach,                 setAssignCoach]             = useState(null);
    const [refresh,                     setRefresh]                 = useState(false);
    // const []
    useEffect(() => {
        props.title("Booking Sheet", "booking")

        $('#txtDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtDate').on('change', function (event) {
            setSelectedDate(event.target.value)
        }); 
        fetchActivePackage()
    }, []);
    
    useEffect(() => {
    }, [refresh])

    useEffect(() => {  
        if(rightSideDetails){
            Config.Core.OpenDrawer()
        }else{
            setBookSheet({})
            setBookingUserList([])
        }
    }, [rightSideDetails]);

    useEffect(() => {
        if (type) {
            setSelectedPackage(null)
            setBookingSheetList([])
            setFilteredActivePackage(activePackage.filter(a => a.Type === type))
        }
    }, [type, activePackage])

    useEffect(() => {
        if (selectedDate && selectedPackage) {
            let dayID = moment(selectedDate, 'DD-MM-YYYY').day()
            dayID = dayID == 0 ? 7 : dayID
            fetchPackageTime(selectedPackage.value.ID, dayID)
        }
    }, [selectedDate, selectedPackage, refresh])

    useEffect(() => {
        if (bookSheet.PackageID) {
            fetchActiveSubscriber(bookSheet.PackageID)
        }
    }, [bookSheet])

    const refetchBookSheet = () => setRefresh(r => !r)

    const updateBookingDetails = (field, value) => { 
        setBookSheet((d) => {
            return { ...d, [field]: value };
        });
    };
    
    const openDrawerHandler = (b) => {
        setRightSideDetails(true)
        delete b.UserID
        setBookingUserList(b.Users)
        setBookSheet(b)
    }

    const addBookingUserHandler = () => {
        if(bookSheet.UserID){

            if (bookSheet.LessonBookID) {
                setBookSheet(b => ({
                    ...b,
                    addedUserID: [...(b.addedUserID || []), bookSheet.UserID]
                }))
            }

            setBookingUserList((a) => {
                const newA = [...a];
                const newO = subscriberList.find((f) => f.UserID == bookSheet.UserID)
                newA.push({...newO,  UserID: newO.UserID, UserType: "User"})
                return newA;
            })
            setBookSheet(a => ({...a, UserID: ''}))
        }
    }

    const deleteUserHandler = (index, value) => {
        if (bookSheet.LessonBookID) {
            setBookSheet(b => ({
                ...b,
                DeletedLessonBookID : [...(b.DeletedLessonBookID || []), value.LessonBookID]
            }))
        }

        setBookingUserList(p => {
            const nA = p.filter((f, i) => index !== i )
            return nA
        })
    }
    const closeDrawer = () => {
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    const addBookingHandler = async () => {
        const btn = $('#add-btn');

        if (!bookSheet.LessonBookID && bookingUserList.length == 0) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : "Add atleast one player",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return;
        }
        helper.StartProcessing(btn);
        try {
            await postLessonBook({...bookSheet, bookingUserList, Type: type, BookDate: moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD')});
            fetchPackageTime(bookSheet.PackageID, bookSheet.DayID)
            closeDrawer()
            helper.StopProcessing(btn);
            swal.fire({
                icon              : 'success',
                titleText         : 'Slot Booked',
                showConfirmButton : false,
                timer             : 1500,
            });
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const fetchActiveSubscriber = async(id) => {
        try {
            const response = type === 'Group' ? await getSubscriber(id) : await getActiveSubscriber(id, 'booking');
            setSubscriberList(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchActivePackage = async() => {
        try {
            const response = await getActivePackage();
            setActivePackage(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchPackageTime = async(packageID, dayID) => {
        try {
            const response = await getLessonBook(packageID, type, dayID, moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            setBookingSheetList(response);  
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    return (
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="row">
                                    <label className="col-form-label col-sm-1 text-right">Date </label>
                                    <div className="form-group col-xl-3 col-sm-2">
                                        <div className="input-group date">
                                            <input 
                                                id="txtDate" 
                                                name="txtDate" 
                                                type="text" 
                                                className="form-control " 
                                                placeholder="Select date" 
                                                defaultValue={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <label className="col-form-label col-sm-1 text-right">Type</label>
                                    <div className="form-group col-xl-3 col-sm-3">
                                        <select
                                            className="form-control kt-selectpicker"
                                            id="ddlType"
                                            value={type || ''}
                                            onChange={(event) => setType(event.target.value)}>
                                            <option value='Individual'>Individual Package</option>
                                            <option value='Group'>Group Package</option>
                                        </select>
                                    </div>
                                    <label className="col-form-label col-sm-1 text-right">Package</label>
                                    <div className="form-group col-xl-3 col-sm-3">
                                        <Select
                                            value={selectedPackage}
                                            onChange={(event) => {
                                                if (event) {
                                                    setSelectedPackage(event)
                                                }
                                            }}
                                            name="ddlPackage"
                                            placeholder="Select Package"
                                            id="ddlPackage"
                                            className="ddlPackage"
                                            options={filteredActivePackage.map(c => ({
                                                label: `${c.Title}`,
                                                value: c
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <table id="table-book-shotgun" className="table table-bordered table-hover margin-b30 ">
                                <thead className="light-grey-background">
                                    <tr>
                                        <th>Slot</th>
                                        <th>Status</th>
                                        <th>Player</th>
                                        <th>Coach</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                {(bookingSheetList && bookingSheetList.length > 0 &&
                                    bookingSheetList.map((b, i) => (

                                        <tbody key={i} style={{borderTop: 'none'}}>
                                            <tr >
                                                <td>{b.Time}</td>
                                                <td>
                                                    {/* <div className={`badge badge-${b.Status == 'Available' ? 'success' : b.Status == 'Booked' ? 'label-danger' : 'danger'}`}> */}
                                                    <div className={`badge badge-${b.Status == 'Available' ? 'success' : 'label-danger'}`}>
                                                        <span>{b.Status}</span>
                                                    </div>
                                                    {/* <div className={`badge badge-success`}>
                                                        <span>{'Available'}</span>
                                                    </div> */}
                                                </td>
                                                <td>
                                                    {(b.Users && b.Users.length > 0) && b.Users.map((u, i) => (
                                                        <span key={i}>
                                                            {u.Name}{i < b.Users.length - 1 ? ', ' : ''}
                                                        </span>
                                                    ))}
                                                </td>
                                                <td>
                                                    {b.Status !== 'Available' && (
                                                        <a className='btn btn-sm btn-label-warning btn-bold' onClick={() => setAssignCoach(b)}>
                                                            {b.CoachName ? b.CoachName : 'Unassigned'}
                                                        </a>
                                                    )}
                                                </td>
                                                <td className='text-center'>
                                                    <button 
                                                        type="button"
                                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                    
                                                        onClick={() => openDrawerHandler(b)}
                                                    >  
                                                        <i className="flaticon2-edit"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))
                                )}
                            </table>
                            {rightSideDetails && 
                                <RightSideDetails title="Booking Sheet" onCloseDrawer={closeDrawer}>
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body padding-t30 padding-b0">
                                            <form id="ModalHolder" className="kt-form kt-form--label-right padding-10">
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Date</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="lblDate" 
                                                            disabled="disabled" 
                                                            name="lblDate" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                                        />
                                                    </div>
                                                </div>
                                                <div id="pnlSlot" className="form-group row">
                                                    <label className="col-form-label col-lg-3">Slots</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="lblSlot" 
                                                            disabled="disabled" 
                                                            name="lblSlot" 
                                                            type="text"
                                                            onChange={updateBookingDetails} 
                                                            className="form-control" 
                                                            value={bookSheet.Time}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Subscriber</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <div className="input-group" >
                                                            <div style={{width: "60%"}}  >
                                                                <Select
                                                                    value={(subscriberList && subscriberList.length > 0 && bookSheet.UserID) && {label:  `${subscriberList.find(m => m.UserID == bookSheet.UserID).Name} (${subscriberList.find(m => m.UserID == bookSheet.UserID).Mobile})`, value: bookSheet.UserID} || ''}
                                                                    onChange={(event) => updateBookingDetails('UserID', event.value)}
                                                                    name="ddlSelectUser"
                                                                    placeholder="Select Subscriber"
                                                                    id="ddlSelectUser"
                                                                    className="ddlSelectUser"
                                                                    options={subscriberList.map(c => ({
                                                                        label: `${c.Name} (${c.Mobile})`,
                                                                        value: c.UserID
                                                                    }))}
                                                                />
                                                            </div>
                                                            {(type === 'Group' || (type === 'Individual' && bookingUserList.length === 0)) && (
                                                                <button id="btnAddUser" className="btn btn-label-brand margin-l10 margin-r0" type="button" onClick={addBookingUserHandler}>Add</button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Comment</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <textarea 
                                                            id="txtComment" 
                                                            name="txtComment" 
                                                            className="form-control" 
                                                            rows="3"
                                                            value={bookSheet.Comment || ''}
                                                            onChange={(event) => updateBookingDetails('Comment', event.target.value)} 
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                {bookingUserList && bookingUserList.length > 0 && (
                                                    <table id="table-user" className="table table-bordered table-hover margin-b30">
                                                        <thead className="light-grey-background">
                                                            <tr>
                                                                <th>Players</th>
                                                                <th>Email</th>
                                                                <th>Mobile</th>
                                                                <th className="text-center">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {bookingUserList.map((m, i) => (
                                                                <tr key={i}>
                                                                    <td>
                                                                        {m.Name}
                                                                    </td>
                                                                    <td>
                                                                        {m.Email}
                                                                    </td>
                                                                    <td>
                                                                        {m.Mobile}
                                                                    </td>
                                                                    <td>
                                                                        <center>
                                                                            <button className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10 btn-user-delete" type="button" onClick={() => deleteUserHandler(i, m)} >                  
                                                                                <i className="flaticon2-rubbish-bin-delete-button"></i>             
                                                                            </button>
                                                                        </center>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                )}
                                            </form>
                                        </div>
                                        <div className="kt-portlet__foot">
                                            <div className="kt-form__actions">
                                                <div className="row">
                                                    <div className="col-lg-12 text-right">
                                                        <button id="add-btn" type="button" className="btn btn-brand d-inline-block" onClick={addBookingHandler}>{bookSheet.LessonBookID ? 'Update Booking' : 'Save Booking' }</button>
                                                        {/* {bookSheet && 
                                                            bookSheet.ID && <button id="btnCancel" type="button" className="btn btn-warning d-inline-block margin-l10" onClick={cancelBookingHandler}>Cancel Booking</button>
                                                        } */}
                                                        <button id="btnCloseDrawer" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </RightSideDetails>
                            }
                        </div>
                    </div>  
                </div>
            </div>
            {assignCoach && (
                <CoachAssignModal
                    show={assignCoach}
                    bookSheet={assignCoach}
                    type={type}
                    refetchBookSheet={() => refetchBookSheet()}
                    onDismissModal={setAssignCoach}
                />
            )}
        </>
    )
}

export default Booking;