import { useState, useEffect } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import Select from 'react-select';
import ImageCropper from "../global/imageCropper";
import { getCoachAll, postCoach } from "../api/academyAPI";
import { uploadPublicFile } from "../api/fileAPI";
const Coach = (props) => {

    const [isInit,              setIsInit]              = useState(true);
    const [rightSideDetails,    setRightSideDetails]    = useState(false);
    const [coachDetails,        setCoachDetails]        = useState({});
    const [coachList,           setCoachList]           = useState([]);
    const [removeCk,            setRemoveCk]            = useState(false);
    const [photoBlob,           setPhotoBlob]           = useState(null);
    const [cropperData,         setCropperData]         = useState(null);
    const [selectedCoach,       setSelectedCoach]       = useState(null);

    useEffect(() => {
        props.title("Coach", "coach")
        fetchCoach();
    }, []);

    useEffect(() => {
        if(rightSideDetails){
            Config.Core.OpenDrawer()  
            window.initCkeditor("#editorContent");
            if (coachDetails && coachDetails.Content) {
                window.setEditorValue("#editorContent", coachDetails.About);
            }
        }else{
            setCoachDetails({});
            setPhotoBlob(null);
        }
    }, [rightSideDetails]);

    useEffect(() => {
        if (removeCk) {
            setRightSideDetails(true)
        }
        else if (!removeCk) {
            window.removeCkeditor("editorContent");
            setRightSideDetails(false)
        }
    }, [removeCk]);

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();        
        }
    }, [coachList]);

    const openCropper = () =>{
        setCropperData({
            imageRatio  : { width : 200, height : 200},
            dataHandler : (val) =>  setPhotoBlob(val),
            header : 'Upload Photo'
        })
    }

    const updateCoachDetails = (field, value) => {   
        setCoachDetails((d) => {
            return { ...d, [field]: value };
        });
    };


    const fillDataTable = () => {

        const dataTable = $('#tblCoach').KTDatatable({
            data: {
                saveState: false,
                source: coachList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Name',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.Photo && row.Photo != '') ? `<img src="${row.Photo}" alt="event-thumbail" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Name} </span>
                                </div>
                            </div>`
                        },
                },
                {
                    field: 'Mobile',
                    title: 'Mobile',
                    width: 100,
                },
            ]
        })

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setCoachDetails(dataTable.dataSet[index]);
                setRemoveCk(true)       
            } 
        });
    }

    const fetchCoach = async() => {
        try {
            const response = await getCoachAll()
            isInit ? setIsInit(false) : $('#tblCoach').KTDatatable().destroy(); 
            setCoachList(response);
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }  

    const addCoachHandler = async () => {
        const btn = $('#btnSave');
        var form = $('#add-coach-form');

        const About = window.getEditorValue("#editorContent");

        form.validate({
            rules: {
                txtName: {
                    required: true
                },
                txtMobile: {
                    required: true,
                    number: true,
                    minlength: 10,
                    maxlength: 10,
                },
                txtEmail: {
                    required: true,
                    email: true
                },     
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                },
                txtMobile: {
                    required: "Please enter mobile",
                },
                txtEmail: {
                    required: "Please enter email",
                    email: "Please enter a valid email"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            let coachImage = '';
            if (photoBlob) {
                const res = await uploadPublicFile(photoBlob, 'User');
                coachImage = res.fileName;
            }else{
                if(coachDetails.Photo) coachImage = coachDetails.Photo.split('/User/')[1];
            }
            await postCoach({...coachDetails, Photo: coachImage, About});
            helper.StopProcessing(btn);
            closeDrawer();
            fetchCoach();
            if (coachDetails.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Coach updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            } else {
                swal.fire({
                    icon: 'success',
                    titleText: 'Coach added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRemoveCk(false);
        }, 200);
    }


    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <div className="row align-items-center margin-l10">
                                <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <input type="text" className="form-control clientSearch" placeholder="Search Coach..." id="generalSearch" />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRemoveCk(true)}  type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Coach</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div id="tblCoach" className="kt-datatable table-striped clickable"></div>
                        {rightSideDetails && (
                            <RightSideDetails title="Add Coach" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body">
                                        <form className="kt-form" id="add-coach-form">
                                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input
                                                            id="txtName" 
                                                            name="txtName" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={coachDetails.Name || ''}
                                                            onChange={(event) => updateCoachDetails('Name', event.target.value)}               
                                                        />						
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Email<span className="red">*</span></label>
                                                    <div className="col-xl-4 col-lg-5">
                                                        <input 
                                                            id="txtEmail" 
                                                            name="txtEmail" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={coachDetails.Email || ''}
                                                            onChange={(event) => updateCoachDetails('Email', event.target.value)} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Mobile<span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input
                                                            id="txtMobile" 
                                                            name="txtMobile" 
                                                            type="number" 
                                                            className="form-control"
                                                            value={coachDetails.Mobile || ''}
                                                            onChange={(event) => updateCoachDetails('Mobile', event.target.value)}               
                                                        />						
                                                    </div>
                                                </div> 
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3"> Profile </label>
                                                    <div className="col-sm-9">                                                        
                                                        {(!!photoBlob || coachDetails.Photo) &&(<>
                                                            <div className="kt-avatar kt-avatar--outline kt-avatar--changed">
                                                                <div className="kt-avatar__holder border-none shadow-none" 
                                                                    style={{ backgroundImage : `url( ${photoBlob ? URL.createObjectURL(photoBlob) : coachDetails.Photo && (coachDetails.Photo) })` } }>    
                                                                </div>
                                                            </div>
                                                        </>) }

                                                        <p className="">
                                                            <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={openCropper}>
                                                                {(!!photoBlob || coachDetails.Photo) ? 'Change Photo' : 'Upload Photo'}
                                                            </button>
                                                        </p>
                                                        <p className="">(200 W x 200 H pixels)</p>                                                       
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3"> About </label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <textarea name="editorContent" id="editorContent" />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-lg-9 ml-lg-auto">
                                                    <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addCoachHandler}>Save</button>
                                                    <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>
                        )}
                    </div>
                </div>
            </div>
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
        </>
    )
}

export default Coach;