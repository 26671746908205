import React, { useEffect, useState } from 'react'
import RightSideDetails from "../global/rightSideDetails";
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import Select from 'react-select';
import { deleteMatTimeBlock, getMatBook, getMatMasterAll, postMatTimeBlock } from '../api/matAPI';
import moment from 'moment';

const MatBlocking = (props) => {

    const [selectedDate,            setSelectedDate]             = useState(moment().toDate());
    const [allMaster,               setAllMaster]                = useState([]);
    const [selectedMat,             setSelectedMat]              = useState(null);
    const [bookList,                setBookList]                 = useState([]);
    const [holiday,                 setHoliday]                  = useState(null);
    const [rightSideDetails,        setRightSideDetails]         = useState(false);
    const [slot,                    setSlot]                     = useState([]);
    const [status,                  setStatus]                   = useState({});
    const [serverTime,              setserverTime]               = useState('')

    useEffect(() => {
        props.title("Block Booking", "mat-block");
        $('#txtDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtDate').on('change', function (event) {
            setSelectedDate(event.target.value)
        }); 
        fetchMatMaster();
    }, []);

    useEffect(() => {
        if (selectedDate && selectedMat) {
            fetchMatBook(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedDate, 'DD-MM-YYYY').format('dddd'), selectedMat.ID)
        }
    }, [selectedDate, selectedMat]);

    useEffect(() => {  
        if(rightSideDetails){
            Config.Core.OpenDrawer()
        }else{
            setStatus({})
        }
    }, [rightSideDetails]);

    const updateStatusDetails = (field, value) => {   
        setStatus((d) => {
            return { ...d, [field]: value };
        });
    };


    const updateSlotDetails = (value, checked) => {   
        if(checked){
            setSlot(p =>  [...p, value])
        }else{
            setSlot((a) => {
                const nA = a.filter((b, i) => b !== value);
                return nA;
            })
        }
    };

    
    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    const openDrawer = () =>{
        if(slot.length > 0){
            setRightSideDetails(true);
        }else{
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Please select atleast one slot',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return  
        }
    }

    const fetchMatMaster = async() => {
        try {
            const calender = await getMatMasterAll();
            setSelectedMat(calender[0])
            setAllMaster(calender); 
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    }

    const fetchMatBook = async (selectedDate, dayName, matID) => {
        try {
            const response = await getMatBook({selectedDate, dayName, matID, type : 'matBlock'});
            setBookList(response.booking);
            setHoliday(response.holiday);
            setserverTime(response.serverTimestamp);
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    }

    const deleteSlot = async(id) => {   
        try {
            await deleteMatTimeBlock(id)  
            swal.fire({
                icon: 'success',
                titleText: 'Booking Deleted Successfully!',
                showConfirmButton: false,
                timer: 1500,
            });
            fetchMatBook(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedDate, 'DD-MM-YYYY').format('dddd'), selectedMat.ID)
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

    const addMatTimeBlock = async () => {
        const btn = $('#btnSave')
        var form = $('#ModalHolder');
        
        form.validate({
            rules: {
                txtName: {
                    required: true
                }        
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                }
            }
        });
        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            const books = slot.map((a) =>({
                MatID       : selectedMat.ID,
                TemplateID  : a.TemplateID,
                Status      : status.Status,
                BookDate    : moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD HH:mm:ss'),
            }));
            
            await postMatTimeBlock(books, moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY'))
            helper.StopProcessing(btn);
            swal.fire({
                icon: 'success',
                titleText: 'Booking Submitted Successfully!',
                showConfirmButton: false,
                timer: 1500,
            });

            $('.book-check').prop('checked', false)
            setSlot([]);
            fetchMatBook(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedDate, 'DD-MM-YYYY').format('dddd'), selectedMat.ID)
            setRightSideDetails(false);

        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    return (
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content" >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="row">
                                    <label className="col-form-label col-sm-3 text-right">Date </label>
                                    <div className="form-group col-xl-4 col-sm-3">
                                        <div className="input-group date">
                                            <input 
                                                id="txtDate" 
                                                name="txtDate" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Select date"
                                                defaultValue={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            /> 
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <label className="col-form-label col-sm-1 text-right">Mat </label>
                                    <div className="form-group col-xl-4 col-sm-3">
                                        <Select
                                            onChange={(event) => setSelectedMat(event.value)}
                                            name="ddlSelectUser"
                                            value={(selectedMat && allMaster.length > 0) && {label : selectedMat.Number, value: selectedMat}}
                                            id="ddlSelectUser"
                                            className="ddlSelectUser"
                                            options={allMaster.map(c => ({
                                                label: `${c.Number}`,
                                                value: c
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <table id="table-book" className="table table-bordered table-hover margin-b30 ">
                                <thead className="light-grey-background">
                                    <tr>
                                        {/* <th>Session</th> */}
                                        <th>Slot</th>
                                        <th>Status</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                {bookList && bookList.length > 0 && bookList.map((m, i) => (
                                    <tbody key={i} style={{borderTop: 'none'}}>
                                        <tr >
                                            <td>{m.SlotFrom} To {m.SlotTo}</td>
                                            <td>
                                                <div className={`badge badge-${m.Status == null ? 'success' : 'danger'}`}>
                                                    <span>{m.Status == null ? 'Available' : m.Status}</span>
                                                </div>
                                            </td>
                                            <td className='text-center'>
                                            {m.Status == null ? (
                                                <label className="kt-checkbox kt-checkbox--brand margin-0"> 
                                                    <input 
                                                        type="checkbox" 
                                                        className="book-check" 
                                                        data-templateid="162"
                                                        onChange={(event) => updateSlotDetails(m,  event.target.checked)}
                                                    /> &nbsp;    	
                                                    <span></span>    
                                                </label> 
                                            ) : (
                                                <>
                                                    {moment(serverTime).isBefore(moment(m.BookDate)) && (
                                                        <button 
                                                            type="button" 
                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r15" 
                                                            onClick={() => deleteSlot(m.ID)}
                                                        >     
                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>  
                                                        </button>        
                                                    )}
                                                </>
                                            )}
                                            </td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                            <div className="kt-portlet__body kt-portlet__body--fit">
                                {rightSideDetails && <RightSideDetails title="Block Booking" onCloseDrawer={closeDrawer}>
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body padding-t30 padding-b0">
                                            <form id="ModalHolder" className="kt-form kt-form--label-right padding-10">
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Date</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="lblDate" 
                                                            disabled="disabled" 
                                                            name="lblDate" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                                        />
                                                    </div>
                                                </div>
                                                <div id="pnlSlot" className="form-group row">
                                                    <label className="col-form-label col-lg-3">Slots</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="lblSlot" 
                                                            disabled="disabled" 
                                                            name="lblSlot" 
                                                            type="text" 
                                                            className="form-control" 
                                                            value={slot.map(a => ' '+ a.SlotFrom + ' To ' + a.SlotTo)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Mat</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="lblNumber" 
                                                            name="lblNumber" 
                                                            disabled="disabled" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={selectedMat.Number}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtName" 
                                                            name="txtName" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={status.Status || ''}
                                                            onChange={(event) => updateStatusDetails('Status', event.target.value)} 
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="kt-portlet__foot">
                                            <div className="kt-form__actions">
                                                <div className="row">
                                                    <div className="col-lg-9 ml-lg-auto">
                                                        <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addMatTimeBlock}>Save Booking</button>
                                                        <button id="btnCancel" type="button" className="btn btn-secondary margin-l10"  onClick={closeDrawer}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </RightSideDetails>}
                            </div>
                        </div>
                        <div className="kt-portlet__foot">
                            <div className="kt-form__actions">
                                <div className="row">
                                    <div className="col-lg-8 ml-lg-auto">
                                        <button id="btnBook" type="button" className="btn btn-brand d-inline-block" onClick={() => openDrawer()}>Block Tee Time</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MatBlocking