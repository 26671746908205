import { useState, useEffect } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import '../../assets/scripts/jquery-validation.init';
import { deletePackageTime, deleteResources, getDays, getLessonByPackageID, getPackageAll, getPackageTimeByPackageID, getResources, getSubLessonByLessonID, postLesson, postPackage, postPackageTime, postSubLesson } from "../api/academyAPI";
import Loading from "../global/loading";
import NoData from "../global/noData";
import AddResources from "./AddResources";

const Lesson = (props) => {

    const [isInit,              setIsInit]              = useState(true);
    const [rightSideDetails,    setRightSideDetails]    = useState(false);
    const [packageList,         setPackageList]         = useState([]);
    const [selectedPackage,     setSelectedPackage]     = useState(null);
    const [lessonList,          setLessonList]          = useState([]);
    const [subLessonList,       setSubLessonList]       = useState([]);
    const [lessonDetails,       setLessonDetails]       = useState({Active: 'Y'});
    const [subLessonDetails,    setSubLessonDetails]    = useState({Active: 'Y'});
    const [selectedLesson,      setSelectedLesson]      = useState(null);
    const [editSubLesson,       setEditSubLesson]       = useState(false);
    const [editLesson,          setEditLesson]          = useState(false);
    const [loading,             setLoading]             = useState(false);
    const [isExpanded,          setIsExpanded]          = useState(null);
    const [navState,            setNavState]            = useState('');
    const [days,                setDays]                = useState([]);
    const [packageTime,         setPackageTime]         = useState([]);
    const [packageDetails,      setPackageDetails]      = useState({});
    const [editPackageTime,     setEditPackageTime]     = useState(false);
    const [outerNavState,       setOuterNavState]       = useState('All Packages');
    const [packageTimeLoading,  setPackageTimeLoading]  = useState(false);
    const [resourcesLoading,    setResourcesLoading]    = useState(false);
    const [addResources,        setAddResources]        = useState(false);
    const [resourcesList,       setResourcesList]       = useState([]);
    const [refreshResources,    setRefreshResources]    = useState(false);

    useEffect(() => {
        props.title("Lesson", "lesson")
        fetchDays();
    }, []);

    useEffect(() => {
        if (outerNavState) {
            fetchPackage();
        }
    }, [outerNavState])

    useEffect(() => {
        if (navState === 'Lessons') {
            fetchLesson(selectedPackage.ID);
            setPackageDetails({})
            setEditPackageTime(false)
        } else if (navState === 'Package Time') {
            $('.startTime').timepicker({ minuteStep: 10, defaultTime: '05:00 AM'});
            $('.startTime').on('change', ({target}) => updatePackageDetails("Time", target.value) );
            setLessonDetails({Active: 'Y'})
        } else if (navState === 'Resources') {
            fetchResources()
        }
    }, [navState, refreshResources])

    useEffect(() => {
        if(rightSideDetails){
            Config.Core.OpenDrawer()
        }
    }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();        
        }
    }, [packageList]);

    useEffect(() => {
        if (selectedPackage) {
            // fetchLesson(selectedPackage.ID)
            setNavState('Lessons')
        } else 
            setNavState('')
    }, [selectedPackage]);

    useEffect(() => {
        if (isExpanded && isExpanded.id && isExpanded.expand == 'true') {
            fetchSubLesson(isExpanded.id)
        } else {
            setEditSubLesson(false)
            setSubLessonDetails({Active: 'Y'})
        }
    }, [isExpanded]);

    function fillDataTable() {

        const dataTable = $('#tblPackage').KTDatatable({
            data: {
                saveState: false,
                source: packageList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Title',
                    title: 'Title',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.BannerImage && row.BannerImage != '') ? `<img src="${row.BannerImage}" height="40" width="40" alt="banner-image" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Title.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Title} </span>
                                    <span class="kt-user-card-v2__desc text-clip"> ${row.Type} </span>
                                </div>
                            </div>`
                    }
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        if (row.Active == 'Y') 
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                               
                        else
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">No</span>'
                    },
                    width: 50,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblPackage .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setSelectedPackage(dataTable.dataSet[index]);
                setRightSideDetails(true); 
            }    
        });
    }

    const fetchPackage = async() => {
        try {
            const response = await getPackageAll()
            isInit ? setIsInit(false) : $('#tblPackage').KTDatatable().destroy();
            outerNavState == 'All Packages' ? setPackageList(response) : outerNavState == 'Individual' ? setPackageList([...response.filter(f => f.Type == 'Individual')]) : setPackageList([...response.filter(f => f.Type == 'Group')]);
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchDays = async() => {
        try {
            const response = await getDays()
            setDays(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchPackageTime = async(packageID, dayID) => {
        try {
            setPackageTimeLoading(true)
            const response = await getPackageTimeByPackageID(packageID, dayID)
            setPackageTime(response);
            setPackageTimeLoading(false)
        } catch (error) {
            setPackageTimeLoading(false)
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchResources = async() => {
        try {
            setResourcesLoading(true)
            const response = await getResources(selectedPackage.ID)
            setResourcesList(response);
            setResourcesLoading(false)
        } catch (error) {
            setResourcesLoading(false)
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const updatePackageDetails = (field, value) => {   
        setPackageDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const updateLessonDetails = (field, value) => {   
        setLessonDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const updateSubLessonDetails = (field, value) => {   
        setSubLessonDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const closeDrawer = () => {
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
            setLessonDetails({ Active: 'Y' })
            setSelectedPackage(null);
        }, 200);
    }

    const fetchLesson = async(id) => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();

                }
            });
            const response = await getLessonByPackageID(id)
            setLessonList(response);    
            swal.close()
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchSubLesson = async(id) => {
        try {
            setLoading(true)
            const response = await getSubLessonByLessonID(id)
            setIsExpanded(null)
            setSubLessonList(response);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const AddSubLessonHandler = async () => {
        const btn = $('#add-sublesson-btn');
        if (!subLessonDetails.Title || subLessonDetails.Title == '') {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : "Please enter title",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return;
        } else if (!subLessonDetails.Index || subLessonDetails.Index == '') {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : "Please enter index",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return;
        }

        helper.StartProcessing(btn);
        try {
            await postSubLesson({...subLessonDetails, LessonID: selectedLesson})
            setSubLessonDetails({ Active: 'Y' })
            fetchSubLesson(selectedLesson)
            if (subLessonDetails.ID) {
                setEditSubLesson(false)
            }
            helper.StopProcessing(btn);
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: 'SubLesson added successfully!',
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,                                       
            });
        }catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const AddLessonHandler = async () => {
        const btn = $('#add-btn');
        var form = $('#add-lesson-form');
        form.validate({
            rules: {
                txtTitle: {
                    required: true
                },
                txtIndex: {
                    required: true,
                    number: true
                }           
            },
            messages: {
                txtName: {
                    required: "Please enter title"
                },
                txtIndex: {
                    required: "Please enter index"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);
        try {
            await postLesson({...lessonDetails, PackageID: selectedPackage.ID})
            setLessonDetails({ Active: 'Y' })
            fetchLesson(selectedPackage.ID)
            if (lessonDetails.ID)
                setEditLesson(false)
            helper.StopProcessing(btn);
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: 'Lesson added successfully!',
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,                                       
            });
        }catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const openFile = async (d) => {
        const ext = helper.getFileExt(d.FileName)
        const name = (d.FileName + '.' + ext).replace(/ /g, '_');
        helper.downloadAndOpenFileHandler('Resources', d.FileName, name);
    }

    const cardClick = (id, index) => {
        const expandedID = document.getElementById(index);
        const expand = expandedID.getAttribute('aria-expanded');
        const cardDetail = {expand, id}
        setIsExpanded(cardDetail)
        setSelectedLesson(id)
    }

    const editSubLessonHandler = (sublesson) => {
        setSubLessonDetails(sublesson);
        setEditSubLesson(true)
    }

    const editLessonHandler = (lesson, index) => {
        setLessonDetails(lesson);
        setEditLesson(true)
    }

    const AddPackageTimeHandler = async (dayID) => {

        const btn = $('#add-packageTime-btn');
        if (!packageDetails.Time) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : "Please select time",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return;
        }

        helper.StartProcessing(btn);

        try {
            await postPackageTime({...packageDetails, DayID: dayID, PackageID: selectedPackage.ID});
            setPackageDetails({})
            if (packageDetails.ID) {
                setEditPackageTime(false)
            }
            fetchPackageTime(selectedPackage.ID, dayID)
            helper.StopProcessing(btn);
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: `Package time ${packageDetails.ID ? 'update' : 'add'} successfully!`,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,                                       
            });
        }catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const cardTimeClick = () => {
        setPackageDetails({})
    }

    const editPackageTimeHandler = (time) => {
        setPackageDetails(time);
        setEditPackageTime(true)
    }

    const removePackageTimeHandler = async (id, dayID) => {
        try {
            await deletePackageTime(id);
            fetchPackageTime(selectedPackage.ID, dayID)
            swal.fire({
                toast             : true,
                icon              : 'success',
                titleText         : 'time deleted successfully!',
                position          : 'bottom-end',
                showConfirmButton : false,
                timer             : 1500,
                animation         : false,
                customClass       : {
                    popup: 'margin-20',
                },
            });
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
            return;
        }
    }

    const deleteDocumentHandler = async (d) => {
        const swalResult = await swal.fire({
            title               : 'Are you sure?',
            text                : "You won't be able to revert this!",
            icon                : 'warning',
            showCancelButton    : true,
            buttonsStyling      : false,
            reverseButtons      : true,
            showLoaderOnConfirm : true,
            confirmButtonClass  : 'btn btn-brand',
            cancelButtonClass   : 'btn btn-secondary',
            confirmButtonText   : 'Delete',
            preConfirm: async () => {
              try {
                    await deleteResources(d.ID);
                } catch (err) {
                    swal.fire({
                        icon                : 'error',
                        titleText           : 'Error!',
                        text                : err.message,
                        buttonsStyling      : false,
                        confirmButtonClass  : 'btn btn-brand',
                    });
                    return;
                }
            },
        });
        if (!swalResult.value) return;
        setResourcesList((a) => {
            const nA = a.filter((b) => b.ID != d.ID)
            return nA
        });
        swal.fire({
            icon              : 'success',
            titleText         : 'Resources Deleted Successfully',
            showConfirmButton : false,
            timer             : 1500
        });
    }

    const refetchResources = () => setRefreshResources(r => !r)

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <nav className="file-nav navbar navbar-top">
                                <a className={outerNavState === "All Packages" ? "top-nav-active" : ""} onClick={() => setOuterNavState("All Packages")}>  
                                    All Packages 
                                </a>
                                <a className={outerNavState === "Individual" ? "top-nav-active" : "" } onClick={() => setOuterNavState("Individual")}  >  
                                    Individual
                                </a>
                                <a className={outerNavState === "Group" ? "top-nav-active" : "" } onClick={() => setOuterNavState("Group")}  >  
                                    Group
                                </a>
                            </nav>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="row align-items-center margin-l10">
                                <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <input type="text" className="form-control clientSearch" placeholder="Search Package..." id="generalSearch" />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div id="tblPackage" className="kt-datatable table-striped clickable"></div>
                        {rightSideDetails && (
                            <RightSideDetails title="Package Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body">
                                        <div className="kt-widget kt-widget--user-profile-3">
                                            <div className="kt-widget__top">
                                                {selectedPackage.BannerImage ? (

                                                    <div className="kt-widget__pic kt-widget__pic--brand">
                                                        <div className="kt-media kt-media--circle">
                                                            <img src={selectedPackage.BannerImage} alt="image"/>
                                                        </div>
                                                    </div>
                                                ) : (

                                                    <div className="kt-widget__pic kt-widget__pic--brand kt-font-brand kt-font-boldest">
                                                        {selectedPackage.Title && selectedPackage.Title.slice(0,1)}
                                                    </div>
                                                )}
                                                <div className="kt-widget__content">
                                                    <div className="row align-items-center">
                                                        <div className="col-5">
                                                            <div className="kt-widget__head">
                                                                <span className="kt-widget__username">
                                                                    {selectedPackage.Title}
                                                                </span>
                                                            </div>
                                                            <div className="kt-widget__subhead padding-t0">
                                                                <span className="padding-r10">
                                                                    <i className="flaticon2-calendar-3"></i>
                                                                    {selectedPackage.Type}
                                                                </span>
                                                                {selectedPackage.Type === 'Group' && (
                                                                    <span className="padding-r10">
                                                                        <i className="flaticon-users"></i>
                                                                        Group Strength : {selectedPackage.GroupSize}
                                                                    </span>
                                                                )}
                                                                <br/>
                                                                {selectedPackage.Type === 'Individual' && (
                                                                    <span className="padding-r10">
                                                                        <i className="flaticon-calendar-1"></i>
                                                                        Duration (in days) : {selectedPackage.Duration}
                                                                    </span>
                                                                )}
                                                                {selectedPackage.Type === 'Group' && (
                                                                    <span className="padding-r10">
                                                                        <i className="flaticon-calendar-1"></i>
                                                                        Duration : {moment(selectedPackage.FromDate).format('Do MMM YYYY')} - {moment(selectedPackage.ToDate).format('Do MMM YYYY')}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet kt-prtlet--mobile">
                                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                                        <div className="kt-portlet__head-label">
                                            <nav className="file-nav navbar navbar-top">
                                                <a className={navState === 'Lessons' ? 'top-nav-active' : ''}
                                                    onClick={() => setNavState('Lessons')}>
                                                    Lesson
                                                </a>
                                                <a className={navState === 'Package Time' ? 'top-nav-active' : ''}
                                                    onClick={() => setNavState('Package Time')}>
                                                    Package Time
                                                </a>
                                                <a className={navState === 'Resources' ? 'top-nav-active' : ''}
                                                    onClick={() => setNavState('Resources')}>
                                                    Resources
                                                </a>
                                            </nav>
                                        </div>
                                        <div className="kt-portlet__head-toolbar">
                                            <div className="kt-portlet__head-wrapper">
                                                <div className="kt-portlet__head-actions">
                                                    {navState === 'Resources' && (
                                                        <button className="btn btn-label-brand btn-block margin-0"
                                                            onClick={(e) => {e.preventDefault(), setAddResources(true)}}
                                                        >
                                                            <i className="la la-plus"></i>
                                                            Add Resource
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__body padding-30">
                                        {navState === 'Lessons' && (
                                            <>
                                                <form className="kt-form kt-form--label-right" id="add-lesson-form" onSubmit={e => e.preventDefault()}>
                                                    <div className="form-group row">
                                                        <div className="col-7">
                                                            <label>Lesson Name</label>
                                                            <input
                                                                id="txtTitle"
                                                                name="txtTitle"
                                                                type='text'
                                                                className = "form-control"
                                                                value={lessonDetails.Title || ''}
                                                                onChange ={({target}) => updateLessonDetails('Title', target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-2">
                                                            <label> Index </label>
                                                            <input
                                                                id="txtIndex"
                                                                name="txtIndex"
                                                                type='number'
                                                                className="form-control"
                                                                value={lessonDetails.Index || ''}
                                                                onChange={(event) => updateLessonDetails('Index', event.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-1">
                                                            <label> Active </label>
                                                            <div className="d-flex align-item-center">
                                                                <span className="kt-switch kt-switch--icon">
                                                                    <label>
                                                                        <input
                                                                            type="checkbox" name=""
                                                                            checked = { lessonDetails.Active === "Y" ? "checked" : ""}
                                                                            onChange = {() => {
                                                                                lessonDetails.Active === "Y"
                                                                                ? updateLessonDetails("Active", "N")
                                                                                : updateLessonDetails("Active", "Y");
                                                                            }}
                                                                        />
                                                                        <span>{}</span>
                                                                    </label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className='col-2 pt-4'>
                                                            {editLesson ? (
                                                                <div className="btn-group btn-block" role="group">

                                                                    <button
                                                                        id="add-btn"
                                                                        className="btn btn-brand btn-block margin-0 margin-t10"
                                                                        style={{ maxWidth: '70%' }}
                                                                        onClick={AddLessonHandler}
                                                                    >
                                                                        Update
                                                                    </button>
                                                                    <button
                                                                        style={{ maxWidth: '30%' }}
                                                                        className="btn btn-secondary btn-block margin-0 margin-t10 padding-0"
                                                                        onClick={() => {
                                                                            setLessonDetails({Active: "Y"});
                                                                            setEditLesson(false)
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="la la-times"
                                                                            style={{ fontSize: '1rem', marginLeft: '3px' }}
                                                                        />
                                                                    </button>
                                                                </div>
                                                            ) : (

                                                                <button className="btn btn-brand btn-sm margin-t10 margin-l0" id='add-btn' onClick={AddLessonHandler} >
                                                                    <i className="la la-plus"></i>
                                                                    Add
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="accordion accordion-toggle-arrow" id="accordionExample4">
                                                    {(lessonList && lessonList.length > 0) ? (
                                                        lessonList.map((m, i) => (
                                                            <div className="card" key={i} >
                                                                <div className="card-header" id="menuHeading" onClick={() => cardClick(m.ID, i)}>
                                                                    <div className="card-title collapsed" id={i}  data-toggle="collapse" data-target={`#${helper.SanitizeUrl(m.Title)}`} aria-expanded="false" aria-controls={helper.SanitizeUrl(m.Title)}>
                                                                        <div className="col-10">
                                                                            <span>{i + 1}.</span>
                                                                            <span className="margin-l10">{m.Title}</span>
                                                                        </div>
                                                                        <div className="col-1">
                                                                            <span className={`kt-badge badge-active kt-badge--${m.Active == 'Y' ? 'success' : 'grey'} kt-badge--pill  padding-lr20 padding-tb10`}>{m.Active == 'Y' ? 'Active' : 'Inactive'}</span>
                                                                        </div>
                                                                        <div className="col-1">
                                                                            <button 
                                                                                type="button"
                                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r50"
                                                                                onClick={() => editLessonHandler(m, i)}
                                                                            >  
                                                                                <i className="flaticon2-edit"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id={helper.SanitizeUrl(m.Title)} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample4" style={{}}>
                                                                    <div className="card-body">
                                                                        <div className="kt-widget4">
                                                                            <form className="kt-form kt-form--label-right" id="add-subLesson-form" onSubmit={e => e.preventDefault()}>
                                                                                <div className="form-group row">
                                                                                    <div className="col-7">
                                                                                        <label>Sub Lesson Name</label>
                                                                                        <input
                                                                                            id="txtTitle"
                                                                                            name="txtTitle"
                                                                                            type='text'
                                                                                            className = "form-control"
                                                                                            value={subLessonDetails.Title || ''}
                                                                                            onChange ={({target}) => updateSubLessonDetails('Title', target.value)}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <label> Index </label>
                                                                                        <input
                                                                                            id="txtIndex"
                                                                                            name="txtIndex"
                                                                                            type='number'
                                                                                            className="form-control"
                                                                                            value={subLessonDetails.Index || ''}
                                                                                            onChange={(event) => updateSubLessonDetails('Index', event.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-1">
                                                                                        <label> Active </label>
                                                                                        <div className="d-flex align-item-center">
                                                                                            <span className="kt-switch kt-switch--icon">
                                                                                                <label>
                                                                                                    <input
                                                                                                        type="checkbox" name=""
                                                                                                        checked = { subLessonDetails.Active === "Y" ? "checked" : ""}
                                                                                                        onChange = {() => {
                                                                                                            subLessonDetails.Active === "Y"
                                                                                                            ? updateSubLessonDetails("Active", "N")
                                                                                                            : updateSubLessonDetails("Active", "Y");
                                                                                                        }}
                                                                                                    />
                                                                                                    <span>{}</span>
                                                                                                </label>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-2 pt-4'>
                                                                                        {editSubLesson ? (
                                                                                            <div className="btn-group btn-block" role="group">
                                                                                                <button
                                                                                                    id="add-sublesson-btn"
                                                                                                    className="btn btn-brand btn-block margin-0 margin-t10"
                                                                                                    style={{ maxWidth: '70%' }}
                                                                                                    onClick={AddSubLessonHandler}
                                                                                                >
                                                                                                    Update
                                                                                                </button>
                                                                                                <button
                                                                                                    style={{ maxWidth: '30%' }}
                                                                                                    className="btn btn-secondary btn-block margin-0 margin-t10 padding-0"
                                                                                                    onClick={() => {
                                                                                                        setSubLessonDetails({Active: "Y"});
                                                                                                        setEditSubLesson(false)
                                                                                                    }}
                                                                                                >
                                                                                                    <i
                                                                                                        className="la la-times"
                                                                                                        style={{ fontSize: '1rem', marginLeft: '3px' }}
                                                                                                    />
                                                                                                </button>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <button className="btn btn-brand btn-sm margin-t10 margin-l0" id='add-sublesson-btn' onClick={AddSubLessonHandler} >
                                                                                                <i className="la la-plus"></i>
                                                                                                Add
                                                                                            </button>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                            {loading ? <Loading/> : (
                                                                                subLessonList && subLessonList.length > 0) && subLessonList.map((s, i) => (
                                                                                    <div className="kt-widget4__item padding-tb5" key={i}>
                                                                                        <div className="col-10">
                                                                                            <div className="kt-widget4__info margin-t10 margin-b10">
                                                                                                <span></span>
                                                                                                <span className="kt-widget4__username">{i + 1}. {s.Title}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-1">
                                                                                            <span className={`kt-badge badge-active margin-r50 kt-badge--${s.Active == 'Y' ? 'success' : 'grey'} kt-badge--pill  padding-lr20 padding-tb10`}>{s.Active == 'Y' ? 'Active' : 'Inactive'}</span>
                                                                                        </div>
                                                                                        <div className="col-1">
                                                                                            <button 
                                                                                                type="button"
                                                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r50"                
                                                                                                onClick={() => editSubLessonHandler(s)}
                                                                                            >  
                                                                                                <i className="flaticon2-edit"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <NoData
                                                            message='No Lesson Found'
                                                        />
                                                    )}
                                                </div>
                                            </>
                                        )}
                                        {navState === 'Package Time' && (
                                            <>
                                                <form className="kt-form kt-form--label-right" id="add-packageTime-form" onSubmit={e => e.preventDefault()}>
                                                    <div className="accordion accordion-toggle-arrow" id="accordionExample4">
                                                            {(days && days.length > 0) && (
                                                                days.map((m, i) => (
                                                                    <div className="card" key={i}>
                                                                        <div className="card-header" id="menuHeading" onClick={() => fetchPackageTime(selectedPackage.ID, m.ID)}>
                                                                            <div className="card-title collapsed" id={i}  data-toggle="collapse" data-target={`#${helper.SanitizeUrl(m.Name)}`} onClick={cardTimeClick} aria-expanded="false" aria-controls={helper.SanitizeUrl(m.Name)}>
                                                                                <div className="col-12">
                                                                                    <span>{m.Name}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div id={helper.SanitizeUrl(m.Name)} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample4" style={{}}>
                                                                            <div className="card-body">
                                                                                <div className="kt-widget4">
                                                                                    <div className="form-group row">
                                                                                        <div className="col-4">
                                                                                            <label className="col-form-label text-right width-50 padding-t0 padding-b6"> Start Time </label>
                                                                                            <div className="input-group timepicker width-200">
                                                                                                <input id={`txtStartTime${i}`} name="txtStartTime" type="text" className="form-control startTime" 
                                                                                                    readOnly={true} placeholder="Select time" 
                                                                                                    value={packageDetails.Time || ''}                                               
                                                                                                />
                                                                                                <div className="input-group-append">
                                                                                                    <span className="input-group-text"> <i className="la la-clock-o"></i> </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-3 mt-4'>
                                                                                            {editPackageTime ? (
                                                                                                <div className="btn-group btn-block" role="group">

                                                                                                    <button
                                                                                                        id="add-packageTime-btn"
                                                                                                        className="btn btn-brand btn-block margin-0 margin-t5"
                                                                                                        style={{ maxWidth: '70%' }}
                                                                                                        onClick={() => AddPackageTimeHandler(m.ID)}
                                                                                                    >
                                                                                                        Update
                                                                                                    </button>
                                                                                                    <button
                                                                                                        style={{ maxWidth: '30%' }}
                                                                                                        className="btn btn-secondary btn-block margin-0 margin-t5 padding-0"
                                                                                                        onClick={() => {
                                                                                                            setPackageDetails({});
                                                                                                            setEditPackageTime(false);
                                                                                                        }}
                                                                                                    >
                                                                                                        <i
                                                                                                            className="la la-times"
                                                                                                            style={{ fontSize: '1rem', marginLeft: '3px' }}
                                                                                                        />
                                                                                                    </button>
                                                                                                </div>
                                                                                            ) : (

                                                                                                <button className="btn btn-brand btn-sm margin-t10 margin-l0" id='add-packageTime-btn' onClick={() => AddPackageTimeHandler(m.ID)} >
                                                                                                    <i className="la la-plus"></i>
                                                                                                    Add
                                                                                                </button>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {packageTimeLoading ? (<Loading/>) : (
                                                                                    packageTime.length > 0 ? (
                                                                                        <div className="row">
                                                                                            <div className="col-sm-12">
                                                                                                <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                                                                                    <table className="kt-datatable__table">
                                                                                                        <thead
                                                                                                            className="kt-datatable__head"
                                                                                                            style={{ display: 'block' }}>
                                                                                                             <tr className="kt-datatable__row block-row">
                                                                                                                <th className="kt-datatable__cell text-clip" width="50%">
                                                                                                                    <span>Time</span>
                                                                                                                </th>
                                                                                                                <th className="kt-datatable__cell text-clip text-right" width="50%">
                                                                                                                    <span className="margin-r20">Action</span>
                                                                                                                </th>
                                                                                                             </tr>
                                                                                                        </thead>
                                                                                                    </table>
                                                                                                </div>
                                                                                                <div 
                                                                                                    className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                                                                                                    <table className="kt-datatable__table table-striped">
                                                                                                        <tbody
                                                                                                            className="kt-datatable__body">
                                                                                                            {packageTime.map((p, i) => (
                                                                                                                <tr
                                                                                                                    key={i}
                                                                                                                    className="kt-datatable__row block-row clickable">

                                                                                                                    <td className="kt-datatable__cell text-clip" width="50%">
                                                                                                                        <span>{p.Time}</span>
                                                                                                                    </td>

                                                                                                                    <td className="kt-datatable__cell text-clip text-right" width="50%">
                                                                                                                        <button 
                                                                                                                            type="button"
                                                                                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                                                                                        
                                                                                                                            onClick={() => editPackageTimeHandler(p)}>  
                                                                                                                            <i className="flaticon2-edit"></i>
                                                                                                                        </button>
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            onClick={() => removePackageTimeHandler(p.ID, p.DayID)}
                                                                                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                                                                                            
                                                                                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                                                                        </button>
                                                                                                                    </td>

                                                                                                                </tr>
                                                                                                            ))}
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <></>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            )}
                                                    </div>
                                                </form>
                                            </>
                                        )}
                                        {navState === 'Resources' && (
                                            resourcesLoading ? (<Loading/>) : (
                                                resourcesList.length > 0 ? (
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                                                <table className="kt-datatable__table">
                                                                    <thead
                                                                        className="kt-datatable__head"
                                                                        style={{ display: 'block' }}>
                                                                            <tr className="kt-datatable__row block-row">
                                                                                <th className="kt-datatable__cell text-clip" width="50%">
                                                                                    <span>Title</span>
                                                                                </th>
                                                                                <th className="kt-datatable__cell text-clip" width="20%">
                                                                                    <span>Access Type</span>
                                                                                </th>
                                                                                <th className="kt-datatable__cell text-clip text-right" width="30%">
                                                                                    <span>Action</span>
                                                                                </th>
                                                                            </tr>
                                                                    </thead>
                                                                </table>
                                                            </div>
                                                            <div 
                                                                className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                                                                <table className="kt-datatable__table table-striped">
                                                                    <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                                                                        {resourcesList.map((f, i) => {
                                                                            return(
                                                                                <tr key={f.ID} className="kt-datatable__row block-row">
                                                                                    <td title={f.Title} className="kt-datatable__cell" width='50%'>
                                                                                        <div className="kt-notification text-clip">
                                                                                            <a className="kt-notification__item padding-0" onClick={() => openFile(f)}>
                                                                                                <div className="kt-notification__item-icon margin-r10">
                                                                                                    <img height="30px" width="30px" src={helper.getFileLogo(f.FileName)} />
                                                                                                </div>
                                                                                                <div className="kt-notification__item-details text-clip">
                                                                                                    <div className="kt-notification__item-title kt-font-bold text-clip">
                                                                                                        {f.Title}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </a>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td title={f.Type} className="kt-datatable__cell" width='20%'>
                                                                                        <span>{f.Type}</span>
                                                                                    </td>
                                                                                    <td className="kt-datatable__cell text-clip text-right" width="30%">
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={() => deleteDocumentHandler(f)}
                                                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                                                            
                                                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            )
                                        )}
                                    </div>
                                </div>
                            </RightSideDetails>
                        )}
                    </div>
                </div>
            </div>
            {addResources && (
                <AddResources
                    onDismissModal={setAddResources}
                    PackageID={selectedPackage.ID}
                    show={addResources}
                    type={selectedPackage.Type}
                    refetchResources={() => refetchResources()}
                />
            )}
        </>
    )
}

export default Lesson;