import { useState } from "react";
import { useEffect } from "react";
import swal from 'sweetalert2';
import Select from 'react-select';
import { deleteTeeTimeBlock, getShotgunBook, getTeeTimeBook, postTeeTimeBlock, verifyDate } from "../api/teeTimeAPI";
import RightSideDetails from "../global/rightSideDetails";
import * as helper from '../global/helper';

const TeeTimeBlocking = (props) => {

    const [TeeBoxOption,            setTeeBoxOption]             = useState([{ value:  '1', label: '1'}, { value:  '4', label: '4'}]);
    const [blockData,               setBlockData]                = useState([]);
    const [selectedDate,            setSelectedDate]             = useState(moment().toDate());
    const [teeTimeBook,             setTeeTimeBook]              = useState([]);
    const [shotgunBook,             setShotgunBook]              = useState([]);
    const [type,                    setType]                     = useState({'TeeBox': TeeBoxOption[0].label});
    const [slot,                    setSlot]                     = useState([]);
    const [rightSideDetails,        setRightSideDetails]         = useState(false);
    const [status,                  setStatus]                   = useState({});


    useEffect(() => {
        props.title("Tee Time Blocking", "tee-time-blocking")    
        $('#txtDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtDate').on('change', function (event) {
            setSelectedDate(event.target.value)
        }); 
    },[])

    useEffect(() =>{
        if(rightSideDetails){
            Config.Core.OpenDrawer()      
        }else{
            setStatus({})
        }
    }, [rightSideDetails])

    useEffect(() => {
        if(selectedDate){
           fetchVerifyDate(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        }
    },[selectedDate])

    useEffect(() => {
        if(type.Type == "Shotgun"){
            fetchShotgunBookData(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), type.Block)
        }else{
            fetchTeeTimeBookData(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), type.TeeBox)

        }
    },[type])

    const updateTypeDetails = (field, value) => { 
        $('.book-check').prop('checked', false)
        setSlot([])  
        setType((d) => {
            return { ...d, [field]: value };
        });
    };
    
    const updateSlotDetails = (value, checked) => {   
        if(checked){
            setSlot(p =>  [...p, value])
        }else{
            setSlot((a) => {
                const nA = a.filter((b, i) => b !== value);
                return nA;
            })
        }
    };

    const deleteSlot = async(id) => {   
        try {
            await deleteTeeTimeBlock(id)  
            swal.fire({
                icon: 'success',
                titleText: 'Booking Deleted Successfully!',
                showConfirmButton: false,
                timer: 1500,
            });
            fetchTeeTimeBookData(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), type.TeeBox)
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

    const updateStatusDetails = (field, value) => {   
        setStatus((d) => {
            return { ...d, [field]: value  };
        });
    };

    const addTeeTimeBlock = async() => {
        const btn = $('#btnSave')
        var form = $('#ModalHolder');
        
        form.validate({
            rules: {
                txtName: {
                    required: true
                }        
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                }
            }
        });
        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            const books = slot.map((a) =>({
                TemplateID  : a.TemplateID,
                Status      : status.Status,
                BookDate    : moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD HH:mm:ss'),
            }))

            await postTeeTimeBlock(books, moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY'));

            helper.StopProcessing(btn);
            swal.fire({
                icon: 'success',
                titleText: 'Booking Submitted Successfully!',
                showConfirmButton: false,
                timer: 1500,
            });

            $('.book-check').prop('checked', false)
            setSlot([])  
            fetchTeeTimeBookData(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), type.TeeBox)
            setRightSideDetails(false);

        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const fetchVerifyDate = async(date) => {
        try {
            const response = await verifyDate(date);
            updateTypeDetails('Type', response.type)
            if(response.type == "Shotgun"){
                const ddlBlock = response.shotgunBlocks.map(b => ({ value: b.ShotgunBlockID, label: b.ShotgunBlockID }));
                updateTypeDetails('Block', ddlBlock[0].label)
                setBlockData(ddlBlock);
            }           
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    const fetchShotgunBookData = async(date, blockID) => {
        try {
            const response = await getShotgunBook(date, blockID);
            setShotgunBook(response)
          
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }
    const fetchTeeTimeBookData = async(date, teeBox) => {
        try {
            const response = await getTeeTimeBook(date, teeBox);
            setTeeTimeBook(response)
         
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    const openDrawer = () =>{
        if(slot.length > 0){
            setRightSideDetails(true);
        }else{
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Please select atleast one slot',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return  
        }
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }
    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content" >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="row">
                                    <label className="col-form-label col-sm-3 text-right">Date </label>
                                    <div className="form-group col-xl-4 col-sm-3">
                                        <div className="input-group date">
                                            <input 
                                                id="txtDate" 
                                                name="txtDate" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Select date"
                                                defaultValue={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            /> 
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {type.Type == 'Shotgun' ? (
                                        <>
                                            <label  className="col-form-label col-sm-1 text-right pnlShotgun ">Block</label>
                                            <div className="form-group col-sm-2 pnlShotgun ">
                                                <div>
                                                    <Select 
                                                        id="ddlBlock" 
                                                        name="ddlBlock "
                                                        className="multi-custom "
                                                        defaultValue={ blockData.find((m) => m.value[0] )  || ''}
                                                        onChange={(event) => updateTypeDetails('Block', event.value)}
                                                        options={blockData}

                                                    />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {/* <label className="col-form-label col-sm-1 text-right pnlSlot ">Tee Box </label>
                                            <div  className="form-group col-sm-2 pnlSlot ">
                                                <div>
                                                    <Select 
                                                        id="ddlTeeBox" 
                                                        name="ddlTeeBox "
                                                        className="multi-custom "
                                                        defaultValue={ TeeBoxOption.find((m) => m.value[0] )  || ''}
                                                        onChange={(event) => updateTypeDetails('TeeBox', event.value)}
                                                        options={TeeBoxOption}

                                                    />
                                                </div>
                                            </div> */}
                                        </>
                                    )}
                                    
                                </div>
                            </div>

                            <h5 id="lblHoliday" className="lblHoliday"></h5>
                            {type.Type == 'Shotgun' ? (
                                <table id="table-book-shotgun" className="table table-bordered table-hover margin-b30 ">
                                    <thead className="light-grey-background">
                                        <tr>
                                            <th>Hole</th>
                                            <th>Status</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    {shotgunBook.TeeTimeBook && shotgunBook.TeeTimeBook.length > 0 && shotgunBook.TeeTimeBook.map((m, i) => (
                                        <tbody key={i} style={{borderTop: 'none'}}>
                                            <tr >
                                                <td>{m.Hole}</td>
                                                <td>{m.Status == null ? 'Available' : m.Status}</td>
                                                <td className="book-control padding-0">          
                                                    <center>  
                                                        {m.Status == null ? (
                                                            <label className="kt-checkbox kt-checkbox--brand margin-0"> 
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="book-check" 
                                                                    data-templateid="162"
                                                                    onChange={(event) => updateSlotDetails(m,  event.target.checked)}
                                                                /> &nbsp;    	
                                                                <span></span>    
                                                            </label>  

                                                        ) : (
                                                            <>
                                                                {moment(shotgunBook.ServerDate).isBefore(moment(m.BookDate)) && (
                                                                    <button 
                                                                        type="button" 
                                                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r15" 
                                                                        onClick={() => deleteSlot(m.ID)}
                                                                    >     
                                                                        <i className="flaticon2-rubbish-bin-delete-button"></i>  
                                                                    </button>        
                                                                )}
                                                            </>
                                                        )}
                                                    </center>      
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}
                                </table>

                            ):(
                                <table id="table-book" className="table table-bordered table-hover margin-b30 ">
                                    <thead className="light-grey-background">
                                        <tr>
                                            {/* <th>Session</th> */}
                                            <th>Slot</th>
                                            <th>Status</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    {teeTimeBook.BookingList && teeTimeBook.BookingList.length > 0 && teeTimeBook.BookingList.map((m, i) => (
                                        <tbody key={i} style={{borderTop: 'none'}}>
                                            <tr  >
                                                {/* <td>{m.Block}</td> */}
                                                <td>{m.Slot}</td>
                                                <td>
                                                    <div className={`badge badge-${m.Status == null ? 'success' : m.Status == 'Booked' ? 'label-danger' : 'danger'}`}>
                                                    <span>{m.Status == null ? 'Available'  : m.Status == 'Booked' ? 'User Booking' : m.Status}</span>
                                                    </div>
                                                </td>
                                                <td className="book-control padding-0">          
                                                {m.Status !== 'Booked' && 
                                                    <center>  
                                                        {m.Status == null ? (
                                                            <label className="kt-checkbox kt-checkbox--brand margin-0"> 
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="book-check" 
                                                                    data-templateid="162"
                                                                    onChange={(event) => updateSlotDetails(m,  event.target.checked)}
                                                                    // onChange={(event) => updateSlotDetails((' '+ m.Slot),  event.target.checked)}
                                                                /> &nbsp;    	
                                                                <span></span>    
                                                            </label>  

                                                        ) : (
                                                            <>
                                                                {moment(teeTimeBook.ServerDate).isBefore(moment(m.BookDate)) && 
                                                                    <button 
                                                                        type="button" 
                                                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r15" 
                                                                        onClick={() => deleteSlot(m.ID)}

                                                                    >     
                                                                        <i className="flaticon2-rubbish-bin-delete-button"></i>  
                                                                    </button>        
                                                                }
                                                            </>
                                                        )}
                                                    </center>      
                                                }
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}
                                </table>
                            )}
                            <div className="kt-portlet__body kt-portlet__body--fit">
                                <div id="tblTeeTimeBlocking" className="kt-datatable table-striped clickable"></div>
                                {rightSideDetails && <RightSideDetails title="Tee Time Blocking" onCloseDrawer={closeDrawer}>
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body padding-t30 padding-b0">
                                            <form id="ModalHolder" className="kt-form kt-form--label-right padding-10">
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Date</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="lblDate" 
                                                            disabled="disabled" 
                                                            name="lblDate" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                                        />
                                                    </div>
                                                </div>
                                                {type.Type == 'Shotgun' ? (
                                                    <div id="pnlHole" className="form-group row">
                                                        <label className="col-form-label col-lg-3">Holes</label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <input 
                                                                id="lblHole" 
                                                                disabled="disabled" 
                                                                name="lblHole" 
                                                                type="text" 
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div id="pnlSlot" className="form-group row">
                                                        <label className="col-form-label col-lg-3">Slots</label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <input 
                                                                id="lblSlot" 
                                                                disabled="disabled" 
                                                                name="lblSlot" 
                                                                type="text" 
                                                                className="form-control" 
                                                                value={slot.map(a => ' '+ a.Slot)}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtName" 
                                                            name="txtName" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={status.Status || ''}
                                                            onChange={(event) => updateStatusDetails('Status', event.target.value)} 
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="kt-portlet__foot">
                                            <div className="kt-form__actions">
                                                <div className="row">
                                                    <div className="col-lg-9 ml-lg-auto">
                                                        <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addTeeTimeBlock}>Save Booking</button>
                                                        <button id="btnCancel" type="button" className="btn btn-secondary margin-l10"  onClick={closeDrawer}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </RightSideDetails>}
                            </div>
                        </div>
                        <div className="kt-portlet__foot">
                            <div className="kt-form__actions">
                                <div className="row">
                                    <div className="col-lg-8 ml-lg-auto">
                                        <button id="btnBook" type="button" className="btn btn-brand d-inline-block" onClick={() =>openDrawer()}>Block Tee Time</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TeeTimeBlocking;