import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const getUserAllUrl                                      = '/api/admin/users/getUserAll';
const postUserUrl                                        = '/api/admin/users/postUser';
const postGuestUrl                                       = '/api/admin/users/postGuest';
const getActiveUserUrl                                   = '/api/admin/users/getActiveUser';

export const getUserAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getUserAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getActiveUser = async() => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosGet(getActiveUserUrl, { headers: { auth_token: `bearer ${token}` } });
    return response;
};

export const postUser = async (userDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postUserUrl,
        { userDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postGuest = async (userDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postGuestUrl,
        { userDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};