import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";



const getEventAllUrl                                      = '/api/admin/event/getEventAll';
const getEventMediaByEventIDUrl                           = (id) => `/api/admin/event/getEventMediaByEventID/${id}`
const getEventRegistrationByEventIDUrl                    = (id) => `/api/admin/event/getEventRegistrationByEventID/${id}`
const postEventUrl                                        = '/api/admin/event/postEvent';
const addEventRegistrationUrl                             = '/api/admin/event/addEventRegistration';
const deleteEventRegistrationUrl                          = (id) => `/api/admin/event/deleteEventRegistration/${id}`


export const getEventAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getEventAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getEventMediaByEventID = async (ID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getEventMediaByEventIDUrl(ID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getEventRegistrationByEventID = async (ID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getEventRegistrationByEventIDUrl(ID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postEvent = async (eventDetails, mediaList) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postEventUrl,
        { eventDetails, mediaList},
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const addEventRegistration = async (eventDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        addEventRegistrationUrl,
        { eventDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deleteEventRegistration = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosDelete(
        deleteEventRegistrationUrl(id),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

