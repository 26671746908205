import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";



const getNoticeBoardAllUrl                                      = '/api/admin/noticeBoard/getNoticeBoardAll';
const getNoticeBoardMediaByNoticeBoardIDUrl                     = (id) => `/api/admin/noticeBoard/getNoticeBoardMediaByNoticeBoardID/${id}`
const postNoticeBoardUrl                                        = '/api/admin/noticeBoard/postNoticeBoard';


export const getNoticeBoardAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getNoticeBoardAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getNoticeBoardMediaByNoticeBoardID = async (ID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getNoticeBoardMediaByNoticeBoardIDUrl(ID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postNoticeBoard = async (noticeBoardDetails, mediaList) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postNoticeBoardUrl,
        { noticeBoardDetails, mediaList},
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};


