import { useState } from "react";
import { useEffect } from "react";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getAppServiceByType, postAppService } from "../api/golfClubAPI";


const IOSService = (props) =>{

    const [iOS,                  setIOS]                  = useState({});

    useEffect(() => {
        props.title("iOS Service", "ios-service")
        fetchAppService();
    }, []);
    
    const fetchAppService = async() =>{
        try {
            const app = await getAppServiceByType('iOS')  
            setIOS(app);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }
        
    const updateIOSDetails = (field, value) => {   
        setIOS((d) => {
            return { ...d, [field]: value };
        });
    };
    const updateIOSHandler = async() => {

        const btn = $('#btnUpdate');
        var form = $('#add-iOS-form');
        
        form.validate({
            rules: {
                txtMessage: {
                    required: true
                }       
            },
            messages: {
                txtMessage: {
                    required: "Please enter Version"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            await postAppService(iOS);
            helper.StopProcessing(btn);
            fetchAppService();
            swal.fire({
                icon: 'success',
                titleText: 'IOS Service updated!',
                showConfirmButton: false,
                timer: 1500,
            });

        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                          : 'error',
                titleText                     : 'Error!',
                text                          : err.message,
                buttonsStyling                : false,
                confirmButtonclassName        : 'btn btn-brand',
            });
            return;
        }
    }

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content" >
                <div className="kt-container  kt-container--fluid kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <form className="kt-form" id="add-iOS-form">
                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Message</label>
                                        <div className="col-xl-6 col-lg-8">
                                            <textarea 
                                                id="txtMessage" 
                                                name="txtMessage" 
                                                className="form-control" 
                                                rows="3"
                                                value={iOS.Message || ''}
                                                onChange={(event) => updateIOSDetails('Message', event.target.value)}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Is Running</label>
                                        <div className="col-xl-6 col-lg-8">
                                            <span className="kt-switch kt-switch--icon">
                                                <label>
                                                    <input 
                                                        id="chkIsRunning" 
                                                        name="chkIsRunning" 
                                                        type="checkbox" 
                                                        checked={iOS.IsRunning == 'Y' ? 'checked' : ''}
                                                        onChange={(event) => updateIOSDetails('IsRunning', event.target.checked ? 'Y' : 'N')} 
                                                    />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div> 
                        <div className="kt-portlet__foot">
                            <div className="kt-form__actions">
                                <div className="row">
                                    <div className="col-lg-9 ml-lg-auto">
                                        <button id="btnUpdate" type="button" className="btn btn-brand d-inline-block" onClick={updateIOSHandler}>Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IOSService;