import { useEffect, useState } from "react";
import { getUserAll } from "../api/usersAPI";
import swal from 'sweetalert2';
import Select from 'react-select';
import * as helper from '../global/helper';
import { addEventRegistration } from "../api/eventAPI";

const AddEventRegistration = (props) => {

    const [usersList,                   setUsersList]                = useState([]);
    const [eventRegistration,           setRegistrationEvent]        = useState({});

    useEffect(() => {
        $('#add-eventRegister-modal').modal({
            backdrop: 'static',
        });
        $('#add-eventRegister-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);
        });
        fetchUsers();
    }, [])
        
    const fetchUsers = async() =>{
        try {
            const users = await getUserAll()
            setUsersList(users)
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const addEventHandler = async(e) => {
        e.preventDefault()
        
        const btn = $('#btnSave');
        var form = $('#add-eventRegister-form');
        
        form.validate({
            rules: {
                ddlUser: {
                    required: true,
                }                      
            },
            messages: {
                ddlUser: {
                    required: "Please select user"
                }
            }
        });
        
        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {

            const res = await addEventRegistration({...eventRegistration, EventID: props.eventRegistrationID})
            helper.StopProcessing(btn);
            props.refetchRegistration()
            $('#add-eventRegister-modal').modal('hide');
            swal.fire({
                icon: 'success',
                titleText: 'Event added successfully!',
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return
        }

    }

    const updateRegistrationDetails = (field, value) => { 
            setRegistrationEvent((d) => {
            return { ...d, [field]: value };
        });
    };

    const closeDrawer = () =>{
       setRegistrationEvent({});
    }

    return(
        <>
            <div className="modal fade" id="add-eventRegister-modal" tabIndex="-1" role="dialog" aria-labelledby="add-eventRegister-modal" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Event Registration</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body grey-background">
                            <div id="ControlModalHolder">
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body">
                                        <form className="kt-form" id="add-eventRegister-form">
                                            <div className="kt-widget kt-widget--user-profile-3">
                                                <div className="form-group row">
                                                    <label className="col-form-label offset-lg-1 col-lg-2">User <span className="red">*</span></label>
                                                    <div className="col-xl-3 col-lg-4">
                                                        <Select
                                                            value={(usersList && usersList.length > 0 && eventRegistration.UserID) && {label: usersList.find(m => m.ID == eventRegistration.UserID).UserName, value: eventRegistration.UserID}}
                                                            onChange={(event) => updateRegistrationDetails('UserID', event.value)}
                                                            name="ddlUser"
                                                            placeholder="Select User"
                                                            id="ddlUser"
                                                            className="ddlUser"
                                                            options={usersList.map(c => ({
                                                                label: c.UserName,
                                                                value: c.ID
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label offset-lg-1 col-lg-2">Special Request </label>
                                                    <div className="col-xl-4 col-lg-6">
                                                        <input 
                                                            id="txtComment" 
                                                            name="txtComment" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={eventRegistration.Comment || ''}
                                                            onChange={(event) => updateRegistrationDetails('Comment', event.target.value)} 
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button  type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeDrawer}>Close</button>
                            <button id="btnSave" type="button" className="btn btn-primary" onClick={addEventHandler}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddEventRegistration;