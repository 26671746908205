import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const getEmailTemplateUrl                                = '/api/admin/golfClub/getEmailTemplate';
const postEmailTemplateUrl                               = '/api/admin/golfClub/postEmailTemplate';
const getAppUpdateByTypeUrl                              = (type) => `/api/admin/golfClub/getAppUpdateByType/${type}`;
const postAppUpdateUrl                                   = '/api/admin/golfClub/postAppUpdate';
const getAppServiceByTypeUrl                             = (type) => `/api/admin/golfClub/getAppServiceByType/${type}`;
const postAppServiceUrl                                  = '/api/admin/golfClub/postAppService';
const getHolidayCalenderAllUrl                           = '/api/admin/golfClub/getHolidayCalenderAll';
const postHolidayCalenderUrl                             = '/api/admin/golfClub/postHolidayCalender';
const getTournamentCalenderAllUrl                        = '/api/admin/golfClub/getTournamentCalenderAll';
const postTournamentCalenderUrl                          = '/api/admin/golfClub/postTournamentCalender';
const postClubUrl                                        = '/api/admin/golfClub/postClub';
const getClubAllUrl                                      = '/api/admin/golfClub/getClubAll';
const postTeeBoxUrl                                      = '/api/admin/golfClub/postTeeBox';
const getTeeBoxAllUrl                                    = '/api/admin/golfClub/getTeeBoxAll';
const getCourseAllUrl                                    = '/api/admin/golfClub/getCourseAll';
const postCourseUrl                                      = '/api/admin/golfClub/postCourse';
const getCourseClosedAllUrl                              = '/api/admin/golfClub/getCourseClosedAll';
const postCourseClosedUrl                                = '/api/admin/golfClub/postCourseClosed';
const postHoleInOneUrl                                   = '/api/admin/golfClub/postHoleInOne';
const getViewHoleInOneAllUrl                             = '/api/admin/golfClub/getViewHoleInOneAll';
const getFeedbackAllUrl                                  = '/api/admin/golfClub/getFeedbackAll';

export const getFeedbackAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getFeedbackAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getEmailTemplate = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getEmailTemplateUrl,
      	{ headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postEmailTemplate = async (templateDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postEmailTemplateUrl,
        { templateDetails },
      	{ headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getAppUpdateByType = async (type) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getAppUpdateByTypeUrl(type),
        { headers: { auth_token: `bearer ${token}` } }
	);
	return response;
};

export const postAppUpdate = async (appDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postAppUpdateUrl,
        { appDetails },
      	{ headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getAppServiceByType = async (type) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getAppServiceByTypeUrl(type),
        { headers: { auth_token: `bearer ${token}` } }
	);
	return response;
};

export const postAppService = async (appDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postAppServiceUrl,
        { appDetails },
      	{ headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getHolidayCalenderAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getHolidayCalenderAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postHolidayCalender = async (calenderDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postHolidayCalenderUrl,
        { calenderDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getTournamentCalenderAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getTournamentCalenderAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postTournamentCalender = async (calenderDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postTournamentCalenderUrl,
        { calenderDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postClub = async (clubDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postClubUrl,
        { clubDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getClubAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getClubAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postTeeBox = async (teeBoxDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postTeeBoxUrl,
        { teeBoxDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getTeeBoxAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getTeeBoxAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getCourseAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getCourseAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postCourse = async (courseDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postCourseUrl,
        { courseDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postCourseClosed = async (courseDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postCourseClosedUrl,
        { courseDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getCourseClosedAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getCourseClosedAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postHoleInOne = async (holeInOneDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postHoleInOneUrl,
        { holeInOneDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getViewHoleInOneAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getViewHoleInOneAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};