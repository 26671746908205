import { useState, useEffect } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import Loading from "../global/loading";
import { getAcademyDiscountByPackageID, getAcademyFeeByPackageID, getLessonByPackageID, getPackageAll, getSubLessonByLessonID, postAcademyDiscount, postAcademyFee, postLesson, postPackage, postSubLesson } from "../api/academyAPI";
import NoData from "../global/noData";

const AcademyFees = (props) => {

    const [isInit,                   setIsInit]                     = useState(true);
    const [rightSideDetails,         setRightSideDetails]           = useState(false);
    const [packageList,              setPackageList]                = useState([]);
    const [academyFeeList,           setAcademyFeeList]             = useState([]);
    const [academyDiscountList,      setAcademyDiscountList]        = useState([]);
    const [selectedPackage,          setSelectedPackage]            = useState(null);
    const [navState,                 setNavState]                   = useState('');
    const [loading,                  setLoading]                    = useState(false);
    const [academyFeeDetails,        setAcademyFeeDetails]          = useState({});
    const [editAcademyFee,           setEditAcademyFee]             = useState(false); 
    const [editAcademyDiscount,      setEditAcademyDiscount]        = useState(false); 
    const [academyDiscountDetails,   setAcademyDiscountDetails]     = useState({}); 
    const [outerNavState,            setOuterNavState]              = useState('All Packages');

    useEffect(() => {
        props.title("Fees", "academy-fees")
    }, []);

    useEffect(() => {
        if (outerNavState) {
            fetchPackage();
        }
    }, [outerNavState])

    useEffect(() => {
        if (navState === 'Academy Fees') {
            fetchAcademyFee()
        } else if (navState ===  'Academy Discount') {
            fetchAcademyDiscount()
        }
    }, [navState])

    useEffect(() => {
        if (selectedPackage)
            setNavState('Academy Fees')
        else
            setNavState('')
    }, [selectedPackage])

    useEffect(() => {
        if(rightSideDetails)
            Config.Core.OpenDrawer() 
    }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();        
        }
    }, [packageList]);

    function fillDataTable() {

        const dataTable = $('#tblPackage').KTDatatable({
            data: {
                saveState: false,
                source: packageList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Title',
                    title: 'Title',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.BannerImage && row.BannerImage != '') ? `<img src="${row.BannerImage}" height="40" width="40" alt="banner-image" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Title.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Title} </span>
                                    <span class="kt-user-card-v2__desc text-clip"> ${row.Type} </span>
                                </div>
                            </div>`
                    }
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        if (row.Active == 'Y') 
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                               
                        else
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">No</span>'
                    },
                    width: 50,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblPackage .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setSelectedPackage(dataTable.dataSet[index]);
                setRightSideDetails(true); 
            }    
        });
    }

    const updateAcademyFeeDetails = (field, value) => {
        setAcademyFeeDetails(a => {
            return { ...a, [field]: value }
        })
    }

    const updateAcademyDiscountDetails = (field, value) => {
        setAcademyDiscountDetails(a => {
            return { ...a, [field]: value }
        })
    }

    const closeDrawer = () => {
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
            setAcademyFeeDetails({})
            setEditAcademyFee(false)
            setSelectedPackage(null)
        }, 200);
    }

    const fetchAcademyFee = async () => {
        try {
            setLoading(true)
            const response = await getAcademyFeeByPackageID(selectedPackage.ID)
            setAcademyFeeList(response)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchAcademyDiscount = async () => {
        try {
            setLoading(true)
            const response = await getAcademyDiscountByPackageID(selectedPackage.ID)
            setAcademyDiscountList(response);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchPackage = async() => {
        try {
            const response = await getPackageAll()
            isInit ? setIsInit(false) : $('#tblPackage').KTDatatable().destroy(); 
            outerNavState == 'All Packages' ? setPackageList(response) : outerNavState == 'Individual' ? setPackageList([...response.filter(f => f.Type == 'Individual')]) : setPackageList([...response.filter(f => f.Type == 'Group')]);        
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const AddAcademyDiscountHandler = async () => {
        const btn = $('#add-btn');
        var form = $('#add-discount');
        form.validate({
            rules: {
                toDate: {
                    required: true
                },
                fromDate: {
                    required: true
                },
                discount: {
                    required: true,
                    number: true
                }           
            },
            messages: {
                toDate: {
                    required: "Please select date"
                },
                fromDate: {
                    required: "Please select date"
                },
                discount: {
                    required: "Please enter discount"
                }
            }
        });

        if (!form.valid()) return;

        if (academyDiscountDetails.FromDate > academyDiscountDetails.ToDate) {
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Invalid Date!!',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return;
        }

        if (academyDiscountDetails.ID && academyDiscountDetails.IsActive) {
            const currentDate = moment().format('YYYY-MM-DD')
            if (academyDiscountDetails.ToDate < currentDate) {
                swal.fire({
                    icon                  : 'warning',
                    titleText             : 'Invalid Date!!',
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
                return;
            }
        }
        const t = academyDiscountList.find((t, i) => {
            if ((t.ID != academyDiscountDetails.ID)) {
                if (moment(academyDiscountDetails.FromDate).isBetween(moment(t.FromDate).format('YYYY-MM-DD'), moment(t.ToDate).format('YYYY-MM-DD'), '', [])
                    || moment(academyDiscountDetails.ToDate).isBetween(moment(t.FromDate).format('YYYY-MM-DD'), moment(t.ToDate).format('YYYY-MM-DD'), '', [])
                    || moment(academyDiscountDetails.FromDate).isBefore(moment(t.FromDate).format('YYYY-MM-DD')) && moment(academyDiscountDetails.ToDate).isAfter(moment(t.ToDate).format('YYYY-MM-DD')))
                {
                    return t
                }
            }
        });
        if (t) {
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Date Conflict!!',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return;
        }
        helper.StartProcessing(btn);

        try {
            await postAcademyDiscount({...academyDiscountDetails, PackageID: selectedPackage.ID});
            setAcademyDiscountDetails({})
            if (academyDiscountDetails.ID) {
                setEditAcademyDiscount(false)
                $('.disableFromDate').attr('disabled', false)
            }
            fetchAcademyDiscount(selectedPackage.ID)
            helper.StopProcessing(btn);
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: `Package fee ${academyDiscountDetails.ID ? 'update' : 'add'} successfully!`,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,                                       
            });

        }catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const AddAcademyFeeHandler = async () => {
        const btn = $('#add-btn');
        var form = $('#add-fee');
        form.validate({
            rules: {
                toDate: {
                    required: true
                },
                fromDate: {
                    required: true
                },
                fee: {
                    required: true,
                    number: true
                }           
            },
            messages: {
                toDate: {
                    required: "Please select date"
                },
                fromDate: {
                    required: "Please select date"
                },
                fee: {
                    required: "Please enter fee"
                }
            }
        });

        if (!form.valid()) return;

        if (academyFeeDetails.FromDate > academyFeeDetails.ToDate) {
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Invalid Date!!',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return;
        }

        if (academyFeeDetails.ID && academyFeeDetails.IsActive) {
            const currentDate = moment().format('YYYY-MM-DD')
            if (academyFeeDetails.ToDate < currentDate) {
                swal.fire({
                    icon                  : 'warning',
                    titleText             : 'Invalid Date!!',
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
                return;
            }
        }

        const t = academyFeeList.find((t, i) => {
            if ((t.ID != academyFeeDetails.ID)) {
                if (moment(academyFeeDetails.FromDate).isBetween(moment(t.FromDate).format('YYYY-MM-DD'), moment(t.ToDate).format('YYYY-MM-DD'), '', [])
                    || moment(academyFeeDetails.ToDate).isBetween(moment(t.FromDate).format('YYYY-MM-DD'), moment(t.ToDate).format('YYYY-MM-DD'), '', [])
                    || moment(academyFeeDetails.FromDate).isBefore(moment(t.FromDate).format('YYYY-MM-DD')) && moment(academyFeeDetails.ToDate).isAfter(moment(t.ToDate).format('YYYY-MM-DD')))
                {
                    return t
                }
            }
        });
        if (t) {
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Date Conflict!!',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return;
        }
        helper.StartProcessing(btn);
        try {
            await postAcademyFee({...academyFeeDetails, PackageID: selectedPackage.ID});
            setAcademyFeeDetails({})
            if (academyFeeDetails.ID) {
                setEditAcademyFee(false)
                $('.disableFromDate').attr('disabled', false)
            }
            fetchAcademyFee(selectedPackage.ID)
            helper.StopProcessing(btn);
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: `Package fee ${academyFeeDetails.ID ? 'update' : 'add'} successfully!`,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,                                       
            });
        }catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const editFeeHandler = (fee) => {
        setAcademyFeeDetails(fee);
        setEditAcademyFee(true)
        if (fee.IsActive)
            $('.disableFromDate').attr('disabled', true)
    }

    const editDiscountHandler = (discount) => {
        setAcademyDiscountDetails(discount);
        setEditAcademyDiscount(true)
        if (discount.IsActive)
            $('.disableFromDate').attr('disabled', true)
    }

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <nav className="file-nav navbar navbar-top">
                                <a className={outerNavState === "All Packages" ? "top-nav-active" : ""} onClick={() => setOuterNavState("All Packages")}>  
                                    All Packages 
                                </a>
                                <a className={outerNavState === "Individual" ? "top-nav-active" : "" } onClick={() => setOuterNavState("Individual")}  >  
                                    Individual
                                </a>
                                <a className={outerNavState === "Group" ? "top-nav-active" : "" } onClick={() => setOuterNavState("Group")}  >  
                                    Group
                                </a>
                            </nav>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="row align-items-center margin-l10">
                                <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <input type="text" className="form-control clientSearch" placeholder="Search Package..." id="generalSearch" />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div id="tblPackage" className="kt-datatable table-striped clickable"></div>
                        {rightSideDetails && (
                            <RightSideDetails title="Package Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body">
                                        <div className="kt-widget kt-widget--user-profile-3">
                                            <div className="kt-widget__top">
                                                {selectedPackage.BannerImage ? (

                                                    <div className="kt-widget__pic kt-widget__pic--brand">
                                                        <div className="kt-media kt-media--circle">
                                                            <img src={selectedPackage.BannerImage} alt="image"/>
                                                        </div>
                                                    </div>
                                                ) : (

                                                    <div className="kt-widget__pic kt-widget__pic--brand kt-font-brand kt-font-boldest">
                                                        {selectedPackage.Title && selectedPackage.Title.slice(0,1)}
                                                    </div>
                                                )}
                                                <div className="kt-widget__content">
                                                    <div className="row align-items-center">
                                                        <div className="col-5">
                                                            <div className="kt-widget__head">
                                                                <span className="kt-widget__username">
                                                                    {selectedPackage.Title}
                                                                </span>
                                                            </div>
                                                            <div className="kt-widget__subhead padding-t0">
                                                                <span className="padding-r10">
                                                                    <i className="flaticon2-calendar-3"></i>
                                                                    {selectedPackage.Type}
                                                                </span>
                                                                {selectedPackage.Type === 'Group' && (
                                                                    <span className="padding-r10">
                                                                        <i className="flaticon-users"></i>
                                                                        Group Strength : {selectedPackage.GroupSize}
                                                                    </span>
                                                                )}
                                                                <br/>
                                                                {selectedPackage.Type === 'Individual' && (
                                                                    <span className="padding-r10">
                                                                        <i className="flaticon-calendar-1"></i>
                                                                        Duration (in days) : {selectedPackage.Duration}
                                                                    </span>
                                                                )}
                                                                {selectedPackage.Type === 'Group' && (
                                                                    <span className="padding-r10">
                                                                        <i className="flaticon-calendar-1"></i>
                                                                        Duration : {moment(selectedPackage.FromDate).format('Do MMM YYYY')} - {moment(selectedPackage.ToDate).format('Do MMM YYYY')}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet kt-prtlet--mobile">
                                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                                        <div className="kt-portlet__head-label">
                                            <nav className="file-nav navbar navbar-top">
                                                <a className={navState === 'Academy Fees' ? 'top-nav-active' : ''}
                                                    onClick={() => setNavState('Academy Fees')}>
                                                    Package Fees
                                                </a>
                                                <a className={navState === 'Academy Discount' ? 'top-nav-active' : ''}
                                                    onClick={() => setNavState('Academy Discount')}>
                                                    Package Discount
                                                </a>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__body kt-portlet__body--fit">
                                        {navState === 'Academy Fees' ? (
                                            <form className="kt-form kt-form--label-right" id="add-fee" onSubmit={e => e.preventDefault()}>
                                                <div className="padding-20">
                                                    <div className="form-group row">
                                                        <div className="col-3">
                                                        <label htmlFor="fromDate">From Date</label>
                                                        <input
                                                            id="fromDate"
                                                            // value={academyFeeDetails.FromDate || ''}
                                                            value={academyFeeDetails.FromDate ? moment(academyFeeDetails.FromDate).format('YYYY-MM-DD') : ''}
                                                            name="fromDate"
                                                            // min={moment().format('YYYY-MM-DD')}
                                                            className="form-control margin-b10 disableFromDate"
                                                            type="date"
                                                            onChange={(event) => updateAcademyFeeDetails('FromDate', event.target.value)}
                                                        />
                                                        </div>
                                                        <div className="col-3">
                                                            <label htmlFor="toDate">To Date</label>
                                                            <input
                                                                id="toDate"
                                                                // value={academyFeeDetails.ToDate || ''}
                                                                value={academyFeeDetails.ToDate ? moment(academyFeeDetails.ToDate).format('YYYY-MM-DD') : ''}
                                                                name="toDate"
                                                                // min={moment().format('YYYY-MM-DD')}
                                                                className="form-control margin-b10"
                                                                type="date"
                                                                onChange={(event) => updateAcademyFeeDetails('ToDate', event.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-3">
                                                            <label htmlFor="fee">Fees</label>
                                                            <div className="input-group">
                                                                <input
                                                                    id="fee"
                                                                    value={academyFeeDetails.AcademyFee || ''}
                                                                    name="fee"
                                                                    className="form-control"
                                                                    type="number"
                                                                    onChange={(event) => updateAcademyFeeDetails('AcademyFee', event.target.value)}
                                                                />
                                                                <div className="input-group-append revenue-icon">
                                                                    <span className="input-group-text">₹</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-2 pt-4'>
                                                            {editAcademyFee ? (
                                                                <div className="btn-group btn-block" role="group">

                                                                    <button
                                                                        id="add-btn"
                                                                        className="btn btn-brand btn-block margin-0 margin-t10"
                                                                        style={{ maxWidth: '70%' }}
                                                                        onClick={AddAcademyFeeHandler}
                                                                    >
                                                                        Update
                                                                    </button>
                                                                    <button
                                                                        style={{ maxWidth: '30%' }}
                                                                        className="btn btn-secondary btn-block margin-0 margin-t10 padding-0"
                                                                        onClick={() => {
                                                                            setAcademyFeeDetails({});
                                                                            setEditAcademyFee(false);
                                                                            $('.disableFromDate').attr('disabled', false)
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="la la-times"
                                                                            style={{ fontSize: '1rem', marginLeft: '3px' }}
                                                                        />
                                                                    </button>
                                                                </div>
                                                            ) : (

                                                                <button className="btn btn-brand btn-sm margin-t10 margin-l0" id='add-btn' onClick={AddAcademyFeeHandler} >
                                                                    <i className="la la-plus"></i>
                                                                    Add
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {loading ? <Loading/> : (
                                                        academyFeeList.length > 0 ? (
                                                            <div className="row">
                                                                <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                                                    <table className="kt-datatable__table">
                                                                        <thead className="kt-datatable__head" style={{ display: 'block' }}>
                                                                            <tr className="kt-datatable__row block-row">
                                                                                <th className="kt-datatable__cell text-clip" width="40%">
                                                                                    <span>Duration</span>
                                                                                </th>
                                                                                <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="50%">
                                                                                    <span>Net Fees</span>
                                                                                </th>
                                                                                <th className="text-right kt-datatable__cell text-clip" width="10%">
                                                                                    <span>Action</span>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                    </table>
                                                                </div> 
                                                                <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0 scrolTable" onScroll={helper.setStickyHeader}>
                                                                    <table className="kt-datatable__table table-striped">
                                                                        <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                                                                            {academyFeeList.map((a, i) => (
                                                                                <tr
                                                                                    key={i}
                                                                                    className="kt-datatable__row block-row">  
                                                                                    <td width="40%" title={moment(a.FromDate).format('DD/MM/YYYY')} className="kt-datatable__cell text-clip">
                                                                                        <span className="kt-font-bold">
                                                                                            {moment(a.FromDate).format('Do MMM YYYY')} - {moment(a.ToDate).format('Do MMM YYYY')}
                                                                                            {a.IsActive && (
                                                                                                <span className='kt-badge kt-badge--success kt-badge--inline kt-badge--pill greenFee-badge' title='Active'>
                                                                                                    Active
                                                                                                </span>
                                                                                            )}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td width="50%" title={a.AcademyFee} className="kt-datatable__cell kt-datatable__cell--center text-clip">
                                                                                        <span>₹ {a.AcademyFee}</span>
                                                                                    </td>  
                                                                                    <td className="kt-datatable__cell text-right text-clip" width="10%">
                                                                                        <button 
                                                                                            type="button"
                                                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                                                
                                                                                            onClick={() => editFeeHandler(a)}
                                                                                        >  
                                                                                            <i className="flaticon2-edit"></i>
                                                                                        </button>
                                                                                    </td>                                       
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <NoData
                                                                message='No Fees Found'
                                                            />
                                                        )
                                                    )}
                                                </div>
                                            </form>
                                        ) : (
                                            <div className="padding-20">
                                                <form className="kt-form kt-form--label-right" id="add-discount" onSubmit={e => e.preventDefault()}>
                                                    <div className="form-group row">
                                                        <div className="col-3">
                                                            <label htmlFor="fromDate">From Date</label>
                                                            <input
                                                                id="fromDate"
                                                                // value={academyFeeDetails.FromDate || ''}
                                                                value={academyDiscountDetails.FromDate ? moment(academyDiscountDetails.FromDate).format('YYYY-MM-DD') : ''}
                                                                name="fromDate"
                                                                // min={moment().format('YYYY-MM-DD')}
                                                                className="form-control margin-b10 disableFromDate"
                                                                type="date"
                                                                onChange={(event) => updateAcademyDiscountDetails('FromDate', event.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-3">
                                                            <label htmlFor="toDate">To Date</label>
                                                            <input
                                                                id="toDate"
                                                                // value={academyFeeDetails.ToDate || ''}
                                                                value={academyDiscountDetails.ToDate ? moment(academyDiscountDetails.ToDate).format('YYYY-MM-DD') : ''}
                                                                name="toDate"
                                                                // min={moment().format('YYYY-MM-DD')}
                                                                className="form-control margin-b10"
                                                                type="date"
                                                                onChange={(event) => updateAcademyDiscountDetails('ToDate', event.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-3 margin-b0">
                                                            <div className="form-group">
                                                                <label htmlFor="discount">Discount</label>
                                                                <div className="input-group">
                                                                    <input
                                                                        id="discount"
                                                                        value={academyDiscountDetails.AcademyDiscount || ''}
                                                                        name="discount"
                                                                        max='100'
                                                                        className="form-control"
                                                                        type="number"
                                                                        onChange={(event) => updateAcademyDiscountDetails('AcademyDiscount', event.target.value)}
                                                                    />
                                                                    <div className="input-group-append revenue-icon">
                                                                        <span className="input-group-text">%</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-2 pt-4'>
                                                            {editAcademyDiscount ? (
                                                                <div className="btn-group btn-block" role="group">

                                                                    <button
                                                                        id="add-btn"
                                                                        className="btn btn-brand btn-block margin-0 margin-t10"
                                                                        style={{ maxWidth: '70%' }}
                                                                        onClick={AddAcademyDiscountHandler}
                                                                    >
                                                                        Update
                                                                    </button>
                                                                    <button
                                                                        style={{ maxWidth: '30%' }}
                                                                        className="btn btn-secondary btn-block margin-0 margin-t10 padding-0"
                                                                        onClick={() => {
                                                                            setAcademyDiscountDetails({});
                                                                            setEditAcademyDiscount(false);
                                                                            $('.disableFromDate').attr('disabled', false)
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="la la-times"
                                                                            style={{ fontSize: '1rem', marginLeft: '3px' }}
                                                                        />
                                                                    </button>
                                                                </div>
                                                            ) : (

                                                                <button className="btn btn-brand btn-sm margin-t10 margin-l0" id='add-btn' onClick={AddAcademyDiscountHandler}>
                                                                    <i className="la la-plus"></i>
                                                                    Add
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {loading ? <Loading/> : (
                                                        academyDiscountList.length > 0 ? (
                                                            <div className="row">
                                                                <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                                                    <table className="kt-datatable__table">
                                                                        <thead className="kt-datatable__head" style={{ display: 'block' }}>
                                                                            <tr className="kt-datatable__row block-row">
                                                                                <th className="kt-datatable__cell text-clip" width="40%">
                                                                                    <span>From Date</span>
                                                                                </th>
                                                                                <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="50%">
                                                                                    <span>Discount %</span>
                                                                                </th>
                                                                                <th className="text-right kt-datatable__cell text-clip" width="10%">
                                                                                    <span>Action</span>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                    </table>
                                                                </div> 
                                                                <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0 scrolTable" onScroll={helper.setStickyHeader}>
                                                                    <table className="kt-datatable__table table-striped">
                                                                        <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                                                                            {academyDiscountList.map((a, i) => (
                                                                                <tr
                                                                                    key={i}
                                                                                    className="kt-datatable__row block-row">  
                                                                                    <td width="40%" title={moment(a.FromDate).format('DD/MM/YYYY')} className="kt-datatable__cell text-clip">
                                                                                        <span className="kt-font-bold">
                                                                                        {moment(a.FromDate).format('Do MMM YYYY')} - {moment(a.ToDate).format('Do MMM YYYY')}
                                                                                            {a.IsActive && (
                                                                                                <span className='kt-badge kt-badge--success kt-badge--inline kt-badge--pill greenFee-badge' title='Active'>
                                                                                                    Active
                                                                                                </span>
                                                                                            )}
                                                                                        </span>
                                                                                    </td> 
                                                                                    <td width="50%" title={a.AcademyDiscount} className="kt-datatable__cell kt-datatable__cell--center text-clip">
                                                                                        <span>{a.AcademyDiscount}</span>
                                                                                    </td>  
                                                                                    <td className="kt-datatable__cell text-right text-clip" width="10%">
                                                                                        <button 
                                                                                            type="button"
                                                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                                            onClick={() => editDiscountHandler(a)}
                                                                                        >  
                                                                                            <i className="flaticon2-edit"></i>
                                                                                        </button>
                                                                                    </td>                                       
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <NoData
                                                                message='No Discount Found'
                                                            />
                                                        )
                                                    )}
                                                </form> 
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </RightSideDetails>
                        )}
                    </div>
                </div>
            </div>
                        
        </>
    )
}

export default AcademyFees;