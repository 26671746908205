import { useState, useEffect } from "react";
import Dropzone from "./dropzone";


const ImageUploader = (props) => {

    const [files, setFiles] = useState([]);

    const handleUpload = (i) => {                  
        const imgFile = window.URL.createObjectURL(i);
        setFiles( o => ([...o, {...imgFile, img: imgFile, File: i}]));        
    }

    const onRemove = (index) => {
        const filtered = files.filter((f,i) => index != i);
        setFiles(filtered);
    }

    const onSave =()=> {
        props.dataHandler(files);
        $("#imageUploaderModal").modal("hide"); 
    }

    useEffect(()=>{
        $("#imageUploaderModal").modal({ backdrop: "static" });      
        $("#imageUploaderModal").on("hidden.bs.modal", function () { props.onDismissModal() });
        $("#imageUploaderModal").modal("toggle");
    },[])

    return (<>
        <div className="modal fade" id="imageUploaderModal" tabIndex={-1} role="dialog">
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">

                    <div className="modal-header">
                        <h5 className="modal-title"> Upload Images </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <Dropzone  classNames="height-95" imageOnly = {true} isMulti={true} fileHandler={handleUpload} accept="image/*" />
                        
                        <div className="mt-2 padding-lr100">
                            {(files.length > 0) && (files.map((f,i) => {
                                return(
                                    <div key={i} className="kt-avatar kt-avatar--outline kt-avatar--changed mx-3 ">
                                        <div className="kt-avatar__holder wh-100" 
                                            style={{ backgroundImage : `url(${f.img})`, backgroundPosition:"center"  } }>    
                                        </div>
                                        <span className="kt-avatar__cancel" onClick={() => onRemove(i)}><i className="fa fa-times"></i></span>
                                    </div>
                                )
                            }))}                            
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary margin-0 margin-r5" data-dismiss="modal"> Close </button>
                        <button id="save-btn" type="button" className="btn btn-success margin-0" onClick={onSave}> Save </button> 
                    </div>
                    
                </div>
            </div>
        </div>
    </>);
}

export default ImageUploader;