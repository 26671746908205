import React, { useEffect, useState } from 'react'
import RightSideDetails from "../global/rightSideDetails";
import TextInput from '../base/TextInput';
import { getMatMasterAll, postMatMaster } from '../api/matAPI';
import * as helper from '../global/helper';
import swal from 'sweetalert2';

const MatMaster = (props) => {
    const [isInit,                  setIsInit]               = useState(true);
    const [rightSideDetails,        setRightSideDetails]     = useState(false);
    const [allMaster,               setAllMaster]            = useState([]);
    const [matMaster,               setMatMaster]            = useState({});

    useEffect(() => {
        props.title("Mat Master", "mat-master")       
        fetchMatMaster();
    }, []);

    useEffect(() => {       
        if (rightSideDetails) {
            Config.Core.OpenDrawer()
        }else{
            setMatMaster({Active: 'Y', Type: 'Mat'})
        }
    }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();
        }
    }, [allMaster]);

    const fetchMatMaster = async() => {
        try {
            const calender = await getMatMasterAll()   
            isInit ? setIsInit(false) : $('#tblMaster').KTDatatable().destroy(); 
            setAllMaster(calender); 
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    }


    const fillDataTable = () => {
        const dataTable = $('#tblMaster').KTDatatable({
            data: {
                saveState: false,
                source   : allMaster,
                pageSize : 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable  : true,
            pagination: true,
            search    : { input: $('#generalSearch')},
            columns: [               
                {  field: 'Number',  title: 'Number', width:250 },
                {
                    field: 'Type',
                    title: 'Type',
                    width: 120,
                },
                {
                    field: 'Active',
                    title: 'Active', width:120,                          
                },
            ]
        });
    
        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setMatMaster(dataTable.dataSet[index]);  
                setRightSideDetails(true);
            } 
        });    
    }

    const updateMatDetails = (field, value) => {
        setMatMaster(m => ({
            ...m,
            [field]: value
        }))
    }

    const addMatMasterHandler = async () => {
        const btn  = $('#btnSave');
        const form = $('#addMasterForm');

        form.validate({
            rules: {
                Number     : { required: true },       
            },
            messages: {
                Number     : { required: "Please enter number" },
            }
        });

        if (!form.valid()) return;
        //Validation Goes Here -->
                
        helper.StartProcessing(btn);
        try {
            await postMatMaster(matMaster);
            
            helper.StopProcessing(btn);
            
            closeDrawer();
            fetchMatMaster();
            setMatMaster({Active: 'Y', Type: 'Mat'})
            if (matMaster.ID) {            
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Master updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,                                       
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Master added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }


    return (
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Master..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i> New Master </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblMaster" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && (
                                <RightSideDetails title="Details" onCloseDrawer={() => closeDrawer()}>
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body padding-t30 padding-b0">
                                            <form className="kt-form" id="addMasterForm">
                                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">                                       
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Type</label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <select
                                                                className='form-control'
                                                                value={matMaster.Type}
                                                                onChange={(e) => updateMatDetails('Type', e.target.value)}
                                                            >
                                                                <option value='Mat'>Mat</option>
                                                                <option value='Grass'>Grass</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <TextInput
                                                        label="Number"
                                                        className="form-control"
                                                        type="text"
                                                        isRequired={true}
                                                        isTextArea={false}
                                                        id="number"
                                                        value={matMaster.Number}
                                                        name="Number"
                                                        onChangeHandler={(val) => {setMatMaster((prev) => {
                                                            return {
                                                                ...prev,
                                                                Number: val.Number
                                                            }
                                                        })}}
                                                    />
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Active</label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <span className="kt-switch kt-switch--icon">
                                                                <label>
                                                                    <input 
                                                                        id="chkActive" 
                                                                        name="chkActive" 
                                                                        type="checkbox" 
                                                                        defaultChecked={matMaster.Active == 'N' ? '' : 'checked'}
                                                                        onChange={(event) => updateMatDetails('Active', event.target.checked ? 'Y' : 'N')} 
                                                                        />
                                                                    <span></span>
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div>    
                                                </div>
                                                <div className="kt-portlet__foot">
                                                    <div className="kt-form__actions">
                                                        <div className="row">
                                                            <div className="col-lg-9 ml-lg-auto">
                                                                <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addMatMasterHandler}>Save</button>
                                                                <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form> 
                                        </div>
                                    </div>
                                </RightSideDetails>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default MatMaster