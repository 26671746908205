import React, { memo, useCallback } from 'react'
import { useEffect } from 'react'

const TextInput = ({
    type,
    value,
    label,
    disabled,
    placeholder,
    onChangeHandler,
    controlClassName,
    className,
    validationMessage,
    invalid,
    maxLength,
    isRequired,
    isTextArea,
    name,
    id
}) => {

    const changeHandler = useCallback(
        (e) => {
            onChangeHandler({ [name]: e.target.value })
        }, [name, onChangeHandler]
    ) 
    useEffect(() => {
    })
    return (
        <>
            <div className="form-group row">
                <label className="col-form-label col-lg-3">{label} {isRequired && <span className="red">*</span>}</label>
                <div className="col-xl-6 col-lg-8">
                    {isTextArea && (
                        <textarea
                            className={className}
                            id={id} 
                            name={name}
                            defaultValue={value}
                            disabled={disabled}
                            onChange={changeHandler}
                        />
                    )}
                    {!isTextArea && (
                        <input 
                            id={id} 
                            name={name} 
                            type={type} 
                            className={className}
                            // value={value}
                            defaultValue={value}
                            onChange={changeHandler}  
                            maxLength={maxLength}
                        />
                    )}
                </div>
            </div>    
        </>
    )
}

export default TextInput;