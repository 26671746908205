import swal from 'sweetalert2';
import Select from 'react-select';
import { useEffect, useState } from "react";
import * as helper from '../global/helper';
import { postGuest, postUser } from '../api/usersAPI';

const AddGuest = (props) => {

    const [userDetails,                 setGuestDetails]              = useState({});

    useEffect(() => {
        $('#modalUser').modal({
            backdrop: 'static',
        });
        $('#modalUser').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);
        });
      
    }, [])

    const updateGuestDetails = (field, value) => { 
        setGuestDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const AddGuestHandler = async () => {
        const btn = $('#btnSaveUser');        
        var form = $('#add-AdminUser-form');


        form.validate({
            rules: {
                txtName: {
                    required: true
                },   
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                },
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {

            const { ID } = await postGuest({...userDetails, Active: 'Y'});
            props.addGuestId(ID);
            helper.StopProcessing(btn);
            $('#modalUser').modal('hide');
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const closeDrawer = () =>{

    }
    return(
        <>
            <div id="modalUser" className="modal fade modal-responsive" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-lg" role="document">
                    <form className="kt-form" id="add-AdminUser-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add User</h5>
                                <button 
                                    type="button" 
                                    className="close" 
                                    data-dismiss="modal" 
                                    aria-label="Close"
                                    onClick={closeDrawer}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div id="ModalUserHolder" className="kt-form kt-form--label-right" role="form">
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                        <div className="col-xl-6 col-lg-8">
                                            <input 
                                                id="txtName" 
                                                name="txtName" 
                                                type="text" 
                                                className="form-control" 
                                                value={userDetails.Name || ''}
                                                onChange={(event) => updateGuestDetails('Name',  helper.camelCase(event.target.value))} 
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Mobile</label>
                                        <div className="col-xl-6 col-lg-8">
                                            <input 
                                                id="txtMobile" 
                                                name="txtMobile" 
                                                type="text" 
                                                minLength='10'
                                                maxLength='10'
                                                className="form-control" 
                                                value={userDetails.Mobile || ''}
                                                onChange={(event) => updateGuestDetails('Mobile', event.target.value)} 
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Email </label>
                                        <div className="col-xl-6 col-lg-8">
                                            <input 
                                                id="txtEmail" 
                                                name="txtEmail" 
                                                type="text" 
                                                className="form-control" 
                                                value={userDetails.Email || ''}
                                                onChange={(event) => updateGuestDetails('Email', event.target.value)} 
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Handicap </label>
                                        <div className="col-xl-6 col-lg-8">
                                            <input 
                                                id="txtHandicap" 
                                                name="txtHandicap" 
                                                type="number" 
                                                className="form-control" 
                                                value={userDetails.Handicap || ''}
                                                onChange={(event) => updateGuestDetails('Handicap', event.target.value)} 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary margin-0 margin-r5" data-dismiss="modal" onClick={closeDrawer}>Close</button>
                                <button type="button" id="btnSaveUser" name="btnSaveUser" className="btn btn-brand margin-0" onClick={AddGuestHandler}>Add</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default AddGuest;