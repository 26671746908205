
const NoData = (props) =>{
    return(
        <>
            <div className="padding-20">
                <div className="empty-image-container">
                    <img style={{ width: '80%' }} src={props.src} alt={props.alt} />
                    <h5 className="margin-t20" >{props.message}</h5>
                </div>
            </div>
        </>
    )
}

export default NoData;