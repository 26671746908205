import { useState, useEffect } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import '../../assets/scripts/jquery-validation.init';
import ImageCropper from "../global/imageCropper";
import { uploadPublicFile } from "../api/fileAPI";
import { getPackageAll, postPackage } from "../api/academyAPI";

const Package = (props) => {

    const [isInit,              setIsInit]              = useState(true);
    const [rightSideDetails,    setRightSideDetails]    = useState(false);
    const [packageDetails,      setPackageDetails]      = useState({Type: 'Individual', Active: 'Y'});
    const [packageBannerBlob,   setPackageBannerBlob]   = useState(null);
    const [cropperData,         setCropperData]         = useState(null);
    const [packageList,         setPackageList]         = useState([]);
    const [removeCk,            setRemoveCk]            = useState(false);
    const [navState,            setNavState]            = useState('All Packages');

    useEffect(() => {
        props.title("Packages", "package")
    }, []);

    useEffect(() => {
        if (navState) {
            fetchPackage();
        }
    }, [navState])

    useEffect(() => {
        if (packageDetails.Type === 'Group') {
            delete packageDetails.Duration
        } else {
            delete packageDetails.FromDate
            delete packageDetails.ToDate
            delete packageDetails.GroupSize
        }
    }, [packageDetails])

    useEffect(() => {
        if(rightSideDetails){
            Config.Core.OpenDrawer()  
            window.initCkeditor("#editorContent");
            if (packageDetails && packageDetails.Content) {
                window.setEditorValue("#editorContent", packageDetails.Content);
            }
        }else{
            setPackageDetails({Active: 'Y', Type: 'Individual',});
            setPackageBannerBlob(null);
        }
    }, [rightSideDetails]);

    useEffect(() => {
        if (removeCk) {
            setRightSideDetails(true)
        }
        else if (!removeCk) {
            window.removeCkeditor("editorContent");
            setRightSideDetails(false)
        }
    }, [removeCk])

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();        
        }
    }, [packageList]);

    const updatePackageDetails = (field, value) => {   
        setPackageDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const fetchPackage = async() =>{
        try {
            const response = await getPackageAll()
            isInit ? setIsInit(false) : $('#tblPackage').KTDatatable().destroy(); 
            navState == 'All Packages' ? setPackageList(response) : navState == 'Individual' ? setPackageList([...response.filter(f => f.Type == 'Individual')]) : setPackageList([...response.filter(f => f.Type == 'Group')]);
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }  

    function fillDataTable() {
        const dataTable = $('#tblPackage').KTDatatable({
            data: {
                saveState: false,
                source: packageList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Title',
                    title: 'Title',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.BannerImage && row.BannerImage != '') ? `<img src="${row.BannerImage}" height="40" width="40" alt="banner-image" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Title.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Title} </span>
                                    <span class="kt-user-card-v2__desc text-clip"> ${row.Type} </span>
                                </div>
                            </div>`
                    }
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        if (row.Active == 'Y') 
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                               
                        else
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">No</span>'
                    },
                    width: 50,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblPackage .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setPackageDetails(dataTable.dataSet[index]);
                setRemoveCk(true)
                // setRightSideDetails(true); 
            }    
        });


    }

    const openCropper = () =>{
        setCropperData({
            imageRatio  : { width : 500, height : 250},
            dataHandler : (val) =>  setPackageBannerBlob(val),
            header : 'Upload Banner Image'
        })
    }
    
    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRemoveCk(false)
            // setRightSideDetails(false);
        }, 200);
    }

    const addPackageHandler = async () => {
        const btn = $('#btnSave');
        var form = $('#add-package-form');

        const Content = window.getEditorValue("#editorContent");
        form.validate({
            rules: {
                txtTitle: {
                    required: true
                },
                txtDuration: {
                    required: true,
                    number: true
                },     
                toDate: {
                    required: true,
                },     
                fromDate: {
                    required: true,
                },     
                txtIndex: {
                    required: true,
                    number: true
                },       
                txtGroupSize: {
                    required: true,
                    number: true
                }           
            },
            messages: {
                txtName: {
                    required: "Please enter title"
                },
                txtDuration: {
                    required: "Please enter duration"
                },
                fromDate: {
                    required: "Please select date"
                },
                toDate: {
                    required: "Please select date"
                },
                txtIndex: {
                    required: "Please enter index"
                },
                txtGroupSize: {
                    required: "Please enter Group Size"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            let packageBannerImage = '';
            if (packageBannerBlob) {
                const res = await uploadPublicFile(packageBannerBlob, 'Content');
                packageBannerImage = res.fileName;
            }else{
                if(packageDetails.BannerImage) packageBannerImage = packageDetails.BannerImage.split('/Content/')[1];
            }
            await postPackage({...packageDetails, BannerImage: packageBannerImage, Content});
            helper.StopProcessing(btn);
            closeDrawer();
            fetchPackage();
            if (packageDetails.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Package updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            } else {
                swal.fire({
                    icon: 'success',
                    titleText: 'Package added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }


    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <nav className="file-nav navbar navbar-top">
                                <a className={navState === "All Packages" ? "top-nav-active" : ""} onClick={() => setNavState("All Packages")}>  
                                    All Packages 
                                </a>
                                <a className={navState === "Individual" ? "top-nav-active" : "" } onClick={() => setNavState("Individual")}  >  
                                    Individual
                                </a>
                                <a className={navState === "Group" ? "top-nav-active" : "" } onClick={() => setNavState("Group")}  >  
                                    Group
                                </a>
                            </nav>
                            
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="row align-items-center margin-r10">
                                <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <input type="text" className="form-control clientSearch" placeholder="Search Package..." id="generalSearch" />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    <button onClick={() => setRemoveCk(true)}  type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Package</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div id="tblPackage" className="kt-datatable table-striped clickable"></div>
                        {rightSideDetails && (
                            <RightSideDetails title="Package Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form className="kt-form" id="add-package-form">
                                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Package Type<span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <select
                                                            className="form-control kt-selectpicker"
                                                            id="ddlType"
                                                            value={packageDetails.Type || ''}
                                                            onChange={(event) => updatePackageDetails('Type', event.target.value)}>
                                                            <option value='Individual'>Individual Package</option>
                                                            <option value='Group'>Group Package</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Title <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input
                                                            id="txtTitle" 
                                                            name="txtTitle" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={packageDetails.Title || ''}
                                                            onChange={(event) => updatePackageDetails('Title', event.target.value)}               
                                                        />						
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3"> Banner Image </label>
                                                    <div className="col-sm-9">                                              
                                                        {(!!packageBannerBlob || packageDetails.BannerImage) &&(<>
                                                            {/* <div className="kt-avatar kt-avatar--outline kt-avatar--changed">
                                                                <div className="kt-avatar__holder border-none shadow-none" 
                                                                    style={{ backgroundImage : `url( ${packageBannerBlob ? URL.createObjectURL(packageBannerBlob) : packageDetails.BannerImage && (packageDetails.BannerImage) })` } }>    
                                                                </div>
                                                            </div> */}
                                                            <img height={175} src={packageBannerBlob ? URL.createObjectURL(packageBannerBlob) : packageDetails.BannerImage && (packageDetails.BannerImage) }/>
                                                        </>) }

                                                        <p className="margin-t10">
                                                            <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={openCropper}>
                                                            {(!!packageBannerBlob || packageDetails.BannerImage) ? 'Change Image' : 'Upload Image'}
                                                            </button>
                                                        </p>
                                                        <p className="">(500 W x 250 H pixels)</p>                                                       
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3"> Content </label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <textarea name="editorContent" id="editorContent" />
                                                    </div>
                                                </div>
                                                {packageDetails.Type === 'Individual' && (
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Package Validity (in days) <span className="red">*</span></label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <input 
                                                                id="txtDuration" 
                                                                name="txtDuration" 
                                                                type="number" 
                                                                className="form-control"
                                                                value={packageDetails.Duration || ''}
                                                                onChange={(event) => updatePackageDetails('Duration', event.target.value)} 
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {packageDetails.Type === 'Group' && (
                                                    <>
                                                        <div className="form-group row">
                                                            <label htmlFor="fromDate" className="col-form-label col-lg-3">Start Date <span className="red">*</span></label>
                                                            <div className="col-xl-6 col-lg-8">
                                                                <input
                                                                    id="fromDate"
                                                                    value={packageDetails.FromDate || ''}
                                                                    name="fromDate"
                                                                    className="form-control margin-b10"
                                                                    type="date"
                                                                    onChange={(event) => updatePackageDetails('FromDate', event.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label htmlFor="toDate" className="col-form-label col-lg-3">End Date <span className="red">*</span></label>
                                                            <div className="col-xl-6 col-lg-8">
                                                                <input
                                                                    id="toDate"
                                                                    value={packageDetails.ToDate || ''}
                                                                    name="toDate"
                                                                    className="form-control margin-b10"
                                                                    type="date"
                                                                    onChange={(event) => updatePackageDetails('ToDate', event.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3">Group Size <span className="red">*</span></label>
                                                            <div className="col-xl-6 col-lg-8">
                                                                <input 
                                                                    id="txtGroupSize" 
                                                                    name="txtGroupSize" 
                                                                    type="number" 
                                                                    className="form-control"
                                                                    value={packageDetails.GroupSize || ''}
                                                                    onChange={(event) => updatePackageDetails('GroupSize', event.target.value)} 
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Index <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtIndex" 
                                                            name="txtIndex" 
                                                            type="number" 
                                                            className="form-control"
                                                            value={packageDetails.Index || ''}
                                                            onChange={(event) => updatePackageDetails('Index', event.target.value)} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Active</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <span className="kt-switch kt-switch--icon">
                                                            <label>
                                                                <input 
                                                                    id="chkActive" 
                                                                    name="chkActive" 
                                                                    type="checkbox" 
                                                                    defaultChecked={packageDetails.Active == 'N' ? '' : 'checked'}
                                                                    onChange={(event) => updatePackageDetails('Active', event.target.checked ? 'Y' : 'N')} 
                                                                    />
                                                                <span></span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                </div>    
                                            </div>
                                        </form>
                                    </div>
                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-lg-9 ml-lg-auto">
                                                    <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addPackageHandler}>Save</button>
                                                    <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>
                        )}
                    </div>
                </div>
            </div>
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
        </>
    )
}

export default Package;